import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { DocumentSection } from 'src/app/models/document-section';
import { EventService } from 'src/app/services/event/event.service';
import { CountryEvent } from 'src/app/models/country-event';

@Component({
  selector: 'app-country-snapshot-section',
  templateUrl: './country-snapshot-section.component.html',
  styleUrls: ['./country-snapshot-section.component.scss']
})
export class CountrySnapshotSectionComponent implements OnInit {

  private _holidays$: Observable<CountryEvent[]>;
  private _nextYearHolidays$: Observable<CountryEvent[]>;
  public isHeader: boolean;
  public previousSectionHeader: string;

  @Input()
  public country: Country;

  @Input()
  public section : DocumentSection;

  @Input()
  public previousSection : DocumentSection;

  public get isHolidays(): boolean {
    return (this.section && (this.section.name.trim() == 'STATUTORY HOLIDAYS'));
  }

  public get currentYear(): number {
    return new Date().getFullYear();
  }

  public get holidays$() {
    return this._holidays$;
  }

  public get nextYearHolidays$() {
    return this._nextYearHolidays$;
  }

  /* Also show the next year's holidays if it's after July 1 */
  public get showNextYearHolidays(): boolean {
    return new Date().getMonth() >= 6;
  }

  public fetchHolidays() {
    this._holidays$ = this.eventService.getEventsForCountryYear(this.country, this.currentYear);
    this._nextYearHolidays$ = this.eventService.getEventsForCountryYear(this.country, this.currentYear + 1);
  }

  constructor(private eventService: EventService) { }

  ngOnInit() {
    this.fetchHolidays();
    this.isHeader = this.section?.name === this.section?.name?.toUpperCase();
    this.previousSectionHeader = (this.previousSection && !this.previousSection.content) ? this.previousSection.name : null;
    if (this.section) {
      // Special handling for lists followed by empty paragraphs. These were added to make formatting look ok
      // on the old site, but should really not be there. Going forwards, we should clean them up in the source data
      this.section.content = this.section.content.split("<p>&nbsp;</p>").join("");
    }
  }

}
