<div class="countrysnapshotsections">
<div *ngIf="(sections$|async)?.length == 0">Loading...</div>
<app-country-snapshot-section
  *ngFor="let section of sections$ | async; let i = index"
  [section]="section"
  [country]="country$|async"
  [class.isFirst]="i == 0"
  [previousSection]="i > 0 ? (sections$|async)[i-1] : null"
  >
</app-country-snapshot-section>
</div>
