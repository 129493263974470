import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { Country } from 'src/app/models/country';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';
import { CalendarEventFormComponent } from '../calendar-event-form/calendar-event-form.component';
import { MatDialog } from '@angular/material/dialog';
import {
  CalendarEventDTO,
  CalendarService,
} from 'src/app/services/calendar/calendar.service';
import { take } from 'rxjs/operators';
import { AlertDialog } from 'src/app/util/alert-dialog/alert-dialog';

@Component({
  selector: 'app-calendar-list-country',
  templateUrl: './calendar-list-country.component.html',
  styleUrls: ['./calendar-list-country.component.scss'],
})
export class CalendarListCountryComponent implements OnInit {
  canEdit$ = this.userService.canUpdate();

  editEvent(id: number, event: CalendarEventDTO) {
    this.dialog.open(CalendarEventFormComponent, {
      width: '600px',
      data: {
        id,
        eventFormData: {
          name: event.name,
          date: event.date,
          country: event.country,
          description: event.description,
          event_type: event.event_type.name,
        },
        organization: event.organization,
      },
    });
  }

  deleteEvent(event: CalendarEventDTO) {
    let dialogRef = this.dialog.open(AlertDialog, {
      width: '600px',
      data: {
        title: 'Delete event',
        message: 'Are you sure you want to delete the event '.concat(
          event.name,
          '? This action cannot be undone'
        ),
        okButtonText: 'Delete event',
        cancelButtonText: 'Cancel',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((alertResult) => {
        if (alertResult) {
          this.canEdit$.subscribe((can) =>
            can
              ? this.calendarService.deleteEvent(event.id).subscribe(
                  (result) => {
                    this.calendarService.changes$.next(true);
                    this.snackBar.open('Event deleted', 'Close', {
                      duration: 5000,
                    });
                  },
                  (error) =>
                    this.snackBar.open(
                      'Could not delete event: '.concat(error?.detail),
                      'Close'
                    )
                )
              : null
          );
        }
      });
  }

  @Input()
  events: CalendarEvent[];

  country: Country;

  constructor(
    private userService: UserService,
    private calendarService: CalendarService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // Get the country information from the first event (since all we know about the country is the iso2 code)
    if (this.events) {
      this.country = this.events[0].country;
    }
  }
}
