<div class="country-event-calendar__wrapper">
  <h2>Upcoming Events</h2>
  <div *ngIf="loading">Loading...</div>

  <table>
    <ng-container *ngFor="let event of (events|async|slice:0:20) as items; let i = index">
      <tr *ngIf="i == 0 || (items[i].date.toISODate().substring(0,4) != items[i-1].date.toISODate().substring(0,4)) ">
        <td class="country-event-calendar__table-year-header">{{ event?.date|date:'yyyy' }}</td>
      </tr>
      <tr *ngIf="i == 0 || (items[i].date.toISODate() != items[i-1].date.toISODate()) || (items[i].name != items[i-1].name) ">
        <td class="country-event-calendar__table-date">{{ event?.date|date:'MMMM d' }}</td>
        <td class="country-event-calendar__table-name">{{ event?.name }}</td>
      </tr>
    </ng-container>
  </table>
  <div class="country-event-calendar__moreevents" *ngIf="!loading">
    <a [routerLink]="['/calendar']">View full calendar</a>
  </div>
</div>
