import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DateTime } from 'luxon';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { take } from 'rxjs/operators';
import { CalendarFilter } from 'src/app/models/calendar-filter';
import {
  CalendarService,
  GroupedCalendarDays,
} from 'src/app/services/calendar/calendar.service';

@Component({
  selector: 'app-calendar-print-list',
  templateUrl: './calendar-print-list.component.html',
  styleUrls: ['./calendar-print-list.component.scss'],
})
export class CalendarPrintListComponent implements OnInit {
  @Input()
  startDate: DateTime;

  @Input()
  endDate: DateTime;

  @Input()
  filter: CalendarFilter;

  @Output()
  printCompleteEvent: EventEmitter<void> = new EventEmitter();

  events: GroupedCalendarDays;

  constructor(private calendarService: CalendarService) {}

  ngOnInit() {
    this.calendarService
      .getGroupedCalendarEvents(this.startDate, this.endDate, this.filter)
      .pipe(take(1))
      .subscribe({
        next: (next) => {
          this.events = next;
        },
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Only print if the startDate or endDate change - otherwise we will trigger the print dialog
    // anytime there's a change event
    if (
      changes.startDate &&
      changes.startDate.currentValue &&
      changes.endDate &&
      changes.endDate.currentValue
    ) {
      this.calendarService
        .getGroupedCalendarEvents(this.startDate, this.endDate, this.filter)
        .pipe(take(1))
        .subscribe({
          next: (next) => {
            this.events = next;
          },
          complete: () => {
            setTimeout(() => {
              window.print();
            }, 2000);
          },
        });
    }
  }
}
