import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../user/user.service';

// Control access to GPS Admin pages
@Injectable()
export class AdminRoleGuard implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private userService: UserService) { }

  canActivate() {
    return this.userService.isAdmin();
  }
}

