import {
  Component,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/services/country/country.service';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import { Router, RouteReuseStrategy } from '@angular/router';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PurchaseComponent implements OnInit {
  chargebeeInstance: any;
  purchaseText = '';

  public form = new UntypedFormGroup({
    countries: new UntypedFormArray([new UntypedFormControl('')]),
  });

  get countries(): UntypedFormArray {
    return this.form.get('countries') as UntypedFormArray;
  }

  get countrySelected(): boolean {
    return (
      this.countries.controls.map((x) => x.value).filter((x) => x)?.length > 0
    );
  }

  public allCountries$: Observable<Country[]>;
  checkoutComplete = false;
  checkoutClosed = false;
  interval: 'yearly' | 'monthly' = 'monthly';

  addAnother(): void {
    (this.form.get('countries') as UntypedFormArray).controls.push(
      new UntypedFormControl('')
    );
  }

  yearly() {
    this.interval = 'yearly';
    this.updatePurchaseText();
  }

  monthly() {
    this.interval = 'monthly';
    this.updatePurchaseText();
  }

  updatePurchaseText() {
    const countries = this.countries.controls
      .map((x) => x.value)
      .filter((x) => x);
    let amount: Number = 0;

    if (countries?.length > 0 && countries?.length <= 3) {
      if (this.interval === 'monthly') {
        amount = countries.length * 140;
      } else {
        amount = countries.length * 1500;
      }
    } else if (countries?.length > 3 && countries?.length <= 10) {
      if (this.interval === 'monthly') {
        amount = countries.length * 119;
      } else {
        amount = countries.length * 1300;
      }
    } else if (countries?.length > 10) {
      if (this.interval === 'monthly') {
        amount = countries.length * 105;
      } else {
        amount = countries.length * 1200;
      }
    }
    if (amount === 0) {
      this.purchaseText = 'Subscribe now';
    } else {
      this.purchaseText =
        'Subscribe now ($' +
        amount.toLocaleString() +
        '/' +
        (this.interval === 'monthly' ? 'month' : 'year') +
        ')';
    }
  }

  startPurchase(): void {
    this.checkoutClosed = false;
    const countries = (this.form.get('countries') as UntypedFormArray).controls
      .map((x) => x.value)
      .filter((x) => x);
    if (countries.length === 0) {
      alert('Select one or more countries for your subscription!');
      return;
    }
    const data = {
      countries,
      interval: this.interval,
      first_name: 'first_name',
      last_name: 'last_name',
      company: 'company',
      email: 'email@email.com',
    };

    const t = this;

    this.chargebeeInstance.openCheckout({
      hostedPage: () => this.paymentsService.start_checkout(data),
      loaded: () => t.mixpanelService.track('Checkout form - loaded'),
      error: () => console.log('error'),
      close: () => {
        t.zone.run(() => {
          t.checkoutClosed = true;
        });
      },
      success: (hostedPageId) => {
        // Hosted page id will be unique token for the checkout that happened
        // You can pass this hosted page id to your backend
        // and then call our retrieve hosted page api to get subscription details
        // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
        let result = t.paymentsService.checkout_complete(hostedPageId);
        result.then((x) => {          
          console.log(x);
          const growsumo = window['growsumo'];
          if (growsumo) {
            growsumo.data.name = x.first_name + ' ' + x.last_name;
            growsumo.data.email = x.email;
            growsumo.data.customer_key = x.id;
            growsumo.createSignup();
          }
        });
        t.mixpanelService.track('Checkout form - Sucess');

        t.zone.run(() => {
          t.checkoutComplete = true;
        });
      },
      step: (value) => {
        // value -> which step in checkout
        // TODO: Add logging to each step so we can monitor the funnel
        t.mixpanelService.track('Checkout form - ' + value);
      },
    });
  }

  constructor(
    public countryService: CountryService,
    private paymentsService: PaymentsService,
    private router: Router,
    private zone: NgZone,
    private renderer: Renderer2,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    const chargbeeParam = 'Chargebee';
    this.chargebeeInstance = window[chargbeeParam]?.init({
      site: environment.chargebee_site,
    });

    this.renderer.addClass(document.body, 'purchase-page');

    this.allCountries$ = this.countryService.publicAllCountries();
    this.updatePurchaseText();
  }
}
