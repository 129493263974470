<div fxLayout="row" fxLayoutAlign="start" class="compare__content-wrapper">

  <mat-card >
    <mat-card-title fxLayoutAlign="row" fxLayoutAlign="space-between center">
      <h1>Compare</h1>
    </mat-card-title>
    <mat-card-content class="compare__table-wrapper">
      <app-compare-table></app-compare-table>
    </mat-card-content>
  </mat-card>
</div>
