import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { CurrencyService } from 'src/app/services/currency/currency.service';
import { CountryService } from 'src/app/services/country/country.service';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-currency-converter',
  templateUrl: './currency-converter.component.html',
  styleUrls: ['./currency-converter.component.scss']
})
export class CurrencyConverterComponent implements OnInit {

  @Input()
  country$: Observable<Country>

  public currencies$: Observable<{}>;
  public baseCurrencyAmount: string;
  public otherCurrencyAmount: string;
  public otherCurrency: string;
  private conversion: number;
  public working: boolean = true;

  public onBaseChange() {
    if (this.conversion) {
      let x = parseFloat(this.baseCurrencyAmount)
      this.otherCurrencyAmount = isNaN(x) ? '' : (x * this.conversion).toFixed(2);
    }
  }

  public onOtherChange() {
    if (this.conversion) {
      let x = parseFloat(this.otherCurrencyAmount);
      this.baseCurrencyAmount = isNaN(x) ? '' : (x / this.conversion).toFixed(2);
    }
  }

  public onChangeCountry() {
    let rate = this.currencyService.getCountryConversion$(this.country$, this.otherCurrency);
    rate.pipe(take(1)).subscribe(
      rate => {
        this.conversion = rate;
        this.onBaseChange();
      },
      err => { this.working = false; }
    )
  }

  public currencyRound(num: number) {
    return num.toFixed(2);
  }

  public currencyOrder(a, b) {
    return (a.value < b.value) ? -1 : 1;
  }

  constructor(private currencyService: CurrencyService, private countryService: CountryService) { }

  ngOnInit() {
    this.currencies$ = this.countryService.allCountries()?.pipe(
      map((countries: Country[]) => {
        let currency = {};
        countries.forEach(c => {
          if (c && c.currency_iso && c.currency_name && !(c.currency_iso in currency)) {
            currency[c.currency_iso] = c.currency_name;
          }
        });
        delete currency['USD'];
        delete currency['EUR'];
        return currency;
      })
    );
    this.country$?.pipe(take(1)).subscribe(
      (c) => {
        this.baseCurrencyAmount = "100";
        this.otherCurrency = "USD";
        this.onChangeCountry();
      }
    );
  }

}
