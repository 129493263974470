import { Component, Input, OnInit } from '@angular/core';
import { DialogRole } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { CalendarService } from 'src/app/services/calendar/calendar.service';

@Component({
  selector: 'app-calendar-list-day',
  templateUrl: './calendar-list-day.component.html',
  styleUrls: ['./calendar-list-day.component.scss']
})
export class CalendarListDayComponent implements OnInit {

  @Input()
  day: DateTime;

  @Input()
  events: Map<string, CalendarEvent>;

  constructor() { }

  ngOnInit(): void {
  }

}
