import { Component, OnInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { DocumentSection } from 'src/app/models/document-section';
import { Country } from 'src/app/models/country';
import { Observable } from 'rxjs';
import { History } from 'src/app/models/history'
import { take, map } from 'rxjs/operators';
import * as InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { RollbarService } from 'src/app/util/rollbar';
import * as Rollbar from 'rollbar';
import { CountryEvent } from 'src/app/models/country-event';
import { EventService } from 'src/app/services/event/event.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-country-profile-section',
  templateUrl: './country-profile-section.component.html',
  styleUrls: ['./country-profile-section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CountryProfileSectionComponent implements OnInit {

  public showBackground : boolean = false;
  public showReferences : boolean = false;
  public showHistory : boolean = false;
  public editing : boolean = false;
  private _history$ : Observable<History[]>;
  private _country : Country;
  public editor = InlineEditor;
  private _holidays$: Observable<CountryEvent[]>;
  private _localHolidays$: Observable<CountryEvent[]>;

  @Input()
  public country$ : Observable<Country>;

  @Input()
  public canEdit$ : Observable<boolean>;

  @Input()
  public section : DocumentSection;

  @Input()
  public documentHistory$ : Observable<History[]>;

  public setBackground(value: boolean) {
    this.showBackground = value;
    if (value) {
      this.showHistory = this.showReferences = false;
    }
  }

  public toggleBackground() : void {
    this.showBackground = !this.showBackground;
    this.showHistory = this.showReferences = false;
  }

  public toggleReferences() : void {
    this.showReferences = !this.showReferences;
    this.showHistory = this.showBackground = false;
  }

  public toggleHistory() : void {
    this.showHistory = !this.showHistory;
    this.showReferences = this.showBackground = false;
    if (this.showHistory) {
      this.mixpanelService.track("Show history for section", { "GPS Country": this._country.name, "GPS Section": this.section.name });
    }

  }

  public get history$() : Observable<History[]> {
    if (!this._history$ && this.documentHistory$) {
      this._history$ = this.documentHistory$.pipe(
        map(historyArray => historyArray.filter((h) => { return h.section_id == this.section.id })),
      );
    }
    return this._history$;
  }

  /* Obsolete, but saving as sample code for when we add the ability to add notes */
  // public toggleEditing() : void {
  //   if (!this.editing) {
  //     this._manualSave$ = new Subject<any>();
  //     this._manualSave$.pipe(
  //       startWith(1),       // kick off the autosave
  //       debounceTime(1000), // Wait until inactivity to save
  //       concatMap(() => this.saveContent()),
  //     )
  //     .subscribe(
  //       () => { },
  //       () => { this.snackBar.open('We were unable to save your changes. Please try again later.', 'Dismiss'); }
  //     );
  //   } else {
  //     this._manualSave$.next();
  //     this._manualSave$.complete();
  //   }
  //   this.editing = !this.editing;
  // }

  /* Obsolete, but saving as sample code for when we add the ability to add notes */
  // public saveContent() {
  //   if (this._oldContent === this.section.content) {
  //     return of();
  //   } else {
  //     this.mixpanelService.track("Save Country Profile section",
  //                               {"GPS Country": this._country.name, "GPS Section": this.section.name });
  //     this._oldContent = this.section.content;
  //     return this.documentService.updateDocumentSectionText(this._country.iso2, this.section.id, this.section.content);
  //   }
  // }

  /* Obsolete, but saving as sample code for when we add the ability to add notes */
  // public onChange( { editor }: ChangeEvent ) {
  //   this.section.content = editor.getData();
  //   this._manualSave$.next();
  // }

  /* Obsolete, but saving as sample code for when we add the ability to add notes */
  // public onBlur( { editor }: ChangeEvent ) {
  //   this.section.content = editor.getData();
  //   this._manualSave$.next();
  // }

  public get isHolidays(): boolean {
    return (this.section && (this.section.name.trim() == 'Public Holidays'));
  }

  public get holidays$() {
    return this._holidays$;
  }

  public get localHolidays$() {
    return this._localHolidays$;
  }

  public fetchHolidays() {
    if (this.isHolidays) {
      let currentYear = new Date().getFullYear();
      this._holidays$ = this.eventService.getEventsForCountryYear(this._country, currentYear);
      this._localHolidays$ = this.eventService.getLocalEventsForCountryYear(this._country, currentYear);
    }
  }

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public eventService: EventService,
    private mixpanelService: MixpanelService,
    @Inject(RollbarService) private rollbar: Rollbar) { }

  ngOnInit() {
    if (this.country$) {
      this.country$.pipe(take(1)).subscribe(
        country => {
          this._country = country;
          this.fetchHolidays();
        }
      )
    }

    if (this.section) {
      // Special handling for holidays - allow us to display holiday content underneat the Calendarific data
      // by placing it after a delimiter
      if (this.isHolidays) {
        const HOLIDAY_DELIMITER: string = "<p>----</p>";
        var pos: number = this.section.content.indexOf(HOLIDAY_DELIMITER);
        if (pos == -1) {
          this.section.content = "";
        } else {
          this.section.content = this.section.content.substr(pos + HOLIDAY_DELIMITER.length);
        }
      }

      // Special handling for lists followed by empty paragraphs. These were added to make formatting look ok
      // on the old site, but should really not be there. Going forwards, we should clean them up in the source data
      this.section.content = this.section.content.split("<p>&nbsp;</p>").join("");
    }
  }

}
