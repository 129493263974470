<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="40px" >
  <div fxFlex="50%" *ngIf="(country$|async)?.currency_iso">
    <div>
      <h3>Currency</h3>
      <div class="countrydataset__currency">{{(country$|async)?.currency_name}} ({{(country$|async)?.currency_iso}})</div>
      <div class="countrydataset__currency_alt" *ngIf="(country$|async)?.currency_alt_iso">{{(country$|async)?.currency_alt_name}} ({{(country$|async)?.currency_alt_iso}})</div>
    </div>
    <app-currency-converter [country$]="country$"></app-currency-converter>
  </div>

  <div fxFlex="50%" *ngIf="(country$|async)?.timezones">
    <h3>Current Time in {{(country$|async)?.name}}</h3>
    <app-country-time [country$]="country$"></app-country-time>
  </div>

</div>

<div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="40px" class="countrydataset__section-wrapper">

  <div *ngIf="(dataset$ | async)?.data?.people?.ethnic_groups" class="countrydataset__section countrydataset__section--ethnic-groups" fxFlex="33">
    <h3>Ethnic Groups</h3>
    <table>
      <tr *ngFor="let item of (dataset$ | async)?.data.people.ethnic_groups.ethnicity">
        <td class="countrydataset__itemname">{{ item.name }}</td>
        <td class="countrydataset__itempercent">{{ item.percent }}<span *ngIf="item.percent">%</span></td>
      </tr>
    </table>
    <div *ngIf="(dataset$ | async)?.data.people.ethnic_groups.note" class="countrydataset__section-note">{{(dataset$ | async)?.data.people.ethnic_groups.note}}</div>
    <div *ngIf="(dataset$ | async)?.data.people.ethnic_groups.date" class="countrydataset__section-date"> Updated {{(dataset$ | async)?.data.people.ethnic_groups.date}}</div>
  </div>

  <div *ngIf="(dataset$ | async)?.data?.people?.languages" class="countrydataset__section countrydataset__section--languages" fxFlex="33">
    <h3>Languages</h3>
    <table>
      <tr *ngFor="let item of (dataset$ | async)?.data.people.languages.language">
        <td class="countrydataset__itemname">{{ item.name }}</td>
        <td class="countrydataset__itempercent">{{ item.percent }}<span *ngIf="item.percent">%</span></td>
      </tr>
    </table>
    <div *ngIf="(dataset$ | async)?.data.people.languages.note" class="countrydataset__section-note">{{(dataset$ | async)?.data.people.languages.note}}</div>
    <div *ngIf="(dataset$ | async)?.data.people.languages.date" class="countrydataset__section-date"> Updated {{(dataset$ | async)?.data.people.languages.date}}</div>
  </div>

  <div *ngIf="(dataset$ | async)?.data?.people?.religions" class="countrydataset__section countrydataset__section--religions" fxFlex="33">
    <h3>Religions</h3>
    <table>
      <tr *ngFor="let item of (dataset$ | async)?.data.people.religions.religion">
        <td class="countrydataset__itemname">{{ item.name }}</td>
        <td class="countrydataset__itempercent">{{ item.percent }}<span *ngIf="item.percent">%</span></td>
      </tr>
    </table>
    <div *ngIf="(dataset$ | async)?.data.people.religions.note" class="countrydataset__section-note">{{(dataset$ | async)?.data.people.religions.note}}</div>
    <div *ngIf="(dataset$ | async)?.data.people.religions.date" class="countrydataset__section-date"> Updated {{(dataset$ | async)?.data.people.religions.date}}</div>
  </div>

</div>

<div fxLayout="row" fxLayoutAlign="space-between none">
  <div *ngIf="(dataset$ | async)?.data?.government?.national_anthem" class="countrydataset__section countrydataset__section--national-anthem">
    <h3>National Anthem</h3>
    <div class="countrydataset__section--national-anthem-name">{{ (dataset$ | async)?.data.government.national_anthem.name }}</div>
    <!-- <audio *ngIf="(dataset$ | async)?.data.government.national_anthem.audio_url" controls src="{{ (dataset$ | async)?.data.government.national_anthem.audio_url }}"></audio> -->
  </div>
  <img *ngIf="(country$|async)?.iso2" class="country__map-image" src="/assets/images/maps/{{ (country$|async)?.iso2|uppercase }}-map.gif"
  onError="this.src='/assets/images/placeholder.png'">
</div>
