<div class="main__toolbar-wrapper">
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="main__toolbar">
    <a [routerLink]="['/dashboard']" class="main__logo-link"><img src="/assets/images/logo-header.png"/></a>

    <div fxLayout="row" class="main__menu-wrapper">
      <!-- <a mat-menu-item [routerLink]="['/dashboard']"><mat-icon svgIcon="home"></mat-icon>Home</a> -->
      <a mat-menu-item [routerLink]="['/dashboard']"><mat-icon svgIcon="globe"></mat-icon>Countries</a>
      <a mat-menu-item [routerLink]="['/documents']"><mat-icon svgIcon="document"></mat-icon>Documents</a>
      <a mat-menu-item [routerLink]="['/updates']">  <mat-icon svgIcon="bell"></mat-icon>Updates</a>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" class="main__menu-welcome-wrapper">

    </div>

  </mat-toolbar>
</div>

<div class="content">
  <p>We seem to have a problem</p>
  <a mat-stroked-button [routerLink]="['/dashboard']">Reload</a>
</div>

<mat-toolbar fxLayout="row" fxLayoutAlign="left" class="main__footer">
  <a href="https://www.globalpeoplestrategist.com/terms-of-use/" target="_blank">Terms of Service</a><span class="main__footer-divider">|</span>
  <a href="https://www.globalpeoplestrategist.com/blog/" target="_blank">Blog</a><span class="main__footer-divider">|</span>
  <a href="mailto:support@globalpeoplestrategist.com">Contact us</a>
</mat-toolbar>
