import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { DateTime } from "luxon";

@Component({
  selector: "app-calendar-month-header",
  templateUrl: "./calendar-month-header.component.html",
  styleUrls: ["./calendar-month-header.component.scss"],
})
export class CalendarMonthHeaderComponent implements OnInit {
  @Input()
  month?: DateTime;

  @Output()
  monthChangeEvent = new EventEmitter<DateTime>();

  previous() {
    this.month = this.month.minus({ month: 1 });
    this.monthChangeEvent.emit(this.month);
    return false;
  }

  next() {
    this.month = this.month.plus({ month: 1 });
    this.monthChangeEvent.emit(this.month);
    return false;
  }

  constructor() {}

  ngOnInit(): void {
    if (!this.month) {
      this.month = DateTime.now().startOf("month");
    }
  }
}
