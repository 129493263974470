import { Component, OnInit } from '@angular/core';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.scss'],
})
export class UpdatesComponent implements OnInit {

  constructor(
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.mixpanelService.track('Page: View All Updates');
  }
}
