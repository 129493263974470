import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';
import { finalize, startWith, map, tap } from 'rxjs/operators';
import { LibraryDocument } from 'src/app/models/library-document';
import { Observable, combineLatest, BehaviorSubject, timer } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/services/country/country.service';
import { ActivatedRoute } from '@angular/router';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { LibraryDocumentService } from 'src/app/services/library-document/library-document.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { AlertDialog } from 'src/app/util/alert-dialog/alert-dialog';
import { UserService } from 'src/app/services/user/user.service';
import { LibraryDocumentFilter } from 'src/app/models/library-document-filter';
import { LibraryDocumentType } from 'src/app/models/library-document-type';
import { PaginatedResponse } from 'src/app/models/paginated-response';

@Component({

  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

  public loading : Boolean = true;
  public filter : UntypedFormControl;
  public filterCountry : UntypedFormControl;
  public filterType : UntypedFormControl;
  public filterCountries$ : Observable<Country[]>;
  public filterTypes$ : Observable<LibraryDocumentType[]>;
  private filteredDocuments$ : Observable<LibraryDocument[]>;
  public canEdit$ : Observable<boolean>;
  public response$ : Observable<PaginatedResponse<LibraryDocument>>;
  public page: number = 0;

  @Input() updateTrigger: boolean; // Input property to trigger update

  get documents$() {
    return this.filteredDocuments$;
  }

  public logDownload(document: LibraryDocument) {
    this.mixpanelService.track("Download document", {"Document Name": document?.name, "GPS Country": document?.country?.name});
  }

  public update() {
    const filter: LibraryDocumentFilter = {
      keyword: this.filter?.value,
      country: this.filterCountry?.value,
      type: this.filterType?.value,
      page: this.page + 1
    };
    this.loading = true;    
    this.response$ = this.libraryDocumentService.getLibraryDocuments(filter).pipe(
      tap(_ => { this.loading = false }),
    );
    
    this.filteredDocuments$ = this.response$.pipe(
      map((response: PaginatedResponse<LibraryDocument>) => response.results ? response.results.sort(this.libraryDocumentService.sortDocuments) : response.results)
    );
  }

  public updateFilter(event: any) {
    this.page = 0;
    this.update();
  }

  public handlePageEvent(event: any) {
    this.page = event.pageIndex;
    this.update();
  }

  public delete(document: LibraryDocument) {
    let dialogRef = this.dialog.open(AlertDialog, {
      width: '600px',
      data: {
        title: 'Delete document',
        message: ''.concat('Are you sure you want to delete the document "',
                           document.name, '". This action cannot be undone.'),
        cancelButtonText: 'Cancel',
        okButtonText: "Delete"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.libraryDocumentService.deleteLibraryDocument(document).subscribe(
          () => {
            this.snackBar.open('Document deleted', 'Close', {duration: 5000});
            this.update();
          },
          (error) => { this.snackBar.open('Could not delete document: '.concat(error.message), 'Close')}
        )
      }
    });
  }

  constructor(public libraryDocumentService: LibraryDocumentService,
              private countryService: CountryService,
              private userService: UserService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private mixpanelService: MixpanelService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.filter = new UntypedFormControl('');
    this.filterCountry = new UntypedFormControl(null);
    this.filterType = new UntypedFormControl(null);
    let country$ : Observable<string>;
    let type$ : Observable<string>;

    this.activatedRoute.queryParams.subscribe(params => {
      this.filterCountry.setValue(params['country']);
      country$ = this.filterCountry.valueChanges.pipe(startWith(params['country']));
    });

    type$ = this.filterType.valueChanges.pipe(startWith(''));

    this.updateFilter(null)

    this.filterCountries$ = this.countryService.standardCountries();
    this.filterTypes$ = this.libraryDocumentService.getLibraryDocumentTypes().pipe(
      map((types) => types.sort(this.libraryDocumentService.sortTypes))
    );

    this.canEdit$ = this.userService.canUpdate();

    // Refresh every 30 minutes, since the default timeout for AWS links is one hour
    let timeout = 1000 * 60 * 30;
    timer(timeout, timeout).subscribe(() => this.update());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.updateTrigger) {
      this.update();
    }
  }

}
