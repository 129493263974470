import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CalendarPrintPopupComponentData } from 'src/app/components/calendar-print-popup/calendar-print-popup.component';
import { CalendarFilter } from 'src/app/models/calendar-filter';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  public month: DateTime = null;
  public view: 'grid' | 'list' = 'grid';
  public filter: CalendarFilter;
  public printStartDate: DateTime = null;
  public printEndDate: DateTime = null;
  public canEdit$: Observable<boolean> = this.userService.canUpdate();
  private _is_notification_only: Observable<boolean>;

  get isNotificationOnly$(): Observable<boolean> {
    return this._is_notification_only;
  }

  updateFilter(f: CalendarFilter) {
    this.filter = f;
    this.mixpanelService.track('Calendar - Update filter', f);
  }

  changeMonth(m: DateTime): void {
    this.month = m;
    this.mixpanelService.track('Calendar - Change month', {
      month: m.monthLong,
    });
  }

  toggleView(v: 'grid' | 'list') {
    this.view = v;
    this.mixpanelService.track('Calendar - Toggle view', { view: v });
    return false;
  }

  print(printDates: CalendarPrintPopupComponentData) {
    this.printStartDate = printDates.start;
    this.printEndDate = printDates.end;
    this.mixpanelService.track('Calendar - Print');
  }

  printComplete() {
    // this.printStartDate = null;
    // this.printEndDate = null;
  }

  constructor(
    private mixpanelService: MixpanelService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.month = DateTime.now().startOf('month');
    this._is_notification_only =
      this.userService.organizationIsNotificationOnly()?.pipe(startWith(false));
    this.mixpanelService.track('Page: Compliance Calendar');
  }
}
