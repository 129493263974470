import { Component, OnInit } from '@angular/core';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss'],
})
export class CompareComponent implements OnInit {
  constructor(private mixpanelService: MixpanelService) {}

  ngOnInit() {
    this.mixpanelService.track('Page: Compare');
  }
}
