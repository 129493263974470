import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CalendarFilter } from 'src/app/models/calendar-filter';
import { CalendarService } from 'src/app/services/calendar/calendar.service';

export interface CalendarExportPopupComponentData {
  filter: CalendarFilter;
}

@Component({
  selector: 'app-calendar-export-popup',
  templateUrl: './calendar-export-popup.component.html',
  styleUrls: ['./calendar-export-popup.component.scss'],
})
export class CalendarExportPopupComponent implements OnInit {
  subscribeUrl: string;
  googleSubscribeUrl: string;
  outlookOnlineSubscribeUrl: string;
  officeOnlineSubscribeUrl: string;

  get filterDescription(): string {
    return this.data.filter?.description();
  }

  constructor(
    public dialogRef: MatDialogRef<CalendarExportPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarExportPopupComponentData,
    public calendarService: CalendarService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.subscribeUrl = await this.calendarService
      .createCalendarSubscription(this.data.filter)
      .pipe(take(1))
      .toPromise();
    // From https://stackoverflow.com/a/71762830
    // It also appears that Google subscribe URLs don't work with HTTPS
    this.googleSubscribeUrl =
      'https://calendar.google.com/calendar/r?cid=' +
      encodeURI(this.subscribeUrl.replace('https', 'http'));
    this.outlookOnlineSubscribeUrl =
      'https://outlook.live.com/calendar/0/addfromweb?url=' +
      encodeURI(this.subscribeUrl);
    this.officeOnlineSubscribeUrl =
      'https://outlook.office.com/calendar/0/addfromweb?url=' +
      encodeURI(this.subscribeUrl);
  }
}
