import { Country } from './country';

export enum UserRole {
  OWNER = 'owner',
  EDITOR = 'editor',
  VIEWER = 'viewer',
}

export enum OrganizationType {
  STANDARD = 'standard',
  NOTIFICATIONS = 'notifications'
}

export class User {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  organization_name: string;
  organization_role: UserRole;
  organization_type: OrganizationType;
  countries: Country[];
  is_staff: boolean;
  update_email_preference: string;
  is_password_set: boolean;
}
