import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public oldPassword: string;
  public password1: string;
  public password2: string;
  public error: string;
  public message: string;

  changePassword(): void {
    this.error = null;
    if (!this.password1) {
      this.error = 'Password cannot be blank';
      return;
    }
    if (this.password1.length < 8) {
      this.error = 'Password must be at least eight characters long';
      return;
    }
    if (this.password1 !== this.password2) {
      this.error = 'Passwords do not match';
      return;
    }
    this.authService.changePassword(this.password1, this.password2).subscribe(
      (data) => {
        this.error = '';
        this.password1 = '';
        this.password2 = '';
        const snackBarRef = this.snackBar.open('Password changed!', 'OK');
        this.mixpanelService.track('Action: Change password');
      },
      (err) => {
        this.error = 'Unable to change password';
      }
    );
  }

  constructor(
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.mixpanelService.track('Page: Change password');
  }
}
