<div fxLayout="row" fxLayoutAlign="start" class="calendar__content-wrapper"
  [class.printing]="printEndDate && printStartDate">
  <mat-card >
    <mat-card-title>
      <div fxLayout="column" fxLayoutAlign="start" class="calendar__header-wrapper">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <h1>Calendar</h1>
          <div class="calendar__gridlisttoggle-wrapper">
            <a href (click)="toggleView('grid')" class="calendar__gridlisttoggle" [class.active]="view === 'grid'">
              <div class="calendar__icon-wrapper">
                <mat-icon svgIcon="table"></mat-icon>
              </div>
            </a>
            <a href (click)="toggleView('list')" class="calendar__gridlisttoggle" [class.active]="view === 'list'">
              <div class="calendar__icon-wrapper">
                <mat-icon svgIcon="list"></mat-icon>
              </div>
            </a>
          </div>
        </div>
        <div>
          <app-calendar-filter (updateFilterEvent)="updateFilter($event)"></app-calendar-filter>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxShow.xs="false" class="calendar__header-bar">
          <app-calendar-month-header [month]="month" (monthChangeEvent)="changeMonth($event)">
          </app-calendar-month-header>
          <div fxLayout="row" fxLayoutAlign="end">
            <app-calendar-add-event [canEdit$]="canEdit$"></app-calendar-add-event>
            <app-calendar-export [filter]="filter"></app-calendar-export>
            <app-calendar-print (printEvent)="print($event)"></app-calendar-print>
          </div>
        </div>
      </div>
    </mat-card-title>
    <mat-card-content>
      <app-calendar-list *ngIf="view === 'list'" [month]="month" [filter]="filter"></app-calendar-list>
      <app-calendar-grid *ngIf="view === 'grid'" [month]="month" [filter]="filter"></app-calendar-grid>
    </mat-card-content>
  </mat-card>
</div>

<app-calendar-print-list *ngIf="printStartDate && printEndDate" [startDate]="printStartDate" [endDate]="printEndDate"
  [filter]="filter" (printCompleteEvent)="printComplete()"></app-calendar-print-list>
