<div class="calendarprintlist__wrapper">
  <h1>
    {{ startDate | date: 'MMMM d, YYYY' }} -
    {{ endDate | date: 'MMMM d, YYYY' }}
  </h1>
  <table>
    <thead>
      <tr>
        <td>
          <div class="header-space">&nbsp;</div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div
            class="calendarprint-day-wrapper"
            *ngFor="let day of events | keyvalue"
          >
            <app-calendar-print-list-day
              [events]="day.value"
              [day]="day.key"
            ></app-calendar-print-list-day>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>
          <div class="footer-space">
            <!-- &copy; Global People Strategist {{ currentYear }} -->
          </div>
        </td>
      </tr>
    </tfoot>
  </table>

  <div *ngIf="!events || !events?.size">
    There are no events in this time period
  </div>
</div>
