import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AIService {
    private env = environment;

    constructor(private http: HttpClient) { }

    getResponse(prompt: string, thread: string = ""): Observable<any> {
        return this.http.post(this.env.api_root.concat('ai_api/assistant_stream/'), { prompt, thread }, {
            responseType: 'text',
            observe: 'events',
            reportProgress: true
        }).pipe(
            filter(event => event.type === HttpEventType.DownloadProgress),
            map(event => event['partialText'])
        );

    }
}