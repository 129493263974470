import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendarEvent } from 'src/app/models/calendar-event';

@Component({
  selector: 'app-calendar-print-list-day',
  templateUrl: './calendar-print-list-day.component.html',
  styleUrls: ['./calendar-print-list-day.component.scss'],
})
export class CalendarPrintListDayComponent implements OnInit {
  @Input()
  day: DateTime;

  @Input()
  events: Map<string, CalendarEvent>;

  constructor() {}

  ngOnInit(): void {}
}
