import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country } from 'src/app/models/country';
import { from, Observable, of } from 'rxjs';
import { parseString, parseStringPromise } from 'xml2js';
import { map, shareReplay, tap, mergeMap } from 'rxjs/operators';
import { NewsItem } from 'src/app/models/news-item';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private env = environment;

  private strip(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  public getNewsForCountry(country: Country): Observable<NewsItem[]> {
    // Note: some RSS feeds can't be loaded in the browser due to CORS security.
    // To get around this, you can use a proxy.

    if (country.news_rss) {
      return this.http
        .get(this.env.cors_proxy.concat(country.news_rss), {
          responseType: 'text',
        })
        .pipe(
          mergeMap((data) => from(parseStringPromise(data))),
          map((data) => (data ? data.rss.channel[0].item : null)),
          map((data) =>
            data
              ? data.map((x: NewsItem) => {
                  return {
                    title: x.title[0],
                    description: x.description ? x.description[0] : '',
                    pubDate: x.pubDate[0],
                    link: x.link[0],
                  };
                })
              : null
          ),
          map((data) =>
            data
              ? data.map((x: NewsItem) => {
                  x.title = this.strip(x.title);
                  x.description = this.strip(x.description);
                  return x;
                })
              : data
          ),
          shareReplay(1)
        );
    } else {
      return of([]);
    }
  }

  constructor(private http: HttpClient) {}
}
