<div *ngIf="populated() || (canUpdate$|async)" class="countrysettings__wrapper" [class.countrysettings__wrapper--populated]="populated()" >

  <mat-icon matSuffix svgIcon="location"></mat-icon>

  <div *ngIf="(canUpdate$|async)" class="countrysettings__edit">
    <button *ngIf="populated(); else noInfoButton" mat-button (click)="edit()">Edit</button>
    <ng-template #noInfoButton>
      <button mat-button (click)="edit()">Add Organization Information</button>
    </ng-template>
  </div>

  <div *ngIf="populated()" class="countrysettings__content">
    <div class="countrysettings__companyname">{{ countrySettings?.company_name }}</div>
    <div *ngIf="countrySettings?.office_hours" class="countrysettings__hours countrysettings__label">Office Hours</div>
    <div class="countrysettings__hours" >{{ countrySettings?.office_hours }}</div>
    <div *ngIf="countrySettings?.address" class="countrysettings__address-header countrysettings__label">Address</div>
    <div class="countrysettings__address">{{ countrySettings?.address }}</div>
    <div *ngIf="countrySettings?.contact" class="countrysettings__contact-header countrysettings__label">Contact</div>
    <div class="countrysettings__contact">{{ countrySettings?.contact }}</div>
  </div>

</div>
