<div class="comparetable__filter-wrapper">
  <form>

    <mat-form-field fxFlexFill
                    appearance="outline"
                    class="formfield__no-label formfield__no-padding comparetable__sectionbaselines"
                    [style.width.px]=400
                    [style.min-width.px]=400
                    floatLabel="never">
      <mat-select [formControl]="selectedSectionBaselines" multiple placeholder="Select topics to compare">
        <mat-option *ngFor="let sectionBaseline of filterSectionBaselines$|async" [value]="sectionBaseline.id">
          {{sectionBaseline.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlexFill
                    appearance="outline"
                    class="formfield__no-label formfield__no-padding comparetable__country"
                    [style.width.px]=400
                    [style.min-width.px]=400
                    floatLabel="never">
      <mat-select [formControl]="selectedCountries" multiple placeholder="Select countries to compare">
        <mat-option *ngFor="let country of filterCountries$|async" [value]="country">
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</div>

<div class="compare__tablewrapper">
  <table mat-table [dataSource]="compareData$ | async">

    <ng-container *ngFor="let column of (columns$ | async)" [matColumnDef]="column.name">
      <th mat-header-cell *matHeaderCellDef>{{ column.name }}</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="column.name == 'Section'">{{ row.section }}</ng-container>
        <div [innerHTML]="row.row[column.iso2]"></div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="(columnHeaders$ | async)"></tr>
    <tr mat-row *matRowDef="let row; columns: (columnHeaders$ | async);"></tr>

  </table>
</div>
