<div class="day-grid-item">
  <div class="day" [class.today]="isToday" [class.isMonthTransition]="isMonthTransition">
    <span class="monthPart" *ngIf="isMonthTransition">{{
      day | date: 'LLL'
    }}</span>
    {{ day | date: 'd' }}
  </div>
  <div class="day-contents">
    <div *ngIf="holidayList?.length > 0" class="view-holidays" (click)="viewDetails()">
      Public Holiday
    </div>
    <div>
      <app-calendar-grid-event
        *ngFor="let event of eventList?.slice(0, 4)"
        [event]="event"
        (viewDetailsEvent)="viewDetails(event.id)"
      ></app-calendar-grid-event>
    </div>
    <div *ngIf="eventList?.length > 2" class="view-all" (click)="viewDetails()">
      View all {{ eventList.length }} events
    </div>
  </div>
</div>
