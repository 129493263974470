<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" >
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding [matTreeNodePaddingIndent]="20"
                [class.level-1]="node.level == 1" [class.level-2]="node.level == 2" [class.level-3]="node.level == 3"
                class="countryprofiletree__leaf">
    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button disabled></button>
      <span (click)="scrollTo(node.section.id)" class="countryprofiletree__name" [class.clickable]="checklistSelection.isSelected(node)" >{{node.item}}</span>
      <mat-checkbox class="checklist-leaf-node" color="primary"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="sectionLeafItemSelectionToggle(node)"></mat-checkbox>
    </div>
    <!-- <button mat-icon-button (click)="addNewItem(node)"><mat-icon>add</mat-icon></button> -->
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="20" [class.level-1]="node.level == 1" [class.level-2]="node.level == 2" [class.level-3]="node.level == 3">
    <div fxLayout="row" fxLayoutAlign="start center" class="countryprofiletree__title-wrapper">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon [class]="treeControl.isExpanded(node) ? 'countryprofiletree__lefticon' : 'countryprofiletree__lefticon countryprofiletree__lefticon--rotated'" svgIcon="down"></mat-icon>
      </button>
      <span (click)="scrollTo(node.section.id)" class="countryprofiletree__name" [class.clickable]="checklistSelection.isSelected(node)" >{{node.item}}</span>
      <mat-checkbox color="primary"
                    [checked]="checklistSelection.isSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="sectionSelectionToggle(node)"></mat-checkbox>
    </div>
  </mat-tree-node>
</mat-tree>
<div *ngIf="!dataSource.data.length" class="countryprofiletree__empty">Loading...</div>
