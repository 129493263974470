<table mat-table [dataSource]="allCountries$ | async">
  <ng-container matColumnDef="country">
    <th mat-header-cell *matHeaderCellDef>Country</th>
    <td mat-cell *matCellDef="let country">
      {{ country.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="snapshotEvents">
    <th mat-header-cell *matHeaderCellDef>Snapshot Holidays</th>
    <td mat-cell *matCellDef="let country" [innerHTML]="(snapshotEvents$|async)?.get(country.iso2)">
    </td>
  </ng-container>

  <ng-container matColumnDef="profileEvents">
    <th mat-header-cell *matHeaderCellDef>Profile Holidays</th>
    <td mat-cell *matCellDef="let country" [innerHTML]="(profileEvents$|async)?.get(country.iso2)">
    </td>
  </ng-container>

  <ng-container matColumnDef="calendarificEvents">
    <th mat-header-cell *matHeaderCellDef>Calendarific Holidays</th>
    <td mat-cell *matCellDef="let country">
      <table>
        <tr *ngFor="let h of (calendarificEvents$|async).get(country.iso2)|async">
          <td class="country-event-calendar__table-date">{{ h?.date|date:'MMMM d' }}</td>
          <td class="country-event-calendar__table-name">{{ h?.name }}</td>
          </tr>
      </table>

    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['country', 'calendarificEvents', 'snapshotEvents', 'profileEvents']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['country', 'calendarificEvents', 'snapshotEvents', 'profileEvents'];"></tr>

</table>
