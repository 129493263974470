import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { Country } from 'src/app/models/country';

export class DisplayCalendarEvent {
  name: string;
  date: DateTime;
  countries: Country[];
  countryDisplay: string;
}

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
})
export class EventCalendarComponent implements OnInit {
  private _events: Observable<DisplayCalendarEvent[]>;

  public get events$(): Observable<DisplayCalendarEvent[]> {
    if (!this._events) {
      const now = DateTime.now();
      this._events = this.calendarService
        .getCalendarEvents(now, now.plus({ months: 1 }))
        .pipe(
          // Sort by date, holiday, country
          map((events) => events.sort((a, b) => {
            if (a.date.toSeconds() !== b.date.toSeconds()) {
              return (a.date > b.date ? 1 : -1)
            } else if (a.name !== b.name) {
              return (a.name > b.name ? 1 : -1)
            } else {
              return a.country.name > b.country.name ? 1 : -1;
            }
          })),
          map((events: CalendarEvent[]) => {
            const newEvents: DisplayCalendarEvent[] = [];
            let lastEvent: DisplayCalendarEvent;
            for (const event of events) {
              if (lastEvent && lastEvent.date.toSeconds() === event.date.toSeconds() && lastEvent.name === event.name) {
                lastEvent.countries.push(event.country);
              } else {
                if (lastEvent) {
                  const countryNames = lastEvent?.countries.map(c => c.name);
                  lastEvent.countryDisplay = [...new Set(countryNames)].join(', ');
                }
                newEvents.push(lastEvent);
                lastEvent = {name: event.name, date: event.date, countries: [event.country], countryDisplay: ''};
              }
            }
            return newEvents;
          })
        );
    }
    return this._events;
  }

  constructor(private calendarService: CalendarService) {}

  ngOnInit() {}
}
