import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, combineLatest, of } from 'rxjs';
import { Country } from 'src/app/models/country';
import { DocumentService } from 'src/app/services/document/document.service';
import { CountryService } from 'src/app/services/country/country.service';
import { ActivatedRoute } from '@angular/router';
import { SectionBaseline } from 'src/app/models/section-baseline';
import { mergeMap, switchMap, tap, map } from 'rxjs/operators';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-compare-table',
  templateUrl: './compare-table.component.html',
  styleUrls: ['./compare-table.component.scss']
})
export class CompareTableComponent implements OnInit {

  public selectedCountries : UntypedFormControl;
  public selectedSectionBaselines : UntypedFormControl;
  public filterCountries$ : Observable<Country[]>;
  public filterSectionBaselines$ : Observable<SectionBaseline[]>;
  public compareData$;
  public columns$ : Observable<Country[]>;
  public columnHeaders$ : Observable<string[]>;

  constructor(public documentService: DocumentService,
              private countryService : CountryService,
              private activatedRoute: ActivatedRoute,
              private mixpanelService: MixpanelService) { }

  ngOnInit() {
    this.selectedCountries = new UntypedFormControl(null);
    this.selectedSectionBaselines = new UntypedFormControl(null);
    let country$ : Observable<string>;

    this.filterSectionBaselines$ = this.documentService.getComparableSectionBaselines()
    this.filterCountries$ = this.countryService.userCountries();

    this.columns$ = this.selectedCountries.valueChanges.pipe(
      map((countries) => { countries.unshift({'name' : 'Section'}); return countries;} )
    )
    this.columnHeaders$ = this.selectedCountries.valueChanges.pipe(
      map((countries) => countries.map((c: Country) => c?.name)),
    )

    this.compareData$ = combineLatest(this.selectedSectionBaselines.valueChanges, this.selectedCountries.valueChanges).pipe(
      map(([sectionBaselines, countries]) => [sectionBaselines, countries.map((c: Country) => c.iso2)]),
      tap(([sectionBaselines, countries]) => this.mixpanelService.track("Compare Sections",
                                                      {"Compare Sections": sectionBaselines,
                                                       "Compare Countries": countries})),
      switchMap(([sectionBaselines, countries]) => this.documentService.getCompareSections(sectionBaselines, countries))
    )

  }

}
