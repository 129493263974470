<mat-card>
  <mat-card-title>Upgrade to the full GPS</mat-card-title>
  <!-- <mat-card-subtitle>Keep up to date with events in your countries</mat-card-subtitle> -->

  <p>
    If you are interested in more content and documents, deeper context into the laws and regulations, or a broader understanding of the different employment categories relevant to your country of interest, we encourage you to <strong>UPGRADE</strong> to a full subscription.  

  </p>
  <app-contact-us displayStyle="button" label="Contact us for more information" instructions="This notifications-only user has requested that they be contacted while viewing a promotions sidebar about the full version of GPS." [interaction]="false"></app-contact-us>
</mat-card>

