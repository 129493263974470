

<div class="updatelist__filter-wrapper" *ngIf="!(noUpdatesSinceAccountCreated$|async)">
  <form>
    <mat-form-field fxFlexFill
                    appearance="outline"
                    class="formfield__no-label formfield__no-padding updatelist__filter"
                    [style.width.px]=400
                    [style.min-width.px]=400>
      <input matInput  placeholder="Search by keyword" [formControl]="filter" (change)="updateFilter($event)" maxlength="20">
      <mat-icon matSuffix svgIcon="search"></mat-icon>
    </mat-form-field>

    <mat-form-field fxFlexFill
                    appearance="outline"
                    class="formfield__no-label formfield__no-padding updatelist__country"
                    [style.width.px]=400
                    [style.min-width.px]=400
                    floatLabel="never">
      <mat-select [formControl]="filterCountry" (selectionChange)="updateFilter($event)" placeholder="Filter by country">
        <mat-option [value]="">All countries</mat-option>
        <mat-option *ngFor="let country of filterCountries$|async" [value]="country.iso2">
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</div>


<mat-table [dataSource]="history$ | async" *ngIf="!(noUpdatesSinceAccountCreated$|async)">
  <ng-container matColumnDef="country">
    <mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
    <mat-cell *matCellDef="let history"><a [routerLink]="['/country', history.country_slug]">{{ history.country_name }}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="section">
    <mat-header-cell *matHeaderCellDef>Section</mat-header-cell>
    <mat-cell *matCellDef="let history">{{ history.section_name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef>Published Date</mat-header-cell>
    <mat-cell *matCellDef="let history">{{ history.date|date:'MMMM yyyy' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="update_description">
    <mat-header-cell *matHeaderCellDef>Update Summary</mat-header-cell>
    <mat-cell *matCellDef="let history">{{ history.update_description }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['country', 'date', 'section', 'update_description']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['country', 'date', 'section', 'update_description'];"></mat-row>

</mat-table>

<div *ngIf="loading" class="updatelist__loading">
  Loading...
</div>
<div *ngIf="noUpdatesSinceAccountCreated$|async" class="updatelist__noupdates">
  Welcome to GPS Updates. No updates have been published since your account was created.
  When new updates are published, you will be able to view them here.
</div>
<div *ngIf="((history$ | async)?.length == 0) && !(noUpdatesSinceAccountCreated$|async)"  class="updatelist__noresults">
  No items match your filter selections
</div>

<mat-paginator *ngIf="(history$ | async)?.length && !loading"
               #paginator
               class="paginator"
               (page)="handlePageEvent($event)"
               [showFirstLastButtons]="false"
               [pageSize]="20"
               [hidePageSize]="true"
               [pageIndex]="page"
               [length]="(response$ | async)?.count"
               aria-label="Select page">
</mat-paginator>
