import { Component, OnInit, Input } from '@angular/core';
import { OrganizationEditMemberComponent, OrganizationEditMemberDialog } from '../organization-edit-member/organization-edit-member.component';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { Organization } from 'src/app/models/organization';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-organization-add-member',
  templateUrl: './organization-add-member.component.html',
  styleUrls: ['./organization-add-member.component.scss']
})
export class OrganizationAddMemberComponent extends OrganizationEditMemberComponent implements OnInit {

  public addMember() {
    let dialogRef = this.dialog.open(OrganizationEditMemberDialog, {
      width: '600px',
      data: {
        dialogHeader: "Add user",
        user: {},
        organization: {...this.organization},
        organizationCountries: this.organization.countries.concat(this.organization.notification_countries),
        allCountries: 'all',
        currentUser: this.currentUser
      }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.organizationService.addOrganizationUser(result).subscribe(
          () => this.snackBar.open('User added and welcome email sent', 'Close', {duration: 5000}),
          (error) => this.snackBar.open('Could not add user: '.concat(error), 'Close')
        )
        this.mixpanelService.track("Add user");
      }
      return false;
    });
  }

}
