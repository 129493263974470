import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { OrganizationComponent } from './pages/organization/organization.component';
import { CountryComponent } from './pages/country/country.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { MainComponent } from './main/main.component';
import { CountryProfileComponent } from './pages/country-profile/country-profile.component';
import { CountrySnapshotComponent } from './pages/country-snapshot/country-snapshot.component';
import { CompareComponent } from './pages/compare/compare.component';
import { UpdatesComponent } from './pages/updates/updates.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { AuthGuard } from './services/auth/auth-guard.service';
import { OwnerRoleGuard } from './services/auth/owner-role-guard.service';
import { DocumentsComponent } from './pages/documents/documents.component';
import { RedirectGuard } from './util/redirect-guard.service';
import { environment } from 'src/environments/environment';
import { CountriesComponent } from './pages/countries/countries.component';
import { ErrorComponent } from './pages/error/error.component';
import { HolidaysMatrixComponent } from './pages/holidays-matrix/holidays-matrix.component';
import { AdminRoleGuard } from './services/auth/admin-role-guard.service';
import { PreferencesComponent } from './pages/preferences/preferences.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TokenLoginComponent } from './pages/token-login/token-login.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { CheckoutCompleteComponent } from './pages/checkout-complete/checkout-complete.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CountryAccessGuard } from './services/auth/country-access.guard';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { EmployeeCalculatorComponent } from './pages/employee-calculator/employee-calculator.component';

const env = environment;

const routes: Routes = [
  { path: 'admin', canActivate: [RedirectGuard], component: DashboardComponent,
      data: {
        externalUrl: env.api_root.concat('admin')
      }
  },
  { path: 'login', component: LoginComponent },
  { path: 'tokenlogin/:token', component: TokenLoginComponent },
  { path: 'passwordreset/:uid/:token', component: ResetPasswordComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'purchase',  canActivate: [RedirectGuard], component: PurchaseComponent,
      data: {
        externalUrl: 'https://www.globalpeoplestrategist.com/pricing/'
      }
  },
  { path: 'checkout_complete', component: CheckoutCompleteComponent },
  { path: '', component: MainComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'setpassword', component: SetPasswordComponent, canActivate: [AuthGuard] },
      { path: 'preferences', component: PreferencesComponent, canActivate: [AuthGuard] },
      { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'countries', component: CountriesComponent, canActivate: [AuthGuard] },
      { path: 'compare', component: CompareComponent, canActivate: [AuthGuard] },
      { path: 'country/:slug', component: CountryComponent, canActivate: [AuthGuard] },
      { path: 'country/:slug/profile', component: CountryProfileComponent, canActivate: [AuthGuard, CountryAccessGuard] },
      { path: 'country/:slug/calculator', component: EmployeeCalculatorComponent, canActivate: [AuthGuard, CountryAccessGuard] },
      { path: 'country/:slug/snapshot', component: CountrySnapshotComponent, canActivate: [AuthGuard] },
      { path: 'updates', component: UpdatesComponent, canActivate: [AuthGuard] },
      { path: 'organization', component: OrganizationComponent, canActivate: [AuthGuard, OwnerRoleGuard] },
      { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard] },
      { path: 'calendar', component: CalendarComponent, canActivate: [AuthGuard] },
      { path: 'holidays', component: HolidaysMatrixComponent, canActivate: [AuthGuard, AdminRoleGuard] },
    ]},
  {path: '404', component: MainComponent, children: [ {path: '', component: NotFoundComponent}]},
  {path: '**', redirectTo: '/404'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }
  )],
  exports: [RouterModule]
})



export class AppRoutingModule { }
