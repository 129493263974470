<div fxLayout="row" fxLayoutAlign="start" class="organization__content-wrapper">

  <mat-card >
    <mat-card-title fxLayoutAlign="row" fxLayoutAlign="space-between center">
      <h1>Manage Organization</h1>
    </mat-card-title>
    <mat-card-content>
      <div class="organization__header">
        <h2>{{ (organization$|async)?.name }}</h2>
      </div>

      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" class="organization__document-wrapper">
        <div fxFlex="432px" fx class="organization__section organization__section-left">
          <app-organization-country-list></app-organization-country-list>
        </div>
        <div fxFlex class="organization__section organization__section-main">
          <app-organization-member-list></app-organization-member-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
