import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, of, timer } from 'rxjs';
import { Country } from 'src/app/models/country';
import { shareReplay, map, take, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DocumentSection } from 'src/app/models/document-section';
import { LibraryDocument } from 'src/app/models/library-document';
import { Section } from 'src/app/models/section';
import { SectionBaseline } from 'src/app/models/section-baseline';
import { ServiceBase } from '../service-base';
import { RollbarService } from 'src/app/util/rollbar';
import * as Rollbar from 'rollbar';

class DocumentSectionNode {
  section: DocumentSection;
  children: number[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService implements ServiceBase {
  private env = environment;
  private _countryProfileSections: Map<
    string,
    BehaviorSubject<DocumentSection[]>
  > = new Map();
  private _countrySnapshotSections: Map<
    string,
    BehaviorSubject<DocumentSection[]>
  > = new Map();

  private LOCAL_STORAGE_KEY = 'documentService';

  public orderDocumentSections(sections: DocumentSection[]): DocumentSection[] {
    const tree: Array<DocumentSection> = [];

    if (!sections.length) {
      return tree;
    }

    // Create a dictionary where each node is keyed by its ID
    const nodes = {};
    sections.forEach((element) => {
      const dsn = new DocumentSectionNode();
      dsn.section = element;
      dsn.children = [];
      nodes[element.id] = dsn;
    });

    // Update the dictionary so each node has a reference to its children
    let root: string = null;
    for (const key in nodes) {
      const parent: string = nodes[key].section.parent;
      if (parent) {
        nodes[parent].children.push(key);
      } else {
        root = key;
      }
    }

    // Sort the children
    for (const key in nodes) {
      if (nodes[key].children) {
        nodes[key].children = nodes[key].children.sort(
          (a, b) => nodes[a].section.order - nodes[b].section.order
        );
      }
    }

    // Walk the tree to provide the elements in order
    const queue = [];
    queue.push(root);
    while (queue.length) {
      const current = queue.shift();
      queue.unshift(...nodes[current].children);
      tree.push(nodes[current].section);
    }

    return tree;
  }

  public setSelectedSections(sections: DocumentSection[]): DocumentSection[] {
    const disabledSections = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    const disabledSectionIds = disabledSections ? JSON.parse(disabledSections) : [];
    sections.forEach((section) => {
      section.selected = !disabledSectionIds.includes(section.id);
    });
    return sections;
  }

  /* Get Country Profile document */
  public getCountryProfileSections(
    iso2: string
  ): Observable<DocumentSection[]> {
    if (!this._countryProfileSections.get(iso2)) {
      // Initalize the BehaviorSubject
      const behaviorSections = new BehaviorSubject(null);

      // Save it (keyed by country) since we are going to have multiple of these
      this._countryProfileSections.set(iso2, behaviorSections);

      // Update the subject with the value from the HTTP call
      this.http
        .get<DocumentSection[]>(
          this.env.api_root.concat('headless/profile/sections/', iso2, '/all')
        )
        .pipe(map((sections) => this.orderDocumentSections(sections)))
        .pipe(map((sections) => this.setSelectedSections(sections)))
        .subscribe((result) => {
          behaviorSections.next(result);
        });
    }
    return this._countryProfileSections.get(iso2);
  }

  public getDocumentSection(id: number) {
    return this.http.get<DocumentSection>(
      this.env.api_root.concat('headless/documentsections/', id.toString())
    );
  }

  // TODO: Rationalize our handling of DocumentSections and DocumentSectionTempaltes
  public getDocumentSectionTemplate(id: number) {
    return this.http.get<DocumentSection>(
      this.env.api_root.concat(
        'headless/documentsectiontemplates/',
        id.toString()
      )
    );
  }

  private saveDocumentSection(section: DocumentSection) {
    let disabledSections = [];
    const disabledSectionsString = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (disabledSectionsString) {
      disabledSections = JSON.parse(disabledSectionsString);
    }
    if (section.selected) {
      disabledSections = disabledSections.filter((s) => s !== section.id);
    } else {
      disabledSections.push(section.id);
    }
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(disabledSections));
    return of({});
  }

  public toggleDocumentSections(
    iso2: string,
    sectionIds: number[],
    value: boolean
  ) {
    const sectionsSubject: BehaviorSubject<DocumentSection[]> =
      this._countryProfileSections.get(iso2);
    const sections: DocumentSection[] = sectionsSubject.getValue();
    if (sections) {
      sectionIds.forEach((element) => {
        const s: DocumentSection = this.findSection(sections, element);
        if (s) {
          s.selected = value;
          this.saveDocumentSection(s)
            .pipe(take(1))
            .subscribe(
              (result) => {},
              // TODO: Change this to aggregate the potentially multiple items being updated and return an
              // observable that contains all the errors
              (error) => {
                console.error(
                  'We were unable to save your changes. Please try again later.'
                );
                this.rollbar.error(
                  'Could not save toggles status change of document sections '
                );
              }
            );
        }
      });
      sectionsSubject.next(sections);
    }
  }

  /* Update the section being displayed once it's been updated. This is purely for front-end display
     purposes - it does not save any changes. */
  public refreshDocumentSection(section: DocumentSection, country: Country) {
    const sectionsSubject = this._countryProfileSections.get(country.iso2);
    if (sectionsSubject) {
      const sections = sectionsSubject.getValue();
      const toUpdate = this.findSection(sections, section.id);
      if (toUpdate) {
        toUpdate.content = section.content;
        sectionsSubject.next(sections);
      }
    }
  }

  /* Get Country Snapshot */
  public getCountrySnapshotSections(
    iso2: string
  ): Observable<DocumentSection[]> {
    if (!this._countrySnapshotSections.get(iso2)) {
      // Initalize the BehaviorSubject
      const behaviorSections = new BehaviorSubject([]);

      // Save it (keyed by country) since we are going to have multiple of these
      this._countrySnapshotSections.set(iso2, behaviorSections);

      // Update the subject with the value from the HTTP call
      this.http
        .get<DocumentSection[]>(
          this.env.api_root.concat('headless/snapshot/sections/', iso2, '/all')
        )
        .pipe(map((sections) => this.orderDocumentSections(sections)))
        .subscribe((result) => {
          behaviorSections.next(result);
        });
    }
    return this._countrySnapshotSections.get(iso2);
  }

  private findSection(sections: DocumentSection[], id: number) {
    return sections.find((element) => {
      return element.id === id;
    });
  }

  getComparableSectionBaselines(): Observable<SectionBaseline[]> {
    return this.http
      .get<SectionBaseline[]>(
        this.env.api_root.concat('headless/baselines/comparable')
      )
      .pipe(shareReplay(1));
  }

  public getCompareSections(
    sections: number[],
    iso2s: string[]
  ): Observable<any[]> {
    let params = new HttpParams();
    if (sections) {
      params = params.set('sections', sections.join(','));
    }
    if (iso2s) {
      params = params.set('countries', iso2s.join(','));
    }
    return this.http
      .get<any[]>(this.env.api_root.concat('headless/baselines/compare'), {
        params,
      })
      .pipe(shareReplay(1));
  }

  public clearCache() {
    this._countryProfileSections = new Map();
    this._countrySnapshotSections = new Map();
  }

  constructor(
    private http: HttpClient,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    timer(this.env.cache_timeout, this.env.cache_timeout).subscribe(() =>
      this.clearCache()
    );
  }
}
