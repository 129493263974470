<a href="https://www.globalpeoplestrategist.com"><img class="logo" src="/assets/images/logo-header.png" /></a>
<div fxLayout="row" fxLayoutAlign="center start" class="purchase__container">

  <div class="purchase__form-wrapper" fxFlex="50">
    <!-- <div fxLayout="row" fxLayoutAlign="end start" fxFlex class="purchase-row"> -->
    <form *ngIf="!checkoutComplete" [formGroup]="form" class="purchase-column">

      <h1 class="header">
        Subscribe to <br />Global People Strategist
      </h1>

      <h2>Pricing</h2>
      <div class="toggle-wrapper">
        <div class="toggle">
          <div class="yearly" (click)="yearly()" [class.active]="interval == 'yearly'">Yearly</div>
          <div class="monthly" (click)="monthly()" [class.active]="interval == 'monthly'">Monthly</div>
        </div>
      </div>
      <table class="monthly" *ngIf="interval == 'monthly'">
        <tr>
          <td>1 to 3 countries</td>
          <td><b>$140</b> per country/month</td>
        </tr>
        <tr>
          <td>4 to 10 countries</td>
          <td><b>$119</b> per country/month</td>
        </tr>
        <tr>
          <td>11 to 20 countries</td>
          <td><b>$105</b> per country/month</td>
        </tr>
        <tr>
          <td>21 or more countries</td>
          <td><a href="mailto:info@globalpeoplestrategist.com">Contact Us!</a></td>
        </tr>
      </table>

      <table class="yearly" *ngIf="interval == 'yearly'">
        <tr>
          <td>1 to 3 countries</td>
          <td><b>$1,500</b> per country/year</td>
        </tr>
        <tr>
          <td>4 to 10 countries</td>
          <td><b>$1,300</b> per country/year</td>
        </tr>
        <tr>
          <td>11 to 20 countries</td>
          <td><b>$1,200</b> per country/year</td>
        </tr>
        <tr>
          <td>21 or more countries</td>
          <td><a href="mailto:info@globalpeoplestrategist.com">Contact Us!</a></td>
        </tr>
      </table>

      <h2>Select countries</h2>

      <div *ngFor="let item of form.get('countries').controls; index as i;" formArrayName="countries">
        <mat-form-field appearance="outline" matNativeControl style="margin-bottom: -1.25em" >
          <mat-select [formControlName]="i" placeholder="Select a country" (selectionChange)="updatePurchaseText()">
            <mat-option value="" >Select a country</mat-option>
            <mat-option value="" *ngIf="!(allCountries$| async)?.length">Loading...</mat-option>
            <mat-option *ngFor="let country of allCountries$| async" value="{{ country.iso2 }}">{{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <a class="add-another" *ngIf="i == form.get('countries').controls.length - 1" (click)="addAnother()">Add another
          country</a>
      </div>
      <div>
        <button mat-flat-button class="start-purchase-button" color="accent" type="submit" (click)="startPurchase()">{{
          purchaseText }}</button>
      </div>

    </form>
    <div *ngIf="checkoutComplete && checkoutClosed" class=purchase-confirmation>
      <h2>Thank you!</h2>
      <p>Your purchase is complete. We are setting up your account, and you will soon receive an email with instructions
        on how to log in.</p>
      <p>We highly recommend <a href="https://calendly.com/gps-onboarding/gps-onboarding" target="_blank">scheduling an onboarding call</a>, which is the best way to become familiar with
        all that GPS has to offer</p>
      <p>If you have any questions, please contact us at <a
          href="mailto:support@globalpeoplestrategist.com">support@globalpeoplestrategist.com</a></p>
    </div>
  </div>
  <!-- </div> -->
  <div class="purchase__content-wrapper" fxFlex="50">
    <!-- <div fxLayout="column" fxLayoutAlign="center" fxFlex  class="purchase-column"> -->

    <div class="header">What you get with<br /> Global People Strategist</div>
    <h2>For your subscription countries</h2>
    <ul>
      <li>Country profiles with <b>in-depth labor laws and regulations</b>
      <li><b>Document library</b> with sample templates and guides, both local and universal
        <ul>
          <li>Employment contracts
          <li>Termination letters
          <li>Handbook templates
          <li>And much, much more!
        </ul>

      <li>Country Snapshots with <b>at-a-glance compliance information</b>

      <li><b>Continuous legal updates</b> sent straight into your account

      <li>Tools to track compliance information <b>relevant to your organization</b>

      <li>Compare countries
      <li>Responsive <b>support team</b> ready to answer your questions

      <li>Always current list of upcoming <b>holidays</b>

      <li><b>Quick country guide</b> with real-time currency converter, local time, and local news feed

    </ul>
    <h2>For all 100+ countries in our database</h2>
    <ul>
      <li>Country Snapshots with <b>at-a-glance compliance information</b>
      <li>Always current list of upcoming <b>holidays</b>

      <li><b>Quick country guide</b> with real-time currency converter, local time, and local news feed
    </ul>
    <!-- </div> -->
  </div>

</div>
