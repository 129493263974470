<span class="month">{{ month?.toJSDate() | date: "MMMM YYYY" }}</span>
<a href (click)="previous()"
  ><div class="calendar__icon-wrapper">
    <mat-icon svgIcon="left"></mat-icon>
  </div></a
>

<a href (click)="next()"
  ><div class="calendar__icon-wrapper">
    <mat-icon svgIcon="right"></mat-icon>
  </div></a
>
