import { Component, OnInit, Input } from '@angular/core';
import { NewsItem } from 'src/app/models/news-item';

@Component({
  selector: 'app-country-news-item',
  templateUrl: './country-news-item.component.html',
  styleUrls: ['./country-news-item.component.scss']
})
export class CountryNewsItemComponent implements OnInit {

  @Input()
  public item: NewsItem;

  constructor() { }

  ngOnInit() {
  }

}
