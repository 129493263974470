import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event/event.service';
import { Country } from 'src/app/models/country';
import { CountryEvent } from 'src/app/models/country-event';
import { CountryService } from 'src/app/services/country/country.service';
import { Observable } from 'rxjs';
import { map, take, shareReplay, tap } from 'rxjs/operators';
import { DocumentSection } from 'src/app/models/document-section';

@Component({
  selector: 'app-holidays-matrix',
  templateUrl: './holidays-matrix.component.html',
  styleUrls: ['./holidays-matrix.component.scss'],
})
export class HolidaysMatrixComponent implements OnInit {
  // public dhEvents$: Observable<Map<Country, CountryEvent[]>>;
  public calendarificEvents$: Observable<
    Map<string, Observable<CountryEvent[]>>
  >;
  public snapshotEvents$: Observable<Map<string, string>>;
  public profileEvents$: Observable<Map<string, string>>;
  public allCountries$: Observable<Country[]>;

  constructor(
    private eventService: EventService,
    private countryService: CountryService
  ) {}

  ngOnInit() {
    this.allCountries$ = this.countryService.allCountries();
    if (!this.allCountries$) {
      return;
    }
    this.allCountries$ = this.allCountries$.pipe(shareReplay(1));

    this.calendarificEvents$ = this.allCountries$.pipe(
      map((countries) => {
        const m: Map<string, Observable<CountryEvent[]>> = new Map();
        countries.forEach((c) => {
          m.set(c.iso2, this.eventService.getEventsForCountryYear(c, 2020));
        });
        return m;
      })
    );

    this.snapshotEvents$ = this.eventService.getSnapshotEvents().pipe(
      map((sections: any[]) => {
        const m: Map<string, string> = new Map();
        sections.forEach((s) => {
          m.set(s.country.iso2, s.content);
        });
        return m;
      })
    );

    this.profileEvents$ = this.eventService.getProfileEvents().pipe(
      map((sections: any[]) => {
        const m: Map<string, string> = new Map();
        sections.forEach((s) => {
          m.set(s.country.iso2, s.content);
        });
        return m;
      })
    );
  }
}
