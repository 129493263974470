<div fxLayout="row" fxLayoutAlign="start" class="documents__content-wrapper">

  <mat-card *ngIf="!(isNotificationOnly$|async)">
    <mat-card-title fxLayoutAlign="row" fxLayoutAlign="space-between center">
      <h1>Documents</h1>
      <app-add-document class="document-page" (document_added)=onDocumentAdded($event)></app-add-document>
    </mat-card-title>
    <mat-card-content class="documents__history-list-wrapper">
      <app-document-list [updateTrigger]="updateTrigger"></app-document-list>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="(isNotificationOnly$|async)">
    <mat-card-title fxLayoutAlign="row" fxLayoutAlign="space-between center">
      <h1>Documents</h1>
    </mat-card-title>
    <mat-card-content class="documents__history-list-wrapper">
      <app-promotion-page imageName="documents">
        <p>Our Documents library contains a myriad of locally compliant templates and samples of useful documents from employment contracts to handbooks, performance management plans to termination checklists, and many, many more. To access the full list across your countries, <strong>UPGRADE</strong> to a full subscription now.
      </app-promotion-page>
    </mat-card-content>
  </mat-card>

</div>
