<div fxLayout="row" class="calendargridevent__wrapper" (click)="viewDetails()">
  <div class="countrylistitem__flag" fxFlex="32px">
    <div class="countrylistitem__flag-placeholder">
      <img
        class="countrylistitem__flag-image"
        src="/assets/images/flags/{{
          event?.country?.slug
        }}-flag-square-icon-64.png"
        onError="this.src='/assets/images/flags/_placeholder-flag-square-icon-64.png'"
      />
    </div>
  </div>

  <div fxFlex class="countrylistitem__text">
    <div class="calendargridevent__countryname">{{ event?.country?.name }}</div>
    <div class="calendargridevent__eventname">{{ event?.name }}</div>
  </div>
</div>
