import { Component, OnInit, Inject } from '@angular/core';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  distinctUntilChanged,
  switchMap,
  shareReplay,
  take,
} from 'rxjs/operators';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { AlertDialog } from 'src/app/util/alert-dialog/alert-dialog';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  private uid: string;
  private token: string;
  public password1: string;
  public password2: string;
  public error: string;

  passwordreset(): void {
    this.error = null;
    if (!this.password1) {
      this.error = 'Password cannot be blank';
      return;
    }
    if (this.password1.length < 8) {
      this.error = 'Password must be at least eight characters long';
      return;
    }
    if (this.password1 !== this.password2) {
      this.error = 'Passwords do not match';
      return;
    }
    this.authService
      .resetPasswordWithToken(
        this.uid,
        this.token,
        this.password1,
        this.password2
      )
      .subscribe(
        (data) => {
          this.error = '';
          const dialogRef = this.dialog.open(AlertDialog, {
            width: '600px',
            data: {
              title: 'Password changed',
              message: 'You will now be redirected to the login page',
              okButtonText: 'OK',
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.router.navigate(['login']);
          });
        },
        (err) => {
          this.error = 'Unable to reset password';
        }
      );
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    const uidParam = 'uid';
    const tokenParam = 'token';
    this.route.params
      .pipe(distinctUntilChanged(), take(1))
      .subscribe((params) => {
        this.uid = params[uidParam];
        this.token = params[tokenParam];
      });
    this.mixpanelService.track('Page: Change Password');
  }
}
