<mat-card>
  <mat-card-title>Event Calendar</mat-card-title>
  <mat-card-subtitle>Keep up to date with events in your countries</mat-card-subtitle>
  <div *ngIf="(events$|async)?.length > 0" class="event-calendar__wrapper">
    <table>
      <ng-container *ngFor="let event of (events$|async) as items; let i = index">
          <tr *ngIf="i == 0 || (items[i]?.date.toISODate().substr(0,10) != items[i-1]?.date.toISODate().substr(0,10))">
            <td class="event-calendar__table-date-header">{{ event?.date|date:'MMMM d' }}</td>
          </tr>
          <tr>
            <td class="event-calendar__table-name">{{ event?.name }}</td>
            <td class="event-calendar__table-country">{{ event?.countryDisplay }}</td>
          </tr>
      </ng-container>
    </table>

    <div class="event-calendar__moreevents">
      <a [routerLink]="['/calendar']">View event calendar</a>
      <hr>
    </div>

  </div>
</mat-card>

