import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Mixpanel } from 'mixpanel-browser';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/services/country/country.service';
import { LibraryDocumentService } from 'src/app/services/library-document/library-document.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss'],
})
export class AddDocumentComponent implements OnInit {
  @Input()
  public country: Country;

  @Output()
  public document_added = new EventEmitter<boolean>();

  public countries$: Observable<Country[]>;

  addDocument(): void {
    this.mixpanelService.track('Open Add Document dialog');
    const dialogRef = this.dialog.open(AddDocumentDialog, {
      width: '600px',
      data: {
        file: null,
        name: '',
        filename: '',
        country_override: this.country,
        country: null,
        countries$: this.countries$,
      },
      autoFocus: true,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.libraryDocumentService
            .createLibraryDocument(
              result.country_override || result.country,
              result.file,
              result.name
            )
            .subscribe(
              () => {
                this.document_added.emit(true);
                this.snackBar.open('Document uploaded', 'Close', {
                  duration: 5000,
                });
                this.mixpanelService.track('Added document', {
                  'GPS Country': result.country_override
                    ? result.country_override.name
                    : result.country.name,
                  'GPS Document Added': result.name,
                });
              },
              (error) => {
                this.snackBar.open(
                  'Could not upload document: '.concat(error.message),
                  'Close'
                );
              }
            );
        }
        return false;
      });
  }

  constructor(
    public dialog: MatDialog,
    private libraryDocumentService: LibraryDocumentService,
    private snackBar: MatSnackBar,
    private countryService: CountryService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.countries$ = this.countryService.standardCountries();
  }
}

export interface AddDocumentDialogData {
  name: string;
  file: File;
  filename: string;
  country_override: Country;
  country: Country;
  countries$: Observable<Country[]>;
}

@Component({
  selector: 'add-document-dialog',
  templateUrl: './add-document-dialog.html',
  // styleUrls: ['./add-document-dialog.scss']
})
export class AddDocumentDialog {
  public error: string;
  public message: string;

  fileSelected(fileInputEvent: any) {
    this.data.file = fileInputEvent.target.files[0];
    this.data.filename = fileInputEvent.target.files[0].name;
    if (!this.data.name) {
      this.data.name = this.data.filename;
    }
  }

  public compareWithFn(item1: Country, item2: Country): Boolean {
    return item1 && item2 ? item1.iso2 === item2.iso2 : item1 === item2;
  }

  constructor(
    public dialogRef: MatDialogRef<AddDocumentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AddDocumentDialogData
  ) {}
}
