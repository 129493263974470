import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  get currentUser$(): Observable<User> {
    return this.userService.currentUser();
  }

  public isOwner(): Observable<Boolean> {
    return this.userService.canManage();
  }

  public isAdmin(): Observable<Boolean> {
    return this.userService.isAdmin();
  }

  public hasSetPassword(): Observable<Boolean> {
    return this.userService.hasSetPassword();
  }

  constructor(private userService: UserService) {}

  ngOnInit() {}
}
