import { Component, OnInit, Input } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { Country } from 'src/app/models/country';
import { LibraryDocument } from 'src/app/models/library-document';
import { map, tap, take, delay } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { LibraryDocumentService } from 'src/app/services/library-document/library-document.service';
import { environment } from 'src/environments/environment';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-country-documents',
  templateUrl: './country-documents.component.html',
  styleUrls: ['./country-documents.component.scss']
})
export class CountryDocumentsComponent implements OnInit {
  private env = environment;

  @Input()
  public country$ : Observable<Country>;
  public country: Country;
  public documents$: Observable<LibraryDocument[]>;
  public activeType: string = "My Documents";
  public showAddDocuments$: Observable<boolean>;
  public loading: boolean = true;
  public canEdit$ : Observable<boolean>;

  private loadDocuments(country: Country) {
    this.documents$ = this.libraryDocumentService.getAllLibraryDocumentsForCountry(country).pipe(
      delay(0),
      map((docs: LibraryDocument[]) => docs ? docs.sort(this.libraryDocumentService.sortDocuments) : docs
      ),
      tap(docs => this.loading = (docs == null)),
    )
  }

  constructor(
    private libraryDocumentService: LibraryDocumentService,
    private mixpanelService: MixpanelService,
    public dialog: MatDialog) { }

  ngOnInit() {
    if (this.country$) {
      this.country$.pipe(take(1)).subscribe(
        country => {
          this.country = country;
          this.loadDocuments(country)
          // Refresh every 30 minutes, since the default timeout for AWS links is one hour
        }
      );
    }
  }

}

