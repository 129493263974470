import { Component, OnInit, Input } from '@angular/core';
import { Country } from 'src/app/models/country';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { Observable } from 'rxjs';
import { Dataset } from 'src/app/models/dataset';
import { mergeMap, tap, shareReplay } from 'rxjs/operators';
import { CurrencyService } from 'src/app/services/currency/currency.service';

@Component({
  selector: 'app-country-dataset',
  templateUrl: './country-dataset.component.html',
  styleUrls: ['./country-dataset.component.scss']
})
export class CountryDatasetComponent implements OnInit {

  @Input()
  public country$: Observable<Country>;
  public dataset: Dataset;
  public dataset$ : Observable<Dataset>;

  public currencyInDollars$() : Observable<number> {
    return this.currencyService.getCountryConversionFromUSD$(this.country$);
  }

  constructor(private datasetService: DatasetService, private currencyService: CurrencyService) { }

  ngOnInit() {
    if (this.country$) {
      this.dataset$ = this.country$.pipe(mergeMap((country) => this.datasetService.getDataset(country?.slug)), shareReplay(1));
    }
  }

}
