import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Message } from 'src/app/models/message';
import { MixpanelService } from '../mixpanel/mixpanel.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private env = environment;

  // http options used for making API calls
  private httpOptions: any;

  public postComment(message: Message): Observable<any> {
    return this.http
      .post<any>(
        this.env.api_root.concat('headless/contact'),
        JSON.stringify(message),
        this.httpOptions
      )
      .pipe(
        map((response) => {
          this.mixpanelService.track('Submit Contact Request');
          return true;
        })
      );
  }

  public postCountryRequest(message: Message): Observable<any> {
    return this.http
      .post<any>(
        this.env.api_root.concat('headless/countryrequest'),
        JSON.stringify(message),
        this.httpOptions
      )
      .pipe(
        map((response) => {
          this.mixpanelService.track('Submit Country Request');
          return true;
        })
      );
  }

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }
}
