import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertDialogData {
  title: string;
  message: string;
  okButtonText: string;
  cancelButtonText: string; /* Optional - if omitted, the cancel button will not be displayed */
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: 'alert-dialog.html',
})
export class AlertDialog {

  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData
  ) {}

}
