
<ng-container *ngFor="let t of currentTime; let i = index;">
  <div *ngIf="i == 0 || (t.localtime.format('LT') != currentTime[i-1].localtime.format('LT'))" class='time__timelistitem'>
    <div class='time__singletime'>
      {{ t.localtime.format('LT') }}
      <span *ngIf="showDays">{{ t.localtime.format('dddd') }}</span>
    </div>
    <div *ngIf="currentTime.length > 1" class="time__zone">{{t.zone}}</div>
  </div>
</ng-container>
