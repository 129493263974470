import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/services/country/country.service';
import { Observable, combineLatest } from 'rxjs';
import { Country } from 'src/app/models/country';
import { UntypedFormControl } from '@angular/forms';
import { finalize, startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-all-countries',
  templateUrl: './all-countries.component.html',
  styleUrls: ['./all-countries.component.scss'],
})
export class AllCountriesComponent implements OnInit {
  /* If we use 'boolean' we get a unit test error */
  public loading: Boolean = true; 
  public filter: UntypedFormControl;
  private filter$: Observable<string>;
  public filteredCountries$: Observable<DisplayCountry[]>;

  public get countries$(): Observable<DisplayCountry[]> {
    return this.filteredCountries$;
  }

  constructor(private countryService: CountryService) {}

  ngOnInit() {
    this.loading = true;
    this.filter = new UntypedFormControl('');

    let allCountries$ = this.countryService
      .allCountries()
      ?.pipe(finalize(() => (this.loading = false)));
    let userCountries$ = this.countryService.userCountries();

    // Need to set a value so that the pipe starts running - otherwise it just waits until someone types
    this.filter$ = this.filter.valueChanges.pipe(startWith(''));

    /* Extend the Country type with some more information we want for display */
    let allDisplayCountries$: Observable<DisplayCountry[]>;
    allDisplayCountries$ = combineLatest(allCountries$, userCountries$).pipe(
      map(([allCountries, userCountries]) => {
        return allCountries.map((c) => {
          return {
            country: c,
            inUserAccount: userCountries.some(
              (element) => element.iso2 === c.iso2
            ),
            hasCountryProfile: false,
          };
        });
      })
    );

    this.filteredCountries$ = combineLatest(
      allDisplayCountries$,
      this.filter$
    ).pipe(
      map(([countries, filterString]) =>
        countries.filter((country) => {
          if (country.country.name)
            return (
              country.country.name
                .toLowerCase()
                .indexOf(filterString.toLowerCase()) !== -1
            );
          else return false;
        })
      )
    );
  }
}

class DisplayCountry {
  country: Country;
  inUserAccount: boolean;
  hasCountryProfile: boolean;
}
