import { Component, OnInit, Input } from '@angular/core';
import { Country } from 'src/app/models/country';
import { Observable, of } from 'rxjs';
import { UpdateService } from 'src/app/services/update/update.service';
import { map, tap, shareReplay } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-country-list-item',
  templateUrl: './country-list-item.component.html',
  styleUrls: ['./country-list-item.component.scss'],
})
export class CountryListItemComponent implements OnInit {
  private _country: Country;
  private _update_count: Observable<number>;
  public notificationOnlyOrganization: Observable<boolean>;
  public show_updates: Boolean;

  @Input()
  set country(country: Country) {
    this._country = country;
  }
  get country(): Country {
    return this._country;
  }

  get country$(): Observable<Country> {
    return of(this._country).pipe(shareReplay(1));
  }

  get updateCount(): Observable<number> {
    return this._update_count;
  }

  toggle(): void {
    this.show_updates = !this.show_updates;
  }

  constructor(
    public updateService: UpdateService,
    public userService: UserService,
  ) {}

  ngOnInit() {
    this.notificationOnlyOrganization = this.userService.organizationIsNotificationOnly();
  }
}
