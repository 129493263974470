import { Component, OnInit } from '@angular/core';
import { RequestCountryComponent } from '../request-country/request-country.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-organization-request-country',
  templateUrl: './organization-request-country.component.html',
  styleUrls: ['./organization-request-country.component.scss']
})
export class OrganizationRequestCountryComponent extends RequestCountryComponent implements OnInit {

  constructor(
    public dialog: MatDialog  ) {
    super(dialog)
  }

  requestCountry() : boolean {
    super.requestCountry();
    return false;
  }

  ngOnInit() {
  }

}
