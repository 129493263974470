import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { distinctUntilChanged, take, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from 'src/app/util/alert-dialog/alert-dialog';

@Component({
  selector: 'app-token-login',
  templateUrl: './token-login.component.html',
  styleUrls: ['../../main/main.component.scss', './token-login.component.scss'],
})
export class TokenLoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private mixpanelService: MixpanelService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params?.subscribe(
      (params) => {
        try {
          const tokenKey = 'token';
          this.authService.tokenLogin(params[tokenKey]);
        } catch (e) {
          this.mixpanelService.track('Login failure from V1 site');
          this.router.navigate(['login']);
        }

        const dialogRef = this.dialog.open(AlertDialog, {
          width: '600px',
          data: {
            title: 'Welcome to GPS (again)',
            message:
              'Welcome to the new and improved GPS! Please update your bookmarks to login to this site directly in the future.',
            okButtonText: 'OK',
          },
        });

        this.router.navigate(['dashboard']);
      },
      (err) => {
        this.mixpanelService.track('Login failure from V1 site');
        this.router.navigate(['login']);
      }
    );
  }
}
