import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    authService: AuthService,
    private mixpanelService: MixpanelService
  ) {
    if (authService.isLoggedIn()) {
      this.mixpanelService.track('Action: Logout');
      authService.logout();
      this.router.navigate(['login']);
    }
  }

  ngOnInit() {}
}
