import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/services/country/country.service';
import { Country } from 'src/app/models/country';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, finalize } from 'rxjs/operators'
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss']
})
export class CountryListComponent implements OnInit {

  public loading : Boolean;
  public filter : UntypedFormControl;
  private filter$ : Observable<string>;
  private filteredCountries$ : Observable<Country[]>;
  public userCountries$ : Observable<Country[]>;

  get countries$() : Observable<Country[]> {
    return this.filteredCountries$
  }

  constructor(public countryService: CountryService) {
  }

  ngOnInit() {
    this.loading = true;
    this.filter = new UntypedFormControl('');

    // Need to set a value so that the combineLatest pipe starts running - otherwise it just waits until someone types
    this.filter$ = this.filter.valueChanges.pipe(startWith(''));

    // Clear the loading flag when the HTTP request is complete
    this.userCountries$ = this.countryService.userCountries().pipe(finalize(() => this.loading = false));

    this.filteredCountries$ = combineLatest(this.userCountries$, this.filter$).pipe(
      map(([countries, filterString]) =>
        countries.filter(country => {
          if (country.name)
            return country.name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
          else
            return false;
        })
      )
    );
  }

}
