<div class="updates-as-list__wrapper" [class.dark-mode]="darkMode">
<h2>Updates</h2>

<div  *ngIf="(updates$|async) === null" class="updatesummary__loading-wrapper">
Loading...
</div>

<div *ngIf="(updates$ | async)?.length == 0" class="updatesummary__noupdates">
  No updates are available for this country
</div>

<div>
  <div *ngFor="let update of (updates$ | async) as updates; let i = index" class="updatesummary__item">
    <div *ngIf="i == 0 || (update.date.toString().substr(0,7) != updates[i-1].date.toString().substr(0,7))"
         class="updatesummary__date">{{update.date|date:'MMMM yyyy' }}</div>
    <div class="updatesummary__section">{{update.section_name}}</div>
    <div class="updatesummary__description">{{update.update_description}}</div>
  </div>
</div>

<div *ngIf="(updates$ | async)?.length > 0" class="updatesummary__allupdates">
  <a [routerLink]="['/updates']" [queryParams]="{country: (country$ | async)?.iso2}">View all updates</a>
  <hr>
</div>
