import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CountryService } from 'src/app/services/country/country.service';
import {
  distinctUntilChanged,
  switchMap,
  shareReplay,
  catchError,
  tap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Country } from 'src/app/models/country';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-country-snapshot',
  templateUrl: './country-snapshot.component.html',
  styleUrls: ['./country-snapshot.component.scss'],
})
export class CountrySnapshotComponent implements OnInit {
  public country$: Observable<Country>;
  public currentYear: string;

  constructor(
    private route: ActivatedRoute,
    private countryService: CountryService,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.country$ = this.route.paramMap.pipe(
      distinctUntilChanged(),
      switchMap((params: ParamMap) =>
        this.countryService.getCountry(params.get('slug'))
      ),
      catchError((_) => {
        this.router.navigate(['404']);
        return of(null);
      }),
      tap((c) =>
        this.mixpanelService.track('Page: Country Snapshot', {
          'GPS Country': c?.name,
        })
      ),
      shareReplay(1)
    );

    this.currentYear = new Date().getFullYear().toString();
  }
}
