<h1 mat-dialog-title>Add Document</h1>
<div mat-dialog-content>
  <p>Add templates and other documents customized for your organization.</p>
  <mat-form-field fxFlexFill appearance="outline" required>
    <mat-label>Document Name</mat-label>
    <input matInput [(ngModel)]="data.name" #name="ngModel" required >
  </mat-form-field>

  <mat-form-field *ngIf="!data.country_override" fxFlexFill appearance="outline" class="editmember__countries">
    <mat-label>Document Country</mat-label>
    <mat-select [(ngModel)]="data.country" [compareWith]="compareWithFn"  #selectedcountries="ngModel" required>
      <mat-option *ngFor="let country of (data.countries$|async)" [value]="country">{{country.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="10px">
    <div><button mat-flat-button color="primary" (click)="fileInput.click()">Select file to upload</button></div>
    <input matInput [(ngModel)]="data.filename" #name="ngModel" (click)="fileInput.click()" required disabled placeholder="PDF, Word, or Excel only">
  </div>

  <input #fileInput hidden="true" type="file" onclick="this.value=null" required="true" (change)="fileSelected($event)" accept=".pdf,.doc,.docx,.xls,.xlsx" >

  <div *ngIf="error"> <mat-error>{{error}}</mat-error></div>
  <div *ngIf="message">{{message}}</div>

</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false" >Cancel</button>
  <button mat-flat-button color="accent"
          [disabled]="name.invalid || fileInput.invalid || !name.value || !fileInput.value || (!data.country && !data.country_override)"
          [mat-dialog-close]="data"
          >Add Document</button>
</div>
