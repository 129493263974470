<div class="countrylist__filter-wrapper" *ngIf="((userCountries$ | async)?.length > 0) || loading">
  <mat-form-field fxFlexFill appearance="outline"
                class="formfield__no-label countrylist__filter">
    <input matInput placeholder="Search by Country Name" [formControl]="filter">
    <mat-icon matSuffix svgIcon="search"></mat-icon>
  </mat-form-field>
</div>
<mat-nav-list>
  <app-country-list-item *ngFor="let country of countries$ | async" [country]="country">
  </app-country-list-item>
</mat-nav-list>
<div *ngIf="loading" className="countrylist__loading">
  <mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar>
  <mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar>
  <mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar>
</div>

<div *ngIf="((countries$ | async)?.length === 0) && ((userCountries$ | async)?.length > 0)  && !loading" class="countrylist__no-countries">
  <p class="empty-message" >No countries match your filter selections</p>
</div>

<div *ngIf="((userCountries$ | async)?.length === 0) && !loading" class="countrylist__no-countries">
  <p class="empty-message first ">
    It looks like you don't have any active countries setup on your account.
  </p>
  <p class="empty-message">
    Either your account is still being configured, or your subscription has expired.
  </p>
  <p class="empty-message">
    Please <app-contact-us></app-contact-us> if you would like to renew, subscribe or just ask us a question. You may also browse our
    <a href [routerLink]="['/countries']">complete list of countries</a>.
  </p>
</div>
