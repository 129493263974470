import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactService } from 'src/app/services/contact/contact.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CountryService } from 'src/app/services/country/country.service';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-request-country',
  templateUrl: './request-country.component.html',
  styleUrls: ['./request-country.component.scss']
})
export class RequestCountryComponent implements OnInit {

  requestCountry() : void {
    let dialogRef = this.dialog.open(RequestCountryDialog, {
      width: '600px',
      data: {email: ''}
    });
  }

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
  }
}

export interface DialogData {
  country: string;
  allCountries$ : Observable<Country[]>;
}

@Component({
  selector: 'request-country-dialog',
  templateUrl: 'request-country-dialog.html',
})
export class RequestCountryDialog {

  constructor(
    private contactService: ContactService,
    private countryService: CountryService,
    public dialogRef: MatDialogRef<RequestCountryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.data.allCountries$ = this.countryService.allCountries();;
  }

  requestCountry() : void {
    if (this.data.country) {
      this.contactService.postCountryRequest({
        type: "Request Country",
        data: {country: this.data.country}
      }).subscribe(
        data => {
          let snackBarRef = this.snackBar.open('Your request to add a country has been submitted', 'Close', {
            duration: 5000
          });
        },
        err => {
          let snackBarRef = this.snackBar.open(
            'There was a problem sending the message. Please contact support@globalpeoplestrategist.com for assistance', 'Close');
        }
      )
    }
  }

}
