import { Component, OnInit, Input } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { Country } from 'src/app/models/country';
import * as dayjs from 'dayjs'
import { take, count } from 'rxjs/operators';
import { CountryTimeZone } from 'src/app/models/country-time-zone';

/* Since the dayjs UTC module doesn't have typescript bindings included, put them here */
declare module 'dayjs' {
  interface Dayjs {
    utc(): Dayjs
    local(): Dayjs
    isUTC(): boolean
    utcOffset(offset: number, keepLocalTime?: boolean): Dayjs
  }
  export function utc(config?: ConfigType, format?: string): Dayjs
}


@Component({
  selector: 'app-country-time',
  templateUrl: './country-time.component.html',
  styleUrls: ['./country-time.component.scss']
})
export class CountryTimeComponent implements OnInit {

  @Input()
  country$: Observable<Country>;

  currentTime: any[];
  showDays: boolean;

  _timer$ : Observable<number>;
  _country: Country;

  private updateTime() : void {
    if (this._country && this._country.timezones) {
      this.currentTime = this._country.timezones.map(tz => {return {localtime: dayjs.utc().add(tz.offset, 'minute'), zone: tz.name}});
      this.showDays = (this.currentTime.some(t => !t.localtime.isToday()))
    }
  }

  constructor() {
    var localizedFormat = require('dayjs/plugin/localizedFormat')
    var isToday = require('dayjs/plugin/isToday')
    var utc = require('dayjs/plugin/utc')
    dayjs.extend(localizedFormat);
    dayjs.extend(isToday);
    dayjs.extend(utc);

   }

  ngOnInit() {
    this.country$?.pipe(take(1)).subscribe(
      country => {
        this._country = country;
    });

    this._timer$ = timer(0, 1000);
    this._timer$.subscribe(
      _ => this.updateTime()
    )

  }

}
