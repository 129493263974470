import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CalendarEvent } from 'src/app/models/calendar-event';

@Component({
  selector: 'app-calendar-grid-event',
  templateUrl: './calendar-grid-event.component.html',
  styleUrls: ['./calendar-grid-event.component.scss']
})
export class CalendarGridEventComponent implements OnInit {

  @Input()
  event: CalendarEvent;

  @Output()
  viewDetailsEvent: EventEmitter<void> = new EventEmitter();

  viewDetails() {
    this.viewDetailsEvent.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
 