import { Component, OnInit } from '@angular/core';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss'],
})
export class CountriesComponent implements OnInit {
  constructor(private mixpanelService: MixpanelService) {}

  ngOnInit() {
    this.mixpanelService.track('Page: All Countries');
  }
}
