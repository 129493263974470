<h1 mat-dialog-title>Search Results</h1>
<div mat-dialog-content>
  <div class="search-count" *ngIf="data.results.length; else noresults">{{ data.results.length }} results for "{{ data.searchTerm }}" </div>
  <ng-template #noresults><div class="search-no-results search-count">No results found for "{{ data.searchTerm }}"</div></ng-template>
  <div *ngFor="let r of data.results" class="other_matches">
    <div>
      <div><a href (click)="select(r.section.id)">{{ r.section.name }}</a></div>
      <div [outerHTML]="r.snippet"></div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false" >Close</button>
</div>
