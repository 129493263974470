<div class="countrylist__filter-wrapper">
  <mat-form-field fxFlexFill appearance="outline"
                class="formfield__no-label countrylist__filter">
    <input matInput placeholder="Search by Country Name" [formControl]="filter">
    <mat-icon matSuffix svgIcon="search"></mat-icon>
  </mat-form-field>
</div>
<div *ngIf="loading" fxLayout="row wrap" fxLayoutGap="20px grid" className="countrylist__loading">
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
  <div class="countrylist__loading-row-wrapper"><mat-toolbar fxLayout="row" class="countrylist__loading-row"></mat-toolbar></div>
</div>
<mat-nav-list fxLayout="row wrap" fxLayoutGap="20px grid">
  <div *ngFor="let country of countries$ | async" class="countrylistitem__toolbar-wrapper">
    <mat-toolbar fxLayout="row" fxLayoutAlign="start center" class="countrylistitem__toolbar">
      <div class="countrylistitem__flag" fxFlex='50px'>
        <div class="countrylistitem__flag-placeholder">
          <img class="countrylistitem__flag-image" src="/assets/images/flags/{{ country.country.slug }}-flag-square-icon-64.png"
              onError="this.src='/assets/images/flags/_placeholder-flag-square-icon-64.png'">
        </div>
      </div>
      <div class="countrylistitem__name"><a [routerLink]="['/country', country.country.slug]" >{{ country.country.name }}</a></div>
      <div fxLayout="row"  fxLayoutAlign="space-between end" class="countrylistitem__link-wrapper">
        <div *ngIf="country.inUserAccount" class="countrylistitem__updates countrylistitem__link">
          <a mat-button [routerLink]="['/updates']" [queryParams]="{country: country.country.slug}" aria-label="Updates">
            <div class="countrylistitem__icon-wrapper"><mat-icon svgIcon="bell" ></mat-icon></div>
          </a>
        </div>
        <div *ngIf="country.inUserAccount" class="countrylistitem__snapshot countrylistitem__link" >
          <a mat-button [routerLink]="['/country', country.country.slug, 'profile']" aria-label="Country Profile">
            <div class="countrylistitem__icon-wrapper"><mat-icon svgIcon="globeperson"></mat-icon></div>
          </a>
        </div>
        <div *ngIf="!country.country.unsupported" class="countrylistitem__snapshot countrylistitem__link"  >
          <a mat-button [routerLink]="['/country', country.country.slug, 'snapshot']" aria-label="Country Snapshot">
            <div class="countrylistitem__icon-wrapper"><mat-icon svgIcon="camera"></mat-icon></div>
          </a>
        </div>

      </div>
    </mat-toolbar>
  </div>
</mat-nav-list>
