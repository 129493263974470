<div *ngIf="!(updates$|async)" class="updatesummary__loading">
  <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>

<div *ngIf="(updates$ | async)?.length == 0" class="updatesummary__noupdates">
  No updates are available for this country
</div>

<table mat-table [dataSource]="updates$ | async" *ngIf="(updates$ | async)?.length > 0">
  <ng-container matColumnDef="section">
    <th mat-header-cell *matHeaderCellDef>Regulation</th>
    <td mat-cell *matCellDef="let update">
      <div class="updatesummary__description">{{update.update_description}}</div>
      <div class="updatesummary__section">{{update.section_name}}</div>
    </td>
    <th mat-footer-cell *matFooterCellDef><a [routerLink]="['/updates']" [queryParams]="{country: (country$ | async)?.iso2}">View all updates</a></th>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Last Updated</th>
    <td mat-cell *matCellDef="let update">
      <div class="updatesummary__date">{{update.date|date:'MMM yyyy' }}</div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['section', 'date']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['section', 'date'];"></tr>
  <tr mat-footer-row *matFooterRowDef="['section']"></tr>

</table>

