<div class="countrysnapshotsection__wrapper" *ngIf="section?.content || isHolidays" [class.is-header]="isHeader">
  <h3 class="countrysnapshotsection__header" *ngIf="previousSectionHeader">{{ previousSectionHeader }}</h3>
  <h3 class="countrysnapshotsection__title">{{ section?.name }}<ng-container *ngIf="isHolidays"> ({{ currentYear }})</ng-container></h3>
  <div *ngIf="!isHolidays else holidays" class="countrysnapshotsection__content" [innerHTML]="section?.content"></div>
  <ng-template #holidays>
    <ul>
      <ng-container *ngFor="let event of (holidays$|async) as items; let i = index">
          <li class="countrysnapshotsection__holiday">{{ event?.date|date:'MMMM d' }} - {{ event?.name }}</li>
      </ng-container>
    </ul>
    <ng-container *ngIf="showNextYearHolidays">
      <h3 class="countrysnapshotsection__title">{{ section?.name }} ({{ currentYear+1 }})</h3>
      <ul>
        <ng-container *ngFor="let event of (nextYearHolidays$|async) as items; let i = index">
            <li class="countrysnapshotsection__holiday">{{ event?.date|date:'MMMM d' }} - {{ event?.name }}</li>
        </ng-container>
      </ul>
    </ng-container>
  </ng-template>
</div>

