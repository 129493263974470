<app-country-banner *ngIf="(country$ | async)?.banner" [country$]="country$"></app-country-banner>
<div fxLayout="row" fxLayoutAlign="start" fxLayout.xs="column" class="country-calculator__content-wrapper">
  <div fxFlex fxFlexOrder.xs="2" class="country__section country__section-main">
    <div class="country-employment-calculator__content-wrapper">
      <mat-card>
        <mat-card-title>
          <div fxLayout="row" fxLayoutAlign="start" class="country-employement-calculator__header-wrapper">
            <h1 fxFlex><span *ngIf="(country$ | async)?.name"><a [routerLink]="['/country', (country$ | async)?.slug]" >{{ (country$ | async)?.name }}</a><span class="separator"> : </span><span class="country-employement-calculator__label">Payroll Calculator</span></span></h1>
          </div>
        </mat-card-title>
        <mat-card-content>
          <app-employment-costs-calculator [country$]="country$" [version]="(country$ | async)?.calculator"></app-employment-costs-calculator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div fxFlex="432px" fxFlexOrder.xs="1" fxFlex.xs class="country__section country__section-sidebar">
    <h1 class="mobile-only">{{ (country$ | async)?.name }}</h1>
    <mat-grid-list cols="2" rowHeight="190px" gutterSize="1px">
      <mat-grid-tile>
        <button *ngIf="(isUserCountry$|async) && ((country$|async)?.type === 'standard'); else requestAccess" mat-flat-button color="accent" class="country__big-button"
              [routerLink]="['/country', (country$ | async)?.slug, 'profile']" >
          <mat-icon svgIcon="globeperson"></mat-icon>
          <div class="country__big-button-title">Country Profile</div>
        </button>
        <ng-template #requestAccess>
          <button mat-flat-button color="accent" class="country__big-button" (click)="requestAddToSubscription()">
            <mat-icon svgIcon="globeperson"></mat-icon>
            <div class="country__big-button-title">Country Profile</div>
          </button>
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile>
        <button *ngIf="!(country$|async)?.unsupported; else requestCreate" mat-flat-button color="accent" class="country__big-button"
            [routerLink]="['/country', (country$ | async)?.slug, 'snapshot']" >
          <mat-icon svgIcon="camera"></mat-icon>
          <div class="country__big-button-title">Country Snapshot</div>
        </button>
        <ng-template #requestCreate>
          <button mat-flat-button color="accent" class="country__big-button" (click)="requestCreateCountry()">
            <mat-icon svgIcon="camera"></mat-icon>
            <div class="country__big-button-title">Country Snapshot</div>
          </button>
        </ng-template>
      </mat-grid-tile>

    </mat-grid-list>
    <button mat-flat-button color="accent" class="country__wide-button"
          [routerLink]="['/country', (country$ | async)?.slug, 'calculator']" fxLayout="row" fxLayoutAlign="left center">
      <mat-icon svgIcon="calculator" inline="true"></mat-icon>
      <div class="country__wide-button-title">Payroll Calculator</div>
    </button>
    <!-- <app-compliance-summary *ngIf="(isUserCountry$|async)"></app-compliance-summary> -->
    <app-country-documents *ngIf="(isUserCountry$|async) && ((country$|async)?.type === 'standard')" [country$]="country$"></app-country-documents>
    <app-country-event-calendar *ngIf="((country$|async)?.type === 'standard')" [country]="(country$|async)"></app-country-event-calendar>
    <app-country-updates-as-list *ngIf="(isUserCountry$|async)" [country$]="country$" [darkMode]="true"></app-country-updates-as-list>
  </div>
</div>

