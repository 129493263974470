<h3>Members</h3>
<div class="organizationmembers__seats">
  <ng-container *ngIf="(organizationUsers$ | async)?.length && (organization$|async)?.seats">Using {{ (organizationUsers$ | async)?.length }} of {{ (organization$|async)?.seats }} seats</ng-container>
</div>

<table mat-table [dataSource]="organizationUsers$">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let person">{{ person.first_name }} {{ person.last_name }}</td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let person">{{ person.email }}</td>
  </ng-container>

  <ng-container matColumnDef="countries">
    <th mat-header-cell *matHeaderCellDef>Countries</th>
    <td mat-cell *matCellDef="let person">
      <ng-container *ngIf="person.countries?.length; else allCountries">
        <span *ngFor="let c of person.countries; let isLast=last">{{c.name}}{{isLast ? "": ", "}}</span>
      </ng-container>
      <ng-template #allCountries>All</ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef>Role</th>
    <td mat-cell *matCellDef="let person">{{ person.organization_role }}</td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let person">
      <app-organization-edit-member [user]="person" [organization]="(organization$|async)" [currentUser]="(currentUser$|async)"></app-organization-edit-member>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let person">
      <a *ngIf="person.email != (currentUser$|async)?.email" class="organizationmemberlist__delete" mat-button (click)="delete(person)"><mat-icon svgIcon="trash"></mat-icon></a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['name', 'email', 'countries', 'role', 'edit', 'delete']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['name', 'email', 'countries', 'role', 'edit', 'delete'];"></tr>

</table>
<div *ngIf="!((organizationUsers$ | async)?.length)" class="organizationmemberlist__loading">
  Loading...
</div>

<app-organization-add-member
  *ngIf="!(organization$|async)?.seats || ((organizationUsers$ | async)?.length < (organization$|async)?.seats)"
  [organization]="(organization$|async)"
  [currentUser]="(currentUser$|async)">
</app-organization-add-member>
