import { Component, OnInit, Inject, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ContactService } from 'src/app/services/contact/contact.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  @Input()
  public label: string = 'Contact Us';

  @Input()
  public instructions: string = 'How can we help you?';

  @Input()
  public displayStyle: 'link' | 'button' = 'link';

  @Input()
  public interaction = true;

  contactUs(): void {
    if (this.interaction) {
      this.mixpanelService.track('Open Contact Us dialog');
      let dialogRef = this.dialog.open(ContactUsDialog, {
        width: '600px',
        data: { email: '', label: this.label, instructions: this.instructions },
        autoFocus: true,
      });
    } else {
      this.mixpanelService.track('Request Contact (no interaction)');
      this.contactService
      .postComment({
        type: 'Contact Us',
        data: { message: this.instructions, page: this.router.url },
      })
      .subscribe(
        (data) => {
          let snackBarRef = this.snackBar.open(
            'Thanks for expressing your interest. We will be in touch shortly.',
            'Close',
            {
              duration: 5000,
              verticalPosition: 'top',
            }
          );
        },
        (err) => {
          let snackBarRef = this.snackBar.open(
            'There was a problem sending the message. Please contact support@globalpeoplestrategist.com for assistance',
            'Close'
          );
        }
      );

    }
  }

  constructor(
    public dialog: MatDialog,
    private mixpanelService: MixpanelService,
    private contactService: ContactService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {}
}

export interface DialogData {
  message: string;
  label: string;
  instructions: string;
}

@Component({
  selector: 'contact-us-dialog',
  templateUrl: 'contact-us-dialog.html',
})
export class ContactUsDialog {
  public label: string;
  public message: string;
  public error: string;

  constructor(
    private contactService: ContactService,
    private router: Router,
    public dialogRef: MatDialogRef<ContactUsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar
  ) {}

  contactus(): void {
    this.contactService
      .postComment({
        type: 'Contact Us',
        data: { message: this.data.message, page: this.router.url },
      })
      .subscribe(
        (data) => {
          let snackBarRef = this.snackBar.open(
            'Your message has been sent',
            'Close',
            {
              duration: 5000,
            }
          );
        },
        (err) => {
          let snackBarRef = this.snackBar.open(
            'There was a problem sending the message. Please contact support@globalpeoplestrategist.com for assistance',
            'Close'
          );
        }
      );
  }
}
