import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit {
  public notificationFrequency = 'monthly';
  public message: string;
  public error: string;
  private user: User;

  public updatePreferences() {
    this.user.update_email_preference = this.notificationFrequency;
    this.userService.saveUser(this.user).subscribe(
      (data) => {
        this.error = '';
        const snackBarRef = this.snackBar.open('Preferences updated!', 'OK');
      },
      (err) => {
        this.error = 'Unable to up preferences';
      }
    );
  }

  constructor(
    public userService: UserService,
    public snackBar: MatSnackBar,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.userService.currentUser()?.subscribe((user) => {
      this.notificationFrequency = user.update_email_preference;
      this.user = user;
    });
    this.mixpanelService.track('Page: Preferences');
  }
}
