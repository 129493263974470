<div>
    <button *ngIf="(documents$ | async)?.length" mat-flat-button color="accent" class="country__wide-button"
          [routerLink]="['/documents']" [queryParams]="{country: (country$ | async)?.iso2}" fxLayout="row" fxLayoutAlign="left center">
      <mat-icon svgIcon="document" inline="true"></mat-icon>
      <div class="country__wide-button-title">Documents</div>
    </button>
    <button *ngIf="!(documents$ | async)?.length" mat-flat-button color="accent" class="country__wide-button"
          [routerLink]="['/documents']" [queryParams]="{country: 'global'}" fxLayout="row" fxLayoutAlign="left center">
      <mat-icon svgIcon="document" inline="true"></mat-icon>
      <div class="country__wide-button-title">Documents</div>
    </button>
</div>
