import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotion-page',
  templateUrl: './promotion-page.component.html',
  styleUrls: ['./promotion-page.component.scss']
})
export class PromotionPageComponent implements OnInit {
  @Input()
  public imageName: string;

  constructor() { }

  ngOnInit(): void {
  }

}
