<div *ngIf="(sections$|async)?.length == 0">
  <div class="countryprofilesection__wrapper--loading">
    <h2 class="countryprofilesection__title--loading">&nbsp;</h2>
    <div class="countryprofilesection__flag-placeholder">
      <img class="countryprofilesection__flag-image" src="/assets/images/flags/{{ (country$ | async)?.slug }}-flag-square-icon-64.png">
    </div>
    <div class="countryprofilesection__content-wrapper--loading">
      <div class="countryprofilesection__content--loading">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    </div>
  </div>
  <div class="countryprofilesection__wrapper--loading">
    <h2 class="countryprofilesection__title--loading">&nbsp;</h2>
    <div class="countryprofilesection__flag-placeholder">
      <img class="countryprofilesection__flag-image" src="/assets/images/flags/{{ (country$ | async)?.slug }}-flag-square-icon-64.png">
    </div>
    <div class="countryprofilesection__content-wrapper--loading">
      <div class="countryprofilesection__content--loading">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    </div>
  </div>
  <div class="countryprofilesection__wrapper--loading">
    <h2 class="countryprofilesection__title--loading">&nbsp;</h2>
    <div class="countryprofilesection__flag-placeholder">
      <img class="countryprofilesection__flag-image" src="/assets/images/flags/{{ (country$ | async)?.slug }}-flag-square-icon-64.png">
    </div>
    <div class="countryprofilesection__content-wrapper--loading">
      <div class="countryprofilesection__content--loading">
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    </div>
  </div>
</div>
<app-country-profile-section
    *ngFor="let section of sections$ | async"
    [section]="section"
    [country$]="country$"
    [canEdit$]="canEdit$()"
    [documentHistory$]="history$"
    ></app-country-profile-section>
