import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEventType } from 'src/app/models/calendar-event-type';
import { Country } from 'src/app/models/country';

export interface CalendarFilterPopupComponentData {
  viewAllCountries: 'all' | 'selected';
  allCountries: Country[];
  allTopics: CalendarEventType[];
  selectedCountries: Country[];
  selectedTopics: CalendarEventType[];
  showHolidays: boolean;
}

@Component({
  selector: 'app-calendar-filter-popup',
  templateUrl: './calendar-filter-popup.component.html',
  styleUrls: ['./calendar-filter-popup.component.scss'],
})
export class CalendarFilterPopupComponent implements OnInit {
  // Note that we're mixing two metaphors here for managing the form - ngModel
  // for the countries, and a FormArray for the topics. This was because we copied
  // the country handling logic from the User Edit popup, and that was built back
  // when we were using ngModel for everything.

  form = new UntypedFormGroup({
    topics: new UntypedFormArray([]),
    showHolidays: new UntypedFormControl(''),
    selectAll: new UntypedFormControl(''),
  });

  get topics(): UntypedFormArray {
    return this.form.get('topics') as UntypedFormArray;
  }

  get showHolidays(): UntypedFormControl {
    return this.form.get('showHolidays') as UntypedFormControl;
  }

  get selectAll(): UntypedFormControl {
    return this.form.get('selectAll') as UntypedFormControl;
  }

  public compareWithFn(item1: Country, item2: Country): Boolean {
    return item1 && item2 ? item1.iso2 === item2.iso2 : item1 === item2;
  }

  public countriesChange(e) {
    if (this.data.viewAllCountries === 'all') {
      this.data.selectedCountries = [];
    }
  }

  public toggleAllTopics(e: MatCheckboxChange) {
    this.topics.controls.forEach((control) => {
      control.setValue(e.checked);
    });
    this.updateSelectedTopics();

    this.form.controls.showHolidays.setValue(e.checked);
    this.data.showHolidays = e.checked;
  }

  public toggleHolidays(e: MatCheckboxChange) {
    this.data.showHolidays = e.checked;
  }

  public updateSelectedTopics() {
    this.data.selectedTopics = [];
    for (let index = 0; index < this.form.get('topics').value.length; index++) {
      if (this.form.get('topics').value[index]) {
        this.data.selectedTopics.push(this.data.allTopics[index]);
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<CalendarFilterPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarFilterPopupComponentData
  ) {}

  ngOnInit(): void {
    this.data.allTopics.forEach((t) => {
      const initialValue = this.data.selectedTopics.find(
        (x) => x.slug === t.slug
      )
        ? true
        : false;
      (this.form.controls.topics as UntypedFormArray).push(
        new UntypedFormControl(initialValue)
      );
    });
    this.form.controls.showHolidays.setValue(this.data.showHolidays);
    this.form.controls.selectAll.setValue(this.data.selectedTopics.length === this.data.allTopics.length);
  }
}
