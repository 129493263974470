import { AfterViewInit, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { Country } from 'src/app/models/country';

@Component({
  selector: 'app-calendar-print-list-country',
  templateUrl: './calendar-print-list-country.component.html',
  styleUrls: ['./calendar-print-list-country.component.scss']
})
export class CalendarPrintListCountryComponent implements OnInit, AfterViewInit {
  @Input()
  events: CalendarEvent[];

  country: Country;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Get the country information from the first event (since all we know about the country is the iso2 code)
    if (this.events) {
      this.country = this.events[0].country;
    }
  }

  ngAfterViewInit(): void {
    // console.log('all done here');
  }
}
