import { Component, OnInit, Input, Injectable, Inject } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Country } from 'src/app/models/country';
import { DocumentSection } from 'src/app/models/document-section';
import { DocumentService } from 'src/app/services/document/document.service';
import { take, finalize } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { RollbarService } from 'src/app/util/rollbar';
import * as Rollbar from 'rollbar';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

/**
 * Node for to-do item
 */
export class SectionNode {
  children: SectionNode[];
  item: string;
  section: DocumentSection;
}

/** Flat to-do item node with expandable and level information */
export class SectionFlatNode {
  item: string;
  section: DocumentSection;
  level: number;
  expandable: boolean;
}

/**
 * The Json object for to-do list data.
 */
const LOADING_DATA = {
  Groceries: {
    'Almond Meal flour': null,
    'Organic eggs': null,
    'Protein Powder': null,
    Fruits: {
      Apple: null,
      Berries: ['Blueberry', 'Raspberry'],
      Orange: null
    }
  },
  Reminders: [
    'Cook dinner',
    'Read the Material Design spec',
    'Upgrade Application to Angular'
  ]
};

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<SectionNode[]>([]);

  get data(): SectionNode[] { return this.dataChange.value; }

  constructor() { }

  loadData(sections : Observable<DocumentSection[]>) {
    sections.subscribe(
      data => {
        if (data) {
          let d = this.buildSectionTree(data);
          this.dataChange.next(d);
        }
      }
    )
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `SectionNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number): SectionNode[] {
    return Object.keys(obj).reduce<SectionNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new SectionNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  // Find a section by section id within a tree, given the root node
  findSection(node: SectionNode, id: number) {
    if (node.section.id == id) {
        return node;
    } else {
        let result = null;
        for (const child of node.children) {
          result = this.findSection(child, id) || result;
          if (result) {
            break;
          }
        }
        return result;
    }
  }

  // Take a list of DocumentSections, ordered by their position in the tree, and turn them into a tree
  // structure to be used with the MatTreeControl
  buildSectionTree(sections : DocumentSection[]) : SectionNode[] {
    return sections.reduce<SectionNode[]>((accumulator, item) => {
      const value = item;
      const node = new SectionNode();
      if (value != null) {
        // TODO: Rename "item" - it's from the sample code, but it's really the "section name"
        node.item = value.name;
        node.section = value;
        node.children = [];
        // If the node has a parent, walk the list of items ALREADY IN THE TREE, to find out
        // where it belongs, and insert it
        if (value.parent) {
          let parent = this.findSection(accumulator[0], value.parent);
          parent.children = parent.children.concat(node);
          return accumulator;
        } else {
          // If the node does not have a parent, it's the root, so just return it
          return accumulator.concat(node);
        }
      }
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: SectionNode, name: string) {
    if (!parent.children) {
      parent.children = [];
    }
    if (parent.children) {
      parent.children.push({
        item: name,
        section: {
          parent: parent.section.id,
          name: name,
          content: "Add your own content here",
          document: parent.section.document,
          owner: parent.section.owner,
          order: 9999,
          selected: true
        },
        children: []} as SectionNode);

      this.dataChange.next(this.data);
    }
  }

  updateItem(node: SectionNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-country-profile-tree',
  templateUrl: './country-profile-tree.component.html',
  styleUrls: ['./country-profile-tree.component.scss'],
  providers: [ChecklistDatabase]
})
export class CountryProfileTreeComponent implements OnInit {

  public loading : boolean = true;

  @Input()
  public country$ : Observable<Country>;
  private _country : Country;
  public sections$: Observable<DocumentSection[]>;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<SectionFlatNode, SectionNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<SectionNode, SectionFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: SectionFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<SectionFlatNode>;

  treeFlattener: MatTreeFlattener<SectionNode, SectionFlatNode>;

  dataSource: MatTreeFlatDataSource<SectionNode, SectionFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<SectionFlatNode>(true /* multiple */);

  constructor(private _database: ChecklistDatabase, public documentService : DocumentService,@Inject(RollbarService) private rollbar: Rollbar, private mixPanelService: MixpanelService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<SectionFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      // Save the old expansion state of the tree
      let oldExpansionStateIds : number[] = [];
      if (this.treeControl.dataNodes) {
        let oldExpansionState = this.treeControl.dataNodes.filter((element) => { return this.treeControl.isExpanded(element); });
        oldExpansionStateIds = oldExpansionState.map((element) => { return element.section.id; })
      }

      // Update the tree
      this.dataSource.data = data;

      // Restore the expansion state
      let expanded = this.treeControl.dataNodes.filter(node => { return node.section && oldExpansionStateIds.includes(node.section.id); });
      expanded.forEach((element) => this.treeControl.expand(element));

      // Update the selected items in the tree to match the data from the server
      let selected = this.treeControl.dataNodes.filter(node => { return node.section && node.section.selected; });
      this.checklistSelection.select(...selected);

      // Expand the (hidden) root node so we can actually see the tree
      this.treeControl.expand(this.treeControl.dataNodes[0])
    });
  }

  ngOnInit() {
    if (this.country$) {
      this.country$.pipe(take(1)).subscribe(
        country => {
          this._country = country;
          if (country) {
            this.sections$ = this.documentService.getCountryProfileSections(country.iso2).pipe(finalize(() => this.loading = false));
            this._database.loadData(this.sections$);
          }
        }
      )
    }
  }

  getLevel = (node: SectionFlatNode) => node.level;

  isExpandable = (node: SectionFlatNode) => node.expandable;

  getChildren = (node: SectionNode): SectionNode[] => node.children;

  hasChild = (_: number, _nodeData: SectionFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: SectionFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: SectionNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
        ? existingNode
        : new SectionFlatNode();
    flatNode.item = node.item;
    flatNode.section = node.section;
    flatNode.level = level;
    flatNode.expandable = !!node.children.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: SectionFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: SectionFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  sectionSelectionToggle(node: SectionFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);

    let idsToToggle : number[] = descendants.map((element) => element.section.id);
    this.documentService.toggleDocumentSections(
      this._country.iso2,
      [...idsToToggle, node.section.id],
      this.checklistSelection.isSelected(node));
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  sectionLeafItemSelectionToggle(node: SectionFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);

    this.documentService.toggleDocumentSections(this._country.iso2, [node.section.id], this.checklistSelection.isSelected(node));
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: SectionFlatNode): void {
    let parent: SectionFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: SectionFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: SectionFlatNode): SectionFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  scrollTo(target : string) {
    let element = document.getElementById(target);
    if (element) {
      const title = element.textContent;
      if (title) {
        this.mixPanelService.track("View Country Profile Section", {section_id: target, section_name: title});
      }
      const y = element.getBoundingClientRect().top + window.pageYOffset - 162;
      window.scrollTo({top: y});
    }
  }

}
