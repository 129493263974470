import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Rollbar from 'rollbar';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { RollbarService } from 'src/app/util/rollbar';
import { environment } from 'src/environments/environment';
import * as chargebee from 'chargebee';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  private apiRoot = environment.api_root;

  // http options used for making API calls
  private httpOptions: any;

  public start_checkout(data: {}): Promise<any> {
    return this.http.post<any>(this.apiRoot.concat('payments/generate_checkout_url'), JSON.stringify(data), this.httpOptions).toPromise();
  }

  // We don't actually do anything with this right now. Their welcome email will be triggered by their
  // account being created in Chargebee
  public checkout_complete(hostedPageId: string): Promise<any> {
    return this.http.post<any>(this.apiRoot.concat('payments/complete_purchase'), {hosted_page_id: hostedPageId }, this.httpOptions).toPromise();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar) {
    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
}
}
