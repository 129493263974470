import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { UserService } from 'src/app/services/user/user.service';
import { CalendarEventFormComponent } from '../calendar-event-form/calendar-event-form.component';

export interface CalendarDayPopupComponentData {
  id: number;
  day: DateTime;
  eventMap: Map<string, CalendarEvent[]>;
}

@Component({
  selector: 'app-calendar-day-popup',
  templateUrl: './calendar-day-popup.component.html',
  styleUrls: ['./calendar-day-popup.component.scss'],
})
export class CalendarDayPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CalendarDayPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarDayPopupComponentData,
  ) {}

  ngOnInit(): void {}
}
