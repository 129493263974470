<app-country-banner *ngIf="(country$ | async)?.banner" [country$]="country$"></app-country-banner>
<div fxLayout="row" fxLayoutAlign="start" fxLayout.xs="column" class="country__content-wrapper">
  <div fxFlex fxFlexOrder.xs="2" class="country__section country__section-main">
    <div class="country__header"
         [ngStyle]="{ 'background-image': (country$|async)?.iso2 && 'url(/assets/images/country-backgrounds/' + (country$|async)?.iso2.toUpperCase() + '-CPD_Background.png)'}">
      <app-country-settings [country$]="country$" *ngIf="(isUserCountry$|async)"></app-country-settings>
    </div>
    <mat-card >
      <mat-card-title fxLayoutAlign="row" fxLayoutAlign="space-between center">
        <h1 fx.Hide.xs="true">{{ (country$ | async)?.name }}</h1>
      </mat-card-title>
      <mat-card-content class="country__country-list-wrapper">
        <img *ngIf="(country$|async)?.iso2" class="country__flag-image" src="/assets/images/flags240/{{ (country$|async)?.iso2|lowercase }}.png">
        <div class="country__overview" [innerHTML]="(country$|async)?.overview"></div>
        <app-country-dataset [country$]="country$"></app-country-dataset>
        <app-country-news [country$]="country$"></app-country-news>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxFlex="432px" fxFlexOrder.xs="1" fxFlex.xs class="country__section country__section-sidebar">
    <h1 class="mobile-only">{{ (country$ | async)?.name }}</h1>
    <mat-grid-list cols="2" rowHeight="190px" gutterSize="1px">
      <mat-grid-tile>
        <button *ngIf="(isUserCountry$|async) && ((country$|async)?.type === 'standard'); else requestAccess" mat-flat-button color="accent" class="country__big-button"
              [routerLink]="['/country', (country$ | async)?.slug, 'profile']" >
          <mat-icon svgIcon="globeperson"></mat-icon>
          <div class="country__big-button-title">Country Profile</div>
        </button>
        <ng-template #requestAccess>
          <button mat-flat-button color="accent" class="country__big-button" (click)="requestAddToSubscription()">
            <mat-icon svgIcon="globeperson"></mat-icon>
            <div class="country__big-button-title">Country Profile</div>
          </button>
        </ng-template>
      </mat-grid-tile>

      <mat-grid-tile>
        <button *ngIf="!(country$|async)?.unsupported; else requestCreate" mat-flat-button color="accent" class="country__big-button"
            [routerLink]="['/country', (country$ | async)?.slug, 'snapshot']" >
          <mat-icon svgIcon="camera"></mat-icon>
          <div class="country__big-button-title">Country Snapshot</div>
        </button>
        <ng-template #requestCreate>
          <button mat-flat-button color="accent" class="country__big-button" (click)="requestCreateCountry()">
            <mat-icon svgIcon="camera"></mat-icon>
            <div class="country__big-button-title">Country Snapshot</div>
          </button>
        </ng-template>
      </mat-grid-tile>
    </mat-grid-list>
    <button *ngIf="(isUserCountry$|async) && (country$|async)?.calculator && ((country$|async)?.type === 'standard')" mat-flat-button color="accent" class="country__wide-button"
          [routerLink]="['/country', (country$ | async)?.slug, 'calculator']" fxLayout="row" fxLayoutAlign="left center">
      <mat-icon svgIcon="calculator" inline="true"></mat-icon>
      <div class="country__wide-button-title">Payroll Calculator</div>
    </button>
    <!-- <app-compliance-summary *ngIf="(isUserCountry$|async)"></app-compliance-summary> -->
    <app-country-documents *ngIf="(isUserCountry$|async) && ((country$|async)?.type === 'standard')" [country$]="country$"></app-country-documents>
    <app-country-event-calendar  [country]="(country$|async)"></app-country-event-calendar>
    <app-country-updates-as-list *ngIf="(isUserCountry$|async)" [country$]="country$" [darkMode]="true"></app-country-updates-as-list>
  </div>
</div>
