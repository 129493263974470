<h3>My Countries</h3>
<mat-nav-list>
  <mat-toolbar *ngFor="let country of countries$ | async" fxLayout="row" fxLayoutAlign="start center" class="organizationcountrylistitem__toolbar">
    <div class="organizationcountrylistitem__flag" fxFlex='50px'>
      <div class="organizationcountrylistitem__flag-placeholder">
        <img class="organizationcountrylistitem__flag-image" src="/assets/images/flags/{{ country.slug }}-flag-square-icon-64.png"
              onError="this.src='/assets/images/flags/_placeholder-flag-square-icon-64.png'">
      </div>
    </div>
    <div class="organizationcountrylistitem__name" fxFlex="275px" ><a [routerLink]="['/country', country.slug]" >{{ country.name }}</a></div >
  </mat-toolbar>
</mat-nav-list>
<div *ngIf="loading" className="organizationcountrylist__loading">
  <mat-toolbar fxLayout="row" class="organizationcountrylist__loading-row"></mat-toolbar>
  <mat-toolbar fxLayout="row" class="organizationcountrylist__loading"></mat-toolbar>
  <mat-toolbar fxLayout="row" class="organizationcountrylist__loading"></mat-toolbar>
</div>
<app-organization-request-country></app-organization-request-country>
