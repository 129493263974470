import { Injectable } from "@angular/core";
import { ServiceBase } from "../service-base";
import { Observable } from "rxjs";
import { Country } from "src/app/models/country";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
import { shareReplay } from "rxjs/operators";
import { EmploymentCost } from "src/app/models/employment-cost";

@Injectable({
  providedIn: 'root',
})
export class CalculatorService implements ServiceBase {
  private env = environment;


  public getSalaryDetails(country: Country, salary: number, currency: string, salary_period: 'monthly'|'yearly', display_period: 'monthly'|'yearly', version: string): Observable<EmploymentCost> {
    
    let params = new HttpParams();
    params = params.set('country', country.iso2);
    params = params.set('salary', salary.toString());
    params = params.set('currency', currency);
    params = params.set('salary_period', salary_period);
    params = params.set('display_period', display_period);

    return this.http
      .get<EmploymentCost>(this.env.api_root.concat('calculator/employment/' + version), { params })
      .pipe(shareReplay(1));
  }


  clearCache(): void {
    throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient) {
  }

}
