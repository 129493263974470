import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Dataset } from 'src/app/models/dataset';
import { Observable } from 'rxjs';
import { shareReplay, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DatasetService {
  private env = environment;

  /* Get dataset for a single country */
  public getDataset(slug: string): Observable<Dataset> {
    return this.http
      .get<Dataset>(this.env.api_root.concat('headless/datasets/', slug))
      .pipe(
        map((d) => (d && d.data ? { data: d.data } : d)),
        shareReplay(1)
      );
  }

  constructor(private http: HttpClient) {}
}
