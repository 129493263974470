import { Component, OnInit } from '@angular/core';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(
    private router: Router,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.mixpanelService.track('Page not found', { page: this.router.url });
  }
}
