<div fxLayout="row" fxLayoutAlign="start" class="countries__content-wrapper">
  <div fxFlex="100%" class="countries__section countries__section-main">
    <mat-card >
      <mat-card-title fxLayoutAlign="row" fxLayoutAlign="space-between center">
        <h1>All Countries</h1>
        <div>
          <a  mat-flat-button color="accent" [matMenuTriggerFor]="wantToMenu" class="countries__wantto">I want to...
            <mat-icon class="icon-white" svgIcon="down"></mat-icon>
          </a>
          <mat-menu xPosition="before"  #wantToMenu="matMenu">
            <mat-nav-list class="actionmenu">
              <a href mat-list-item [routerLink]="['/compare']">
                <h3 matLine>Compare Countries</h3>
                <p matLine>Compare regulations across multiple countries</p>
              </a>
              <app-request-country></app-request-country>
            </mat-nav-list>
          </mat-menu>

        </div>
      </mat-card-title>
      <mat-card-content class="countries__country-list-wrapper">
        <app-all-countries></app-all-countries>
      </mat-card-content>
    </mat-card>
  </div>
</div>
