import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import { CalendarPrintPopupComponent, CalendarPrintPopupComponentData } from '../calendar-print-popup/calendar-print-popup.component';

@Component({
  selector: 'app-calendar-print',
  templateUrl: './calendar-print.component.html',
  styleUrls: ['./calendar-print.component.scss']
})
export class CalendarPrintComponent implements OnInit {
  
  @Output()
  printEvent: EventEmitter<CalendarPrintPopupComponentData> = new EventEmitter(null);

  print() {
    let dialogRef = this.dialog.open(CalendarPrintPopupComponent, {
      width: "400px",
      data: {
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.printEvent.emit(result);
        }
        return false;
      });

  }

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

}
