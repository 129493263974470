import { Component, OnInit, Input, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { History } from 'src/app/models/history';
import { DocumentSection } from 'src/app/models/document-section';
import { take, map } from 'rxjs/operators';
import { DocumentService } from 'src/app/services/document/document.service';
import { CountryProfileSectionComponent } from '../country-profile-section/country-profile-section.component';
import { UserService } from 'src/app/services/user/user.service';
import { CountryService } from 'src/app/services/country/country.service';
import { CountrySettings } from 'src/app/models/country-settings';
import { UpdateService } from 'src/app/services/update/update.service';
import { Update } from 'src/app/models/update';

@Component({
  selector: 'app-country-profile-sections',
  templateUrl: './country-profile-sections.component.html',
  styleUrls: ['./country-profile-sections.component.scss']
})
export class CountryProfileSectionsComponent implements OnInit {

  @Input()
  public country$ : Observable<Country>;
  public sections$: Observable<DocumentSection[]>;
  public history$: Observable<History[]>;

  @ViewChildren(CountryProfileSectionComponent)
  private sectionComponents: CountryProfileSectionComponent[];

  public canEdit$() : Observable<boolean> {
    return this.userService.canUpdate();
  }

  public setBackground(value: boolean) {
    this.sectionComponents.forEach(element => {
      element.setBackground(value);
    });
  }

  constructor(
    public documentService : DocumentService,
    public userService : UserService,
    public updateService : UpdateService) { }

  ngOnInit() {
    if (this.country$) {
      this.country$.pipe(take(1)).subscribe(
        country => {
          if (country) {
            this.sections$ = this.documentService.getCountryProfileSections(country.iso2);
            this.history$ = this.updateService.getFullHistoryForCountry(country).pipe(
              map (history => history.results)
            );
          }
        }
      )
    }
  }

}
