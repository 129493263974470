import { Component, OnInit } from '@angular/core';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { Organization } from 'src/app/models/organization';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';
import { User } from 'src/app/models/user';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from 'src/app/util/alert-dialog/alert-dialog';
import { take, finalize, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-organization-member-list',
  templateUrl: './organization-member-list.component.html',
  styleUrls: ['./organization-member-list.component.scss']
})
export class OrganizationMemberListComponent implements OnInit {

  public organization$ : Observable<Organization>;
  public organizationUsers$ : Observable<User[]>;
  public currentUser$ : Observable<User>;

  public delete(user: User) {
    let dialogRef = this.dialog.open(AlertDialog, {
      width: '600px',
      data: {
        title: 'Delete user',
        message: 'Are you sure you want to delete the user '.concat(user.email, '? This action cannot be undone'),
        okButtonText: "Delete user",
        cancelButtonText: "Cancel"
      }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.organizationService.deleteOrganizationUser(user).subscribe(
          () => {
            let snackBarRef = this.snackBar.open('User deleted', 'Close', { duration: 5000 });
          },
          (error) => {
            let snackBarRef = this.snackBar.open('Could not delete user: '.concat(error), 'Close');
          }
        )
      }
    });
    return false;
  }

  constructor(
    public dialog: MatDialog,
    public organizationService: OrganizationService,
    public userService : UserService,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit() {
    this.organization$ = this.organizationService.getCurrentOrganization();
    this.organizationUsers$ = this.organizationService.getCurrentOrganizationUsers();
    this.currentUser$ = this.userService.currentUser();
  }

}
