import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Country} from 'src/app/models/country';
import {CountryService} from 'src/app/services/country/country.service';
import {CalendarEventType} from 'src/app/models/calendar-event-type';
import {CalendarService} from 'src/app/services/calendar/calendar.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CalendarEventFormData} from 'src/app/models/calendar-event-form-data';
import {UserService} from 'src/app/services/user/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {filter, map, switchMap} from 'rxjs/operators';
import {formatDate} from '@angular/common';

export interface AddEventDialogData {
  id?: number;
  eventFormData: CalendarEventFormData;
  organization: number | null;
}

@Component({
  selector: 'app-calendar-event-form.component.ts',
  templateUrl: './calendar-event-form.component.html',
  styleUrls: ['./calendar-event-form.component.scss'],
})
export class CalendarEventFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('formElement') formElement: ElementRef;
  @ViewChildren('customTopicInput') customTopicInput: QueryList<ElementRef>;

  sub: Subscription = new Subscription();
  canEdit$: Observable<boolean> = this.userService.canUpdate();
  countries$: Observable<Country[]>;
  topics$: Observable<string[]>;
  formData: CalendarEventFormData = {
    name: '',
    country: null,
    event_type: null,
    custom_event_type: "",
    description: '',
    date: '',
  };
  eventTypeMyEvent: CalendarEventType;
  myEventsFound: string;

  readonly myEvents: CalendarEventType = {
    name: 'My Events',
    slug: 'MY_EVENTS_CREATE',
  };
  readonly customTopicValue: CalendarEventType = {
    name: "Add new topic...",
    slug: "custom"
  };

  public compareWithFnCountry(item1: Country, item2: Country): Boolean {
    return item1 && item2 ? item1.iso2 === item2.iso2 : item1 === item2;
  }

  public compareWithFnTopic(
    item1: CalendarEventType,
    item2: CalendarEventType
  ): Boolean {
    return item1 && item2 ? item1.slug === item2.slug : item1 === item2;
  }

  saveEvent(id?: number) {
    this.canEdit$
      ?.pipe(
        filter((can) => !!can),
        switchMap(() => {
          if (id) {
            if (this.data.organization) {
              return this.editEvent(id);
            }
            return null;
          } else {
            return this.addEvent();
          }
        })
      )
      .subscribe(
        (result) => {
          this.calendarService.changes$.next(true);
          id
            ? this.snackBar.open('Event saved', 'Close', {duration: 5000})
            : this.snackBar.open('Event added', 'Close', {duration: 5000});
        },
        (error) => {
          this.snackBar.open(
            'Could not save event: '.concat(
              error.error?.date[0] || error?.detail
            ),
            'Close'
          );
        }
      );
  }

  editEvent(id: number) {
    return this.calendarService.editEvent(id, this.formData);
  }

  addEvent() {
    return this.calendarService.addEvent(this.formData);
  }

  selectTopic() {
    this.formData.custom_event_type = "";
  }

  checkDateFormat() {
    const pattern = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
    let dateValid = false;
    if (this.formData.date) {
      dateValid = !!formatDate(this.formData.date, 'YYYY-MM-dd', 'en-EN').match(
        pattern
      );
    }
    return dateValid;
  }

  checkFieldsRequired() {
    const dateValid = this.checkDateFormat();
    return (
      dateValid &&
      !!this.formData.country &&
      !!this.formData.date &&
      !!((this.formData.event_type && (this.formData.event_type !== this.customTopicValue)) || this.formData.custom_event_type) &&
      !!this.formData.name
    );
  }

  setFormData() {
    if (this.data.eventFormData) {
      this.formData = this.data.eventFormData;
      this.formData.date = this.formData.date.toString();
    }
  }

  initData() {
    this.countries$ = this.countryService.userCountries();
    this.topics$ = this.calendarService
      .getCalendarEventTypes()
      ?.pipe(map((tab) => tab.map((v) => v.name)));
    this.topics$?.subscribe((topics) => {
      this.myEventsFound = topics.find((topic) => topic === 'My Events');
    });
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddEventDialogData,
    private calendarService: CalendarService,
    private countryService: CountryService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.setFormData();
    this.initData();
  }

  ngAfterViewInit() {
    this.sub.add(this.customTopicInput.changes.subscribe(() => {
      this.customTopicInput.length ? this.customTopicInput.first.nativeElement.focus() : null;
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
