<h1 mat-dialog-title>Request a country</h1>
<div mat-dialog-content>
  <p>What country would you like to add?
  </p>
  <mat-form-field fxFlexFill appearance="outline" matNativeControl>
    <mat-select [(ngModel)]="data.country" [placeholder]="(data.allCountries$|async) ? 'Select a Country' : 'Loading...'" [disabled]="!(data.allCountries$|async)">
      <mat-option *ngFor="let country of data.allCountries$| async" value="{{ country.iso2 }}">{{ country.name }}</mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close >Cancel</button>
  <button mat-flat-button color="accent" mat-dialog-close (click)="requestCountry()" cdkFocusInitial>Contact Us</button>
</div>
