import { Injectable } from '@angular/core';
import { DocumentSection } from 'src/app/models/document-section';
import Fuse from 'fuse.js';
import { MixpanelService } from '../mixpanel/mixpanel.service';

export class SearchResult {
  section: DocumentSection;
  field: string;
  snippet?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  // We need to extract the snippet ourselves, rather than relying on the search index, since its indices
  // are based on the content that includes the full HTML
  private extractSnippet(term: string, text: string): string {
    let result = '';

    // Strip the HTML
    const doc = new DOMParser().parseFromString(text, 'text/html');
    const searchText = doc.body.textContent || '';

    // Regular expression to find sentences
    const regex = new RegExp(
      '([^.!?]*' + term + '[^.!?]*[^.!?\\s][.!?][\'"]?)(\\s|$)',
      'gi'
    );
    const matches = regex.exec(searchText);
    if (matches) {
      result = matches[0].trim();
      const regexReplace = new RegExp(term, 'i');
      result = result.replace(regexReplace, '<mark>' + term + '</mark>');
    }
    return result;
  }

  searchCountryProfileIndex(
    searchTerm: string,
    sections: DocumentSection[]
  ): SearchResult[] {
    const options = {
      includeScore: true,
      includeMatches: true,
      ignoreLocation: true,
      threshold: 0,
      keys: [
        { name: 'name', weight: 2 },
        'content',
        { name: 'background', weight: 0.5 },
        { name: 'references', weight: 0.5 },
      ],
    };

    const fuse = new Fuse(sections, options);
    const fuseResult: any[] = fuse.search(searchTerm);
    this.mixpanelService.track('Search Country Profile', {
      'Search Term': searchTerm,
    });

    const result = fuseResult.map((x) => {
      const field = x.matches[0]?.key;
      let snippet = '';
      if (field !== 'name') {
        snippet = this.extractSnippet(searchTerm, x.item[field]);
      }
      return { section: x.item, field, snippet };
    });
    return result;
  }

  constructor(private mixpanelService: MixpanelService) {}
}
