import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  private _is_notification_only: Observable<boolean>;

  get isNotificationOnly$(): Observable<boolean> {
    return this._is_notification_only;
  }

  constructor(
    private userService: UserService,
    private mixPanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.mixPanelService.track('Page: Dashboard');
    this._is_notification_only =
      this.userService.organizationIsNotificationOnly()?.pipe(startWith(null));
  }
}
