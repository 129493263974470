import { Component, OnInit, Input } from '@angular/core';
import { Country } from 'src/app/models/country';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-country-banner',
  templateUrl: './country-banner.component.html',
  styleUrls: ['./country-banner.component.scss']
})
export class CountryBannerComponent implements OnInit {

  @Input()
  public country$: Observable<Country>;

  constructor() { }

  ngOnInit() {
  }

}
