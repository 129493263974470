import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateTime } from 'luxon';

export interface CalendarPrintPopupComponentData {
  start: DateTime,
  end: DateTime,
}

@Component({
  selector: 'app-calendar-print-popup',
  templateUrl: './calendar-print-popup.component.html',
  styleUrls: ['./calendar-print-popup.component.scss'],
})
export class CalendarPrintPopupComponent implements OnInit {

  validDateRangeSelected = false;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  
  public changing() {
    this.validDateRangeSelected = (this.range.controls.start.value && this.range.controls.end.value) ? true : false;
    if (this.validDateRangeSelected) {
      this.data.start = DateTime.fromJSDate(this.range.controls.start.value);
      this.data.end = DateTime.fromJSDate(this.range.controls.end.value);
    }
  }

  constructor(
    public dialogRef: MatDialogRef<CalendarPrintPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarPrintPopupComponentData
  ) {}

  ngOnInit(): void {}
}
