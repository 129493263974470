import { Component, OnInit, Input } from '@angular/core';
import { Country } from 'src/app/models/country';
import { UpdateService } from 'src/app/services/update/update.service';
import { Observable, EMPTY } from 'rxjs';
import { History } from 'src/app/models/history';
import { map, take } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { HistoryFilter } from 'src/app/models/history-filter';


@Component({
  selector: 'app-country-updates',
  templateUrl: './country-updates.component.html',
  styleUrls: ['./country-updates.component.scss']
})
export class CountryUpdatesComponent implements OnInit {

  protected _country$: Observable<Country>;
  protected _updates$: Observable<History[]>;
  protected _updates$$: Observable<Observable<History[]>>;

  @Input()
  public set country$(country: Observable<Country>) { this._country$ = country; }
  public get country$() : Observable<Country> { return this._country$; }

  public get updates$() : Observable<History[]> {
    return this._updates$;
  }

  public get canUpdate$() { return this.userService.canUpdate() }
  public get organizationIsNotificationOnly$() { return this.userService.organizationIsNotificationOnly() }

  protected fetchUpdates(country: Country): Observable<History[]> {
    if (country) {
      const filter: HistoryFilter = {
        country: country.iso2,
        pageSize: 5
      }
      return this.updateService.getHistory(filter).pipe(map((updates) => updates.results));
    } else {
      return EMPTY;
    }
  }

  constructor(public updateService: UpdateService, public userService: UserService) {
  }

  ngOnInit() {
    this._country$.pipe(take(1)).subscribe(
      x => {
        this._updates$ = this.fetchUpdates(x);
       }
    )
  }



}
