import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject, of, timer } from 'rxjs';
import { Country } from 'src/app/models/country';
import { HttpClient, HttpParams } from '@angular/common/http';
import { shareReplay, tap, take } from 'rxjs/operators';
import { History } from 'src/app/models/history';
import { ServiceBase } from '../service-base';
import { MixpanelService } from '../mixpanel/mixpanel.service';
import { PaginatedResponse } from 'src/app/models/paginated-response';
import { HistoryFilter } from 'src/app/models/history-filter';

@Injectable({
  providedIn: 'root',
})
export class UpdateService implements ServiceBase {
  private env = environment;
   
  public getHistory(filter: HistoryFilter): Observable<PaginatedResponse<History>> {
    let params = new HttpParams();
    if (filter.country) {
      params = params.set('country', filter.country);
    }
    if (filter.keyword) {
      params = params.set('keyword', filter.keyword);
    }
    if (filter.page) {
      params = params.set('page', filter.page.toString());
    }
    if (filter.pageSize) {
      params = params.set('pageSize', filter.pageSize.toString());
    }
    return this.http
      .get<PaginatedResponse<History>>(this.env.api_root.concat('headless/history'), 
        { params }
      )
      .pipe(shareReplay(1));
  }

  public getFullHistoryForCountry(country: Country): Observable<PaginatedResponse<History>> {
    const filter = {
      country: country.iso2,
      page: 1,
      pageSize: 999,
    };
    return this.getHistory(filter);
  }

  public clearCache() {
  }

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService
  ) {
    timer(this.env.cache_timeout, this.env.cache_timeout).subscribe(() =>
      this.clearCache()
    )
  }
}
