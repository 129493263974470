import { Injectable } from '@angular/core';
import { shareReplay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Country } from 'src/app/models/country';
import { Observable, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private env = environment;

  private _fxData$: Observable<any>;

  public getFXData$(): Observable<any> {
    if (!this._fxData$) {
      this._fxData$ = this.http
        .get(
          'https://openexchangerates.org/api/latest.json?app_id='.concat(
            this.env.currency_token
          )
        )
        .pipe(shareReplay(1));
    }
    return this._fxData$;
  }

  public getCountryConversionFromUSD$(
    country$: Observable<Country>
  ): Observable<number> {
    return combineLatest([this.getFXData$(), country$.pipe(shareReplay(1))]).pipe(
      map(([fxData, country]) => {
        return fxData.rates[country.currency_iso];
      }),
      shareReplay(1)
    );
  }

  public getCountryConversion$(
    country$: Observable<Country>,
    targetCurrency: string
  ): Observable<number> {
    return combineLatest([this.getFXData$(), country$.pipe(shareReplay(1))]).pipe(
      map(([fxData, country]) => {
        const USDtoCountry = fxData.rates[country.currency_iso];
        const USDtoTarget = fxData.rates[targetCurrency];
        return USDtoTarget / USDtoCountry;
      }),
      shareReplay(1)
    );
  }

  constructor(private http: HttpClient) {}
}
