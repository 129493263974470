import { Injectable } from '@angular/core';
import { Organization } from '../../models/organization';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { first, shareReplay, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService implements ServiceBase {
  private _organization$: BehaviorSubject<Organization>;
  private _users$: BehaviorSubject<User[]>;
  private env = environment;

  public getCurrentOrganization(): Observable<Organization> {
    if (!this._organization$) {
      this._organization$ = new BehaviorSubject(new Organization());
      this.http
        .get<Organization>(this.env.api_root.concat('headless/organization'))
        .subscribe((result) => this._organization$.next(result));
    }
    return this._organization$;
  }

  public getCurrentOrganizationUsers(): Observable<User[]> {
    if (!this._users$) {
      this._users$ = new BehaviorSubject([]);
      this.http
        .get<User[]>(this.env.api_root.concat('headless/organization/users/'))
        .subscribe((result) => this._users$.next(result));
    }
    return this._users$;
  }

  public deleteOrganizationUser(user: User) {
    return this.http
      .delete(
        this.env.api_root.concat(
          'headless/organization/users/',
          user.id.toString(),
          '/'
        )
      )
      .pipe(
        /* Update the current list of users to remove the one we just deleted */
        tap(() => {
          let newUsers: User[];
          newUsers = this._users$.getValue().filter((element) => {
            return element.id !== user.id;
          });
          this._users$.next(newUsers);
        }),
        first(),
        shareReplay(1)
      );
  }

  public saveOrganizationUser(user: User): Observable<User> {
    return this.http
      .patch<User>(
        this.env.api_root.concat(
          'headless/organization/users/',
          user.id.toString(),
          '/'
        ),
        user
      )
      .pipe(
        tap((result) => {
          let u: User;
          u = this.findUser(this._users$.getValue(), result.id);
          if (u) {
            Object.assign(u, result);
            this._users$.next(this._users$.getValue());
          }
        })
      );
  }

  public addOrganizationUser(user: User): Observable<User> {
    return this.http
      .post<User>(
        this.env.api_root.concat('headless/organization/users/'),
        user
      )
      .pipe(
        tap((result) => {
          const users = this._users$.getValue();
          users.push(result);
          this._users$.next(users);
        })
      );
  }

  public isValidEmail(email: string, userId: number): Observable<Boolean> {
    return this.http
      .get<Boolean>(
        this.env.api_root.concat(
          'headless/validate/uniqueemail/',
          userId ? userId.toString() : '0',
          '/',
          email
        )
      )
      .pipe(first(), shareReplay(1));
  }

  private findUser(users: User[], id: number): User {
    return users.find((element) => {
      return element.id === id;
    });
  }

  public clearCache() {
    this._organization$ = null;
    this._users$ = null;
  }

  constructor(private http: HttpClient) {
    timer(this.env.cache_timeout, this.env.cache_timeout).subscribe(() =>
      this.clearCache()
    );
  }
}
