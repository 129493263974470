<h1 mat-dialog-title>Filter Calendar View</h1>
<div mat-dialog-content>
  <form mat-form [formGroup]="form">
  <h2>Topics</h2>

    <mat-checkbox (change)="toggleAllTopics($event)" [formControl]="selectAll" ngDefaultControl>View all topics</mat-checkbox>
    <ng-container formArrayName="topics">
      <div *ngFor="let topic of data.allTopics; let i = index" >
         <mat-checkbox [formControlName]="i" (change)="updateSelectedTopics()">
           {{topic.name}}
         </mat-checkbox>
      </div>
   </ng-container>
   <mat-checkbox (change)="toggleHolidays($event)" [formControl]="showHolidays" ngDefaultControl>Public Holidays</mat-checkbox>

  <h2>Countries</h2>
  <div class="country-controls-wrapper">
    <mat-radio-group fxFlexFill [(ngModel)]="data.viewAllCountries"  [ngModelOptions]="{standalone: true}" aria-label="Select an option" (ngModelChange)="countriesChange($event)" ngDefaultControl>
        <mat-radio-button value="all" style="margin-right: 10px;">View all countries</mat-radio-button>
        <mat-radio-button value="selected">View selected countries</mat-radio-button>
    </mat-radio-group>
    
    <mat-form-field  fxFlexFill appearance="outline" class="calendarfilter__countries">
        <mat-label>Select Countries</mat-label>
        <mat-select [(ngModel)]="data.selectedCountries" multiple [compareWith]="compareWithFn" [ngModelOptions]="{standalone: true}" [disabled]="data.viewAllCountries === 'all'" #selectedcountries="ngModel" ngDefaultControl required>
          <mat-option *ngFor="let country of data.allCountries" [value]="country">{{country.name}}</mat-option>
        </mat-select>
    </mat-form-field>
  </div>
</form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="data" cdkFocusInitial>Apply Filters</button>
</div>
