import { Component, OnInit, Input } from '@angular/core';
import { Country } from 'src/app/models/country';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { DateTime } from 'luxon';
import { CalendarFilter } from 'src/app/models/calendar-filter';

@Component({
  selector: 'app-country-event-calendar',
  templateUrl: './country-event-calendar.component.html',
  styleUrls: ['./country-event-calendar.component.scss']
})
export class CountryEventCalendarComponent implements OnInit {

  @Input()
  public country: Country;

  public loading = true;

  private _events: Observable<CalendarEvent[]>;

  public get events() : Observable<CalendarEvent[]> {
    if (!this._events) {
      this._events = this.getEvents();
    }
    return this._events;
  }

  private getEvents() : Observable<CalendarEvent[]> {
    if (this.country) {
      const now = DateTime.now();
      return this.calendarService.getCalendarEvents(now, now.plus({'years': 1}), new CalendarFilter([this.country])).pipe(
        finalize(() => this.loading = false)
      );
    }
  }

  constructor(private calendarService: CalendarService) { }

  ngOnInit() {
  }

}
