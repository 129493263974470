import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { Country } from 'src/app/models/country';
import { DocumentSection } from 'src/app/models/document-section';
import { CountryService } from 'src/app/services/country/country.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { SearchResult, SearchService } from 'src/app/services/search/search.service';

@Component({
  selector: 'app-country-profile-search',
  templateUrl: './country-profile-search.component.html',
  styleUrls: ['./country-profile-search.component.scss']
})
export class CountryProfileSearchComponent implements OnInit {

  @Input()
  public country$ : Observable<Country>;
  public sections: DocumentSection[];

  searchControl: UntypedFormControl;

  search() {
    let searchTerm = this.searchControl.value;
    const result = this.searchService.searchCountryProfileIndex(searchTerm, this.sections);

    let dialogRef = this.dialog.open(SearchResultsDialog, {
      width: '600px',
      data: {
        searchTerm: searchTerm,
        results: result
      }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        let element = document.getElementById(result);
        if (element) {
          const y = element.getBoundingClientRect().top + window.pageYOffset - 162;
          window.scrollTo({top: y});
        }
      }
      return false;
    });

  }

  constructor(public dialog: MatDialog, private searchService: SearchService, public documentService: DocumentService) { }


  ngOnInit(): void {
    this.searchControl = new UntypedFormControl('');

    if (this.country$) {
      this.country$.pipe(
        take(1),
        mergeMap((country) => this.documentService.getCountryProfileSections(country.iso2))
        ).subscribe(
        result => {
          if (result) {
            this.sections = result;
          }
        }
      )
    }

  }
}


export interface SearchResultsData {
  searchTerm: string;
  results: SearchResult[];
}

@Component({
  selector: 'search-results-dialog',
  templateUrl: 'search-results-dialog.html',
  styleUrls: ['./search-results-dialog.scss']
})
export class SearchResultsDialog {

  public error: string;
  public message: string;

  select(id: string) {
    this.dialogRef.close(id);
    return false;
  }

  constructor(
    public dialogRef: MatDialogRef<SearchResultsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: SearchResultsData,
  ) { }

}
