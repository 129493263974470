import { CalendarEventType } from './calendar-event-type';
import { Country } from './country';

export class CalendarFilter {
  countries?: Country[];
  topics?: CalendarEventType[];
  showHolidays? = true;

  constructor(
    countries: Country[] = [],
    topics: CalendarEventType[] = [],
    showHolidays: boolean = true
  ) {
    (this.countries = countries),
      (this.topics = topics),
      (this.showHolidays = showHolidays);
  }

  description = (): string => {
    let countryString = '';
    let topicString = '';
    if (this.countries?.length) {
      countryString = this.countries.map((c) => c.name).join(', ');
    } else {
      countryString = 'All countries';
    }
    if (this.topics?.length) {
      const topicNames = this.topics.map((t) => t.name);
      if (this.showHolidays) {
        topicNames.push('Holidays');
      }
      topicString = topicNames.sort().join(', ');
    } else {
      if (!this.showHolidays) {
        topicString = 'All topics (except holidays)';
      } else {
        topicString = 'All topics';
      }
    }
    return [countryString, topicString].join(' | ');
  };
}
