import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarFilter } from 'src/app/models/calendar-filter';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { CalendarExportPopupComponent } from '../calendar-export-popup/calendar-export-popup.component';

@Component({
  selector: 'app-calendar-export',
  templateUrl: './calendar-export.component.html',
  styleUrls: ['./calendar-export.component.scss'],
})
export class CalendarExportComponent implements OnInit {
  @Input()
  filter: CalendarFilter;

  export() {
    let dialogRef = this.dialog.open(CalendarExportPopupComponent, {
      width: '600px',
      data: {
        filter: this.filter ?? new CalendarFilter(),
      },
    });
  }

  constructor(
    public dialog: MatDialog,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.mixpanelService.track('Calendar - Open download dialog');
  }
}
