import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from 'src/app/models/country';
import { CountryService } from '../country/country.service';

@Injectable({
  providedIn: 'root'
})
export class CountryAccessGuard implements CanActivate {
  /* Only allow access to standard countries on the user's subscription */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      const countrySlug = route.paramMap.get('slug');     
      return this.countryService.getCountry(countrySlug).pipe(map((c) => {return c.type === 'standard'}));
  }
  
  constructor(public countryService: CountryService) {}
}
