import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { Country } from 'src/app/models/country';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CountryService } from 'src/app/services/country/country.service';
import {
  switchMap,
  distinctUntilChanged,
  take,
  shareReplay,
  catchError,
  tap,
  mergeMap,
  concatMap,
  takeWhile,
} from 'rxjs/operators';
import { DocumentSection } from 'src/app/models/document-section';
import { DocumentService } from 'src/app/services/document/document.service';
import { MatAccordionDisplayMode } from '@angular/material/expansion';
import { CountryProfileSectionsComponent } from 'src/app/components/country-profile-sections/country-profile-sections.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-country-profile',
  templateUrl: './country-profile.component.html',
  styleUrls: ['./country-profile.component.scss'],
})
export class CountryProfileComponent implements OnInit {
  public documentDoesNotExist = false;
  public country$: Observable<Country>;
  public sections$: Observable<DocumentSection[]>;
  public showBackToTop = false;

  @ViewChild(CountryProfileSectionsComponent)
  private sectionsComponent: CountryProfileSectionsComponent;

  public toggleBackground(m: MatSlideToggleChange): void {
    this.sectionsComponent.setBackground(m.checked);
  }

  public backToTop() {
    window.scroll({
      top: 110,
      left: 0,
      behavior: 'smooth',
    });
  }

  scrollToElementWhenReady = (id: string, timeout = 3000, interval = 100) => {
    const startTime = Date.now();

    const checkElement = () => {
      const element = document.getElementById(id);
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY - 162;
        window.scrollTo({ top: y });
        /* Give some more time to load, and then go to the element again.
        This is only needed for Safari, I think */
        setTimeout(() => {
          const y = element.getBoundingClientRect().top + window.scrollY - 162;
          window.scrollTo({ top: y });
        }, 1000);
      } else if (Date.now() - startTime < timeout) {
        // console.log('Element not found, checking again in', interval, 'ms');
        setTimeout(checkElement, interval);
      } else {
        // console.warn(`Element with id ${id} not found within ${timeout}ms`);
      }
    };

    checkElement();
  }

  constructor(
    private route: ActivatedRoute,
    private countryService: CountryService,
    private documentService: DocumentService,
    private scrollDispatcher: ScrollDispatcher,
    private router: Router,
    private mixpanelService: MixpanelService
  ) { }

  ngOnInit() {
    this.country$ = this.route.paramMap.pipe(
      distinctUntilChanged(),
      switchMap((params: ParamMap) =>
        this.countryService.getCountry(params.get('slug'))
      ),
      catchError((_) => {
        this.router.navigate(['404']);
        return of(null);
      }),
      tap((c) =>
        this.mixpanelService.track('Page: Country Profile', {
          'GPS Country': c?.name,
        })
      ),
      shareReplay(1)
    );

    /* We want to check to see if there are NO sections available, and display an error message of some kind */
    this.country$
      .pipe(
        mergeMap((country) =>
          country
            ? this.documentService.getCountryProfileSections(country.iso2)
            : of(null)
        ),
        concatMap((sections) =>
          sections !== null ? of(sections, true) : of(null)
        ),
        takeWhile((s) => s !== true),
        switchMap((sections) => {
          if (sections != null && sections.length === 0) {
            this.documentDoesNotExist = true;
          }
          // console.log('Sections:', sections);

          // Return the query parameter observable
          return this.route.queryParamMap;
        }),
        take(1)
      )
      .subscribe((params: ParamMap) => {
        const id = params.get('id');
        if (id) {
          this.scrollToElementWhenReady(id);
        }
      });

    /* Display back-to-top on load, if necessary */
    if (window.scrollY > 300) {
      const e = document.getElementById('back-to-top');
      this.showBackToTop = true;
      if (e) {
        e.style.opacity = '1';
      }
    }

    /* Update back-to-top on scroll */
    this.scrollDispatcher.scrolled().subscribe(() => {
      if (!this.showBackToTop && window.scrollY > 300) {
        const e = document.getElementById('back-to-top');
        this.showBackToTop = true;
        if (e) {
          e.style.opacity = '1';
        }
      } else if (this.showBackToTop && window.scrollY <= 300) {
        const e = document.getElementById('back-to-top');
        this.showBackToTop = false;
        if (e) {
          e.style.opacity = '0';
        }
      }
    });
  }
}
