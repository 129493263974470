import { Component, OnInit } from '@angular/core';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { Organization } from 'src/app/models/organization';
import { Observable } from 'rxjs';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  public organization$: Observable<Organization>;

  constructor(private organizationService: OrganizationService, private mixpanelService: MixpanelService) {
  }

  ngOnInit() {
    this.organization$ = this.organizationService.getCurrentOrganization();
    this.mixpanelService.track('Page: Manage Organization');
  }

}
