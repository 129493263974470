import { Component, OnInit, Input, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryService } from 'src/app/services/country/country.service';
import { take } from 'rxjs/operators';
import { CountrySettings } from 'src/app/models/country-settings';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user/user.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-country-settings',
  templateUrl: './country-settings.component.html',
  styleUrls: ['./country-settings.component.scss']
})
export class CountrySettingsComponent implements OnInit {
  private _country: Country;
  private _country$: Observable<Country>;
  private _canUpdate$: Observable<boolean>;
  private _countrySettings : CountrySettings;

  @Input()
  set country$(country$: Observable<Country>) { this._country$ = country$; }
  get canUpdate$() : Observable<boolean> { return this._canUpdate$; }
  get countrySettings() : CountrySettings { return this._countrySettings; }

  public populated() : boolean {
    let cs = this._countrySettings;
    if (!cs) {
      return false;
    }
    return (cs &&
            (cs.company_name && (cs.company_name.length > 0)) ||
            (cs.office_hours && (cs.office_hours.length > 0)) ||
            (cs.address && (cs.address.length > 0)) ||
            (cs.contact && (cs.contact.length > 0)));
  }

  private fetchCanUpdate() : Observable<boolean> {
    return this.userService.canUpdate();
  }

  edit() : void {
    let dialogRef = this.dialog.open(EditSettingsDialog, {
      width: '600px',
      data: {settings: { ...this._countrySettings}, country: this._country }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._countrySettings = result.settings;
        this.mixpanelService.track("Update country settings", {"GPS Country": this._country.name});
      }
    });
  }

  constructor(public dialog: MatDialog,
              public countryService: CountryService,
              public userService : UserService,
              private mixpanelService: MixpanelService) { }

  ngOnInit() {
    this._canUpdate$ = this.fetchCanUpdate();
    this._country$.pipe(take(1)).subscribe(
      country => {
        this._country = country;
        this.countryService.getCountrySettings(country.slug).subscribe(
          settings => { this._countrySettings = settings; }
        )
      }
    )
  }

}

export interface DialogData {
  settings: CountrySettings;
  country: Country;
}

@Component({
  selector: 'edit-settings-dialog',
  templateUrl: 'edit-settings-dialog.html',
})
export class EditSettingsDialog {

  public message: string;
  public error: string;

  constructor(
    private countryService: CountryService,
    public dialogRef: MatDialogRef<EditSettingsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar
  ) {}

  save() : void {
    let settingsPatch: CountrySettings = new CountrySettings();
    settingsPatch.company_name = this.data.settings.company_name;
    settingsPatch.office_hours = this.data.settings.office_hours;
    settingsPatch.address = this.data.settings.address;
    settingsPatch.contact = this.data.settings.contact;

    let x = this.countryService.saveCountrySettings(this.data.country.slug, settingsPatch);
    x.subscribe(
      result => { this.dialogRef.close(this.data); },
      error => {
        this.dialogRef.close();
        this.snackBar.open('We were unable to save your changes. Please try again later.', 'Dismiss');
      }
    )
  }

}
