import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { UpdateService } from 'src/app/services/update/update.service';
import { finalize, startWith, map, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { History } from 'src/app/models/history';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/services/country/country.service';
import { ActivatedRoute } from '@angular/router';
import { PaginatedResponse } from 'src/app/models/paginated-response';
import { HistoryFilter } from 'src/app/models/history-filter';

@Component({
  selector: 'app-update-list',
  templateUrl: './update-list.component.html',
  styleUrls: ['./update-list.component.scss'],
})
export class UpdateListComponent implements OnInit {
  public loading: Boolean;
  public filter: UntypedFormControl;
  public filterCountry: UntypedFormControl;
  public filterCountries$: Observable<Country[]>;
  private filteredHistory$: Observable<History[]>;
  public response$: Observable<PaginatedResponse<History>>;
  public noUpdatesSinceAccountCreated$: Observable<Boolean>;
  public page: number = 0;

  get history$(): Observable<History[]> {
    return this.filteredHistory$;
  }

  public update() {
    const filter: HistoryFilter = {
      keyword: this.filter?.value,
      country: this.filterCountry?.value,
      page: this.page + 1
    };
    this.loading = true;    
    this.response$ = this.updateService.getHistory(filter).pipe(
      tap(_ => { this.loading = false }),
    );
    this.filteredHistory$ = this.response$.pipe(map((response) => response.results))
  }

  public updateFilter(event: any) {
    this.page = 0;
    this.update();
  }

  public handlePageEvent(event: any) {
    this.page = event.pageIndex;
    this.update();
  }

  constructor(
    private updateService: UpdateService,
    private countryService: CountryService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loading = true;
    this.filter = new UntypedFormControl('');
    this.filterCountry = new UntypedFormControl(null);

    // Need to set a value so that the combineLatest pipe starts running - otherwise it just waits until someone types
    const filter$: Observable<string> = this.filter.valueChanges.pipe(
      startWith('')
    );
    let country$: Observable<string>;

    if (this.activatedRoute.queryParams) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.filterCountry.setValue(params['country']);
        country$ = this.filterCountry.valueChanges.pipe(
          startWith(params['country'])
        );
      });
    }


    this.update();

    this.filterCountries$ = this.countryService.userCountries();
  }
}
