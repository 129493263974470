import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CalendarEventFormComponent } from '../calendar-event-form/calendar-event-form.component';

@Component({
  selector: 'app-calendar-add-event',
  templateUrl: './calendar-add-event.component.html',
  styleUrls: ['./calendar-add-event.component.scss'],
})
export class CalendarAddEventComponent implements OnInit {
  @Input() canEdit$: Observable<boolean>;

  addEvent() {
    this.dialog.open(CalendarEventFormComponent, {
      width: '600px',
      data: {},
    });
  }

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}
}
