import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  private _is_notification_only: Observable<boolean>;
  public updateTrigger: boolean = false;

  get isNotificationOnly$(): Observable<boolean> {
    return this._is_notification_only;
  }

  public onDocumentAdded() {
    this.updateTrigger = !this.updateTrigger;
  }

  constructor(
    private userService: UserService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    this.mixpanelService.track('Page: Documents');
    this._is_notification_only = this.userService
      .organizationIsNotificationOnly()
      ?.pipe(startWith(false));
  }
}
