import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { DocumentService } from 'src/app/services/document/document.service';
import { UserService } from 'src/app/services/user/user.service';
import { CountryService } from 'src/app/services/country/country.service';
import { take, finalize, map, mergeMap } from 'rxjs/operators';
import { DocumentSection } from 'src/app/models/document-section';

@Component({
  selector: 'app-country-snapshot-sections',
  templateUrl: './country-snapshot-sections.component.html',
  styleUrls: ['./country-snapshot-sections.component.scss']
})
export class CountrySnapshotSectionsComponent implements OnInit {

  @Input()
  public country$ : Observable<Country>;
  public sections$: Observable<DocumentSection[]>;
  public loading : boolean = true;

  constructor(public documentService : DocumentService) { }

    ngOnInit() {
      if (this.country$) {
        this.sections$ = this.country$.pipe(
          take(1),
          mergeMap((country) => this.documentService.getCountrySnapshotSections(country.iso2)),
          map((s) => s.filter((section) => section.parent)), /* Remove top-level item which has the title of the snapshot */
          finalize(() => this.loading = false));
      }
    }

}
