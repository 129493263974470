<h1 mat-dialog-title>Update Country Information</h1>
<div mat-dialog-content>
  <mat-form-field fxFlexFill appearance="outline">
    <mat-label>Organization Name in Country</mat-label>
    <input matInput [(ngModel)]="data.settings.company_name">
  </mat-form-field>

  <mat-form-field fxFlexFill appearance="outline">
    <mat-label>Office Hours</mat-label>
    <input matInput [(ngModel)]="data.settings.office_hours">
  </mat-form-field>

  <mat-form-field fxFlexFill appearance="outline">
    <mat-label>Address</mat-label>
    <textarea matInput [(ngModel)]="data.settings.address" rows="3"></textarea>
  </mat-form-field>

  <mat-form-field fxFlexFill appearance="outline">
    <mat-label>Contact</mat-label>
    <textarea matInput [(ngModel)]="data.settings.contact" rows="3"></textarea>
  </mat-form-field>

  <div *ngIf="error"> <mat-error>{{error}}</mat-error></div>
  <div *ngIf="message">{{message}}</div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close >Cancel</button>
  <button mat-flat-button color="accent" (click)="save()" cdkFocusInitial>Save</button>
</div>
