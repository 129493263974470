import { _getTextWithExcludedElements } from '@angular/cdk/testing';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ThirdPartyPaymentMethod } from 'chargebee-typescript/lib/resources';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { take } from 'rxjs/operators';
import { CalendarEventType } from 'src/app/models/calendar-event-type';
import { CalendarFilter } from 'src/app/models/calendar-filter';
import { Country } from 'src/app/models/country';
import { CalendarService } from 'src/app/services/calendar/calendar.service';
import { CountryService } from 'src/app/services/country/country.service';
import { CalendarFilterPopupComponent } from '../calendar-filter-popup/calendar-filter-popup.component';

@Component({
  selector: 'app-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss'],
})
export class CalendarFilterComponent implements OnInit {
  @Output()
  updateFilterEvent: EventEmitter<CalendarFilter> = new EventEmitter();

  countryString = 'All countries';
  topicString = 'All topics';

  allTopics: CalendarEventType[];
  allCountries: Country[];
  _filter: CalendarFilter;

  @Input()
  get filter(): CalendarFilter {
    return this._filter;
  }
  set filter(f: CalendarFilter) {
    this._filter = f;
    if (this._filter.countries?.length) {
      this.countryString = this._filter.countries.map((c) => c.name).join(', ');
    } else {
      this.countryString = 'All countries';
    }
    if ((this._filter.topics?.length) && (this._filter.topics?.length !== this.allTopics.length)) {
      const topicNames = this._filter.topics.map((t) => t.name);
      if (this._filter.showHolidays) {
        topicNames.push('Holidays');
      }
      this.topicString = topicNames.sort().join(', ');
    } else {
      if (!this._filter.showHolidays) {
        this.topicString = 'All topics (except holidays)';
      } else {
        this.topicString = 'All topics';
      }
    }
  }

  editFilter() {
    let dialogRef = this.dialog.open(CalendarFilterPopupComponent, {
      data: {
        viewAllCountries: this.filter?.countries?.length ? 'selected' : 'all',
        allCountries: this.allCountries,
        selectedCountries: this.filter?.countries || [],
        allTopics: this.allTopics,
        selectedTopics: (!this._filter?.topics || !this._filter?.topics.length) ? this.allTopics : this._filter.topics,
        showHolidays: this.filter ? this.filter?.showHolidays : true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.filter = new CalendarFilter(
            result.selectedCountries,
            result.selectedTopics.length !== this.allTopics.length ? result.selectedTopics : [],
            result.showHolidays);
          this.updateFilterEvent.emit(this.filter);
        }
        return false;
      });
  }

  constructor(
    public dialog: MatDialog,
    public countryService: CountryService,
    public calendarService: CalendarService
  ) {}

  ngOnInit(): void {
    this.countryService
      .userCountries()
      .pipe(take(1))
      .subscribe({
        next: (c: Country[]) => (this.allCountries = c),
      });
    this.calendarService
      .getCalendarEventTypes()
      .pipe(take(1))
      .subscribe({
        next: (c: CalendarEventType[]) => (this.allTopics = c),
      });
  }
}
