import { Injectable } from '@angular/core';
import { Country } from 'src/app/models/country';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay, take, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CountrySettings } from 'src/app/models/country-settings';
import { ServiceBase } from '../service-base';

@Injectable({
  providedIn: 'root',
})
export class CountryService implements ServiceBase {
  private env = environment;
  private _userCountries$: Observable<Country[]>;
  private _countrySettings$: Map<string, BehaviorSubject<CountrySettings>> =
    new Map();

  /* Get details about a single country */
  public getCountry(slug: string): Observable<Country> {
    return this.http
      .get<Country>(this.env.api_root.concat('headless/countries/', slug))
      .pipe(shareReplay(1));
  }

  /* Is this a country to which the user has access (either as a standard or notification country) */
  public isUserCountry(country: Country): Observable<boolean> {
    return this.userCountries().pipe(
      take(1),
      map((countries: Country[]) => countries.map((c) => c.iso2)),
      map((countries: string[]) => countries.includes(country.iso2))
    );
  }

  /* Get settings for a single country for this organization */
  public getCountrySettings(slug: string): Observable<CountrySettings> {
    if (!this._countrySettings$.get(slug)) {
      const behaviorSettings = new BehaviorSubject(new CountrySettings());
      this._countrySettings$.set(slug, behaviorSettings);
      this.http
        .get<CountrySettings>(
          this.env.api_root.concat('headless/countrysettings/', slug)
        )
        .subscribe((result) => {
          behaviorSettings.next(result);
        });
    }
    return this._countrySettings$.get(slug);
  }

  public saveCountrySettings(slug: string, countrySettings: CountrySettings) {
    return this.http
      .patch<CountrySettings>(
        this.env.api_root.concat('headless/countrysettings/', slug),
        countrySettings
      )
      .pipe(
        tap(
          /* Make sure that anyone else subscribing to the BehaviorSubject knows there's an update */
          (settings) => {
            const behaviorSettings = this._countrySettings$.get(slug);
            if (behaviorSettings) {
              behaviorSettings.next(settings);
            }
          }
        )
      );
  }

  public userCountries(): Observable<Country[]> {
    if (!this._userCountries$) {
      this._userCountries$ = this.fetchUserCountries().pipe(shareReplay(1));
    }
    return this._userCountries$;
  }

  /* All non-notification countries */
  public standardCountries(): Observable<Country[]> {
    return this.userCountries().pipe(
      map((countries: Country[]) => countries.filter((c) => c.type === 'standard'))
    );
  }

  /* Get all countries in GPS */
  public allCountries(): Observable<Country[]> {
    return this.http
      .get<Country[]>(this.env.api_root.concat('headless/countries/all'))
      .pipe(shareReplay(1));
  }

  /* Get all supported countries in GPS (no authentication required) */
  public publicAllCountries(): Observable<Country[]> {
    return this.http
      .get<Country[]>(this.env.api_root.concat('headless/public/countries/all'))
      .pipe(shareReplay(1));
  }

  /* Fetch all countries that the current user has access to */
  public fetchUserCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(
      this.env.api_root.concat('headless/countries/user')
    );
  }

  public clearCache() {
    this._countrySettings$ = new Map();
    this._userCountries$ = null;
  }

  constructor(private http: HttpClient) {
    timer(this.env.cache_timeout, this.env.cache_timeout).subscribe(() =>
      this.clearCache()
    );
  }
}
