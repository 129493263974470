import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { CalendarFilter } from 'src/app/models/calendar-filter';
import {
  CalendarService,
  GroupedCalendarDays,
} from 'src/app/services/calendar/calendar.service';

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.scss'],
})
export class CalendarListComponent implements OnInit {
  @Input()
  month: DateTime;

  @Input()
  filter: CalendarFilter;

  events: GroupedCalendarDays;

  constructor(private calendarService: CalendarService) {}

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    if (this.month) {
      this.events = await this.calendarService
        .getGroupedCalendarEvents(
          this.month.startOf('month'),
          this.month.endOf('month'),
          this.filter
        )
        .toPromise();
    }
  }
}
