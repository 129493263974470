<h1 mat-dialog-title>Add to Calendar</h1>
<div mat-dialog-content>
  <p>
    Add these events to your calendar and automatically receive updates when
    dates change or new events are added.
  </p>
  <div class="filter-description">Your selection: {{ filterDescription }}</div>
  <div class="button-container">
    <a mat-stroked-button class="subscribe-link" [href]="subscribeUrl"
      ><mat-icon svgIcon="apple"></mat-icon>Apple Calendar</a
    >
  </div>
  <div class="button-container">
    <a
      mat-stroked-button
      class="subscribe-link"
      [href]="googleSubscribeUrl"
      target="_blank"
      ><mat-icon svgIcon="google"></mat-icon>Google</a
    >
  </div>
  <div class="button-container">
    <a
      mat-stroked-button
      class="subscribe-link"
      [href]="outlookOnlineSubscribeUrl"
      target="_blank"
      ><mat-icon svgIcon="outlookcom"></mat-icon>Outlook.com</a
    >
  </div>
  <div class="button-container">
    <a mat-stroked-button class="subscribe-link" [href]="subscribeUrl"
      ><mat-icon svgIcon="outlook"></mat-icon>Outlook
      <span>(desktop app)</span></a
    >
  </div>
  <div class="button-container">
    <a
      mat-stroked-button
      class="subscribe-link"
      [href]="officeOnlineSubscribeUrl"
      target="_blank"
      ><mat-icon svgIcon="office365"></mat-icon>Office 365</a
    >
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button [mat-dialog-close]="false" cdkFocusInitial>
    Close
  </button>
</div>
