import { Component, OnInit, Input } from '@angular/core';
import { UpdateService } from 'src/app/services/update/update.service';
import { UserService } from 'src/app/services/user/user.service';
import { CountryUpdatesComponent } from '../country-updates/country-updates.component';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { History } from 'src/app/models/history';

@Component({
  selector: 'app-country-updates-as-list',
  templateUrl: './country-updates-as-list.component.html',
  styleUrls: ['./country-updates-as-list.component.scss']
})
export class CountryUpdatesAsListComponent extends CountryUpdatesComponent implements OnInit  {

  @Input()
  public darkMode : boolean;

  @Input()
  public set country$(country: Observable<Country>) { this._country$ = country; }
  public get country$() : Observable<Country> { return this._country$; }

  public get updates$() : Observable<History[]> {
    return this._updates$;
  }

  constructor(public updateService: UpdateService, public userService: UserService) {
    super(updateService, userService);
    this.darkMode = false;
  }

}
