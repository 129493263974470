import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { CalendarDayPopupComponent } from '../calendar-day-popup/calendar-day-popup.component';

@Component({
  selector: 'app-calendar-grid-day',
  templateUrl: './calendar-grid-day.component.html',
  styleUrls: ['./calendar-grid-day.component.scss'],
})
export class CalendarGridDayComponent implements OnInit {
  @Input()
  day: DateTime;

  @Input()
  countryEventMap: Map<string, CalendarEvent[]>;

  eventList: CalendarEvent[];
  holidayList: CalendarEvent[];

  isToday = false;
  isMonthTransition = false;

  viewDetails(id: number) {
    let dialogRef = this.dialog.open(CalendarDayPopupComponent, {
      width: '900px',
      data: {
        id,
        day: this.day,
        eventMap: this.countryEventMap,
      },
    });
  }

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    const now = DateTime.now().startOf('day');
    this.isToday = this.day?.startOf('day').diff(now, 'day').days === 0;
    this.isMonthTransition =
      this.day &&
      (this.day?.day == 1 || this.day?.daysInMonth == this.day?.day);
  }

  ngOnChanges(changes: SimpleChanges) {
    const nestedEvents = this.countryEventMap ? [...this.countryEventMap?.values()] : [];
    // Flatten the array
    const allEvents = nestedEvents.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );
    this.eventList = allEvents.filter((e) => !e.holiday_type);
    this.holidayList = allEvents.filter((e) => e.holiday_type);
  }
}
