<div fxLayout="column" fxFlex="100%" fxLayoutAlign="start" class="currency__wrapper" *ngIf="(currencies$|async) && working">

  <div fxLayout="row"  fxLayoutGap="10px">
    <mat-form-field fxFlex="100px">
      <input matInput
      (ngModelChange)="baseCurrencyAmount=$event"
      [ngModel]="baseCurrencyAmount"
      (input)="onBaseChange()">
    </mat-form-field>
    <div fxFlex="300px" class="currency__base-currency">{{(country$|async)?.currency_name}}</div>
  </div>
  <div class="currency__equals">=</div>
  <div fxLayout="row" fxLayoutGap="10px">
    <mat-form-field fxFlex="100px">
      <input matInput
      (ngModelChange)="otherCurrencyAmount=$event"
      [ngModel]="otherCurrencyAmount"
      (input)="onOtherChange()">
    </mat-form-field>

    <mat-form-field  fxFlex="300px">
      <mat-select [(ngModel)]="otherCurrency" (selectionChange)='onChangeCountry()'>
        <mat-option value='USD'>US Dollar (USD)</mat-option>
        <mat-option value='EUR'>Euro (EUR)</mat-option>
        <mat-option *ngFor="let c of (currencies$|async)|keyvalue:currencyOrder" [value]="c.key">{{c.value}} ({{c.key}})</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
