import { Component, OnInit } from '@angular/core';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['../../main/main.component.scss', './error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(private mixpanelService: MixpanelService) {}

  ngOnInit() {
    this.mixpanelService.track('Page: Error');
  }
}
