import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/services/country/country.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-organization-country-list',
  templateUrl: './organization-country-list.component.html',
  styleUrls: ['./organization-country-list.component.scss']
})
export class OrganizationCountryListComponent implements OnInit {


  public loading : Boolean;
  private _countries$ : Observable<Country[]>;

  get countries$() : Observable<Country[]> {
    return this._countries$;
  }

  constructor(public countryService: CountryService) {
  }

  ngOnInit() {
    this.loading = true;
    let userCountries$ = this.countryService.userCountries();
    if (userCountries$) {
      this._countries$ = userCountries$.pipe(finalize(() => this.loading = false));
    }
  }

}
