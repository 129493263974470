<h1 mat-dialog-title>Recover password</h1>
<div mat-dialog-content>
  <p>Enter your email address below. We will send you an email with a link you can use to reset your password.</p>
  <p>If the email has not arrived after five minutes, please check your spam folder.</p>
  <mat-form-field fxFlexFill appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput required [(ngModel)]="data.email" (keyup.enter)="resetPassword()" >
  </mat-form-field>
  <div *ngIf="error"> <mat-error>{{error}}</mat-error></div>
  <div *ngIf="message">{{message}}</div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="accent"  (click)="resetPassword()" cdkFocusInitial>Recover my password</button>
</div>
