<div fxLayout="row" fxLayoutAlign="start" class="updates__content-wrapper">

  <mat-card >
    <mat-card-title fxLayoutAlign="row" fxLayoutAlign="space-between center">
      <h1>Updates</h1>
    </mat-card-title>
    <mat-card-content class="updates__history-list-wrapper">
      <app-update-list></app-update-list>
    </mat-card-content>
  </mat-card>

</div>
