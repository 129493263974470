import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Country } from 'src/app/models/country';
import { EmploymentCost } from 'src/app/models/employment-cost';
import { CalculatorService } from 'src/app/services/calculator/calculator.service';
import { ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { CountryService } from 'src/app/services/country/country.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-employment-costs-calculator',
  templateUrl: './employment-costs-calculator.component.html',
  styleUrls: ['./employment-costs-calculator.component.scss'],
})
export class EmploymentCostsCalculatorComponent implements OnInit {
  @Input()
  public country$: Observable<Country>;

  @Input()
  public version: string;

  @ViewChild('salaryInput')
  salaryInput: ElementRef;

  public currencies$: Observable<{}>;
  public selectedCurrency: string;

  public cost$: Observable<EmploymentCost>;
  public salary: string = " ";
  public salary_period: 'monthly' | 'yearly' = "yearly";
  public display_period: 'monthly' | 'yearly' = "yearly";

  public exportString: string;
  public exportCountry: string;

  formatNumber(num: number): string {
    return this.decimalPipe.transform(num, '1.2-2');
  }

  formatNumberString(num: string): string {
    return this.formatNumber(parseFloat(num));
  }

  onClear() {
    this.cost$ = of(null);    
  }

  createExportString(costs) {
    let result = "";
    result += "Country," + this.exportCountry + "\n";
    result += "Currency," + this.selectedCurrency + "\n";
    result += (this.display_period == 'yearly' ? "Annual" : "Monthly") + " Salary,\"" + this.formatNumberString(costs.salary) + "\"\n";
    result += (this.display_period == 'yearly' ? "Annual" : "Monthly") + " Employer Costs,\"" + this.formatNumberString(costs.employer_costs) + "\"\n";
    result += "\n";
    result += "Name,Amount,Frequency\n";
    for (let c of costs.cost_details) {
      result += "\"" + c.name + "\",\"" + this.formatNumberString(c.amount) + "\"," + c.frequency + "\n";
    }
    return result
  }

  onCalculate() {
    this.cost$ = of(null);
    if (this.salary) {
      let x = parseFloat(this.salary.replace(',', ''));
      if (!isNaN(x)) {
        this.cost$ = this.country$.pipe(
          tap((country) => { this.exportCountry = country.name; }),
          mergeMap((country) => this.calculatorService.getSalaryDetails(country, x, this.selectedCurrency, this.salary_period, this.display_period, this.version)), 
          shareReplay(1), 
          tap(
            (cost) => {
              this.exportString = this.createExportString(cost);
            }
          ));
      }
    }
  }


  export() {
    this.clipboard.copy(this.exportString);
    this.snackBar.open('Calculator results have been copied to the clipboard', 'Close', {
      duration: 5000,
    });
  }

  public currencyOrder(a, b) {
    return (a.value < b.value) ? -1 : 1;
  }

  constructor(
    private calculatorService: CalculatorService,
    private countryService: CountryService,
    private decimalPipe: DecimalPipe,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.country$?.pipe(
      take(1),
      tap((c) => {
        this.selectedCurrency = c.currency_iso;
      }),
      switchMap(() => this.countryService.allCountries()),
      map((countries: Country[]) => {
        let currency = {};
        countries.forEach(c => {
          if (c && c.currency_iso && c.currency_name && !(c.currency_iso in currency)) {
            currency[c.currency_iso] = c.currency_name;
          }
        });
        delete currency['USD'];
        delete currency['EUR'];
        delete currency[this.selectedCurrency];
        return currency;
      }),
    ).subscribe((currency) => {
      this.currencies$ = of(currency);
    });
  }

  ngAfterViewInit() {
    if (this.salaryInput) {
      this.salaryInput.nativeElement.focus();
    }
  }

}
