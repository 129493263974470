import { Component, OnInit, Input } from '@angular/core';
import { NewsService } from 'src/app/services/news/news.service';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { NewsItem } from 'src/app/models/news-item';
import { mergeMap, map } from 'rxjs/operators';
import { isArray } from 'util';

@Component({
  selector: 'app-country-news',
  templateUrl: './country-news.component.html',
  styleUrls: ['./country-news.component.scss']
})
export class CountryNewsComponent implements OnInit {

  @Input()
  public country$ : Observable<Country>;
  public news$ : Observable<NewsItem[]>;

  constructor(private newsService : NewsService) { }

  ngOnInit() {
    if (this.country$) {
      this.news$ = this.country$.pipe(
        mergeMap((country) => country ? this.newsService.getNewsForCountry(country) : null),
        map((news) => news && Array.isArray(news) ? news.slice(0,10) : news)
      )
    }
  }
}
