import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Country } from 'src/app/models/country';
import { CountryEvent } from 'src/app/models/country-event';
import { CountryService } from '../country/country.service';
import { Observable, timer } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServiceBase } from '../service-base';
import { DateTime } from 'luxon';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { CalendarEventDTO } from '../calendar/calendar.service';

@Injectable({
  providedIn: 'root',
})
export class EventService implements ServiceBase {
  private env = environment;

  private consolidateLocalHolidays(holidays: CountryEvent[]): CountryEvent[] {
    const hh = holidays.reduce((acc, current) => {
      const dupe = acc.find((e) => e.id === current.id && e.date === current.date);
      if (dupe) {
        dupe.locations = dupe.locations.concat(', ', current.locations);
      } else {
        acc.push({ ...current });
      }
      return acc;
    }, []);
    return hh;
  }

  public getLocalEventsForCountryYear(
    country: Country,
    year: number
  ): Observable<CountryEvent[]> {
    return this.getEventsForCountryYear(country, year, 'local').pipe(
      map((h) => this.consolidateLocalHolidays(h))
    );
  }

  public getEventsForCountryYear(
    country: Country,
    year: number,
    type: string = 'national'
  ): Observable<CountryEvent[]> {
    let params = new HttpParams();
    params = params.set('countries', country.iso2);
    params = params.set('start', `${year.toString()}-01-01`);
    params = params.set('end', `${year.toString()}-12-31`);
    params = params.set('includeHolidays', 'true');

    const url = this.env.api_root.concat('headless/events/');
    const events$ = this.http.get<[CalendarEventDTO]>(url, {params}).pipe(
      shareReplay(1),
      map((raw_events) =>
        raw_events.filter((e) => {
          return e.holiday_type == type
        }),
      ),
      map((events) =>
        events.map((h) => {
          return {
            id: h.id.toString(),
            name: h.name,
            country: { name: h.country.name, iso2: h.country.iso2 },
            start: DateTime.fromISO(h.date).toJSDate(),
            date: h.date,
            locations: h.locality,
            description: h.description
          };
        }),
      ));
    
      return events$
  }

  public getSnapshotEvents(): Observable<[]> {
    let params = new HttpParams();
    params = params.set('document_type', 'snapshot');
    params = params.set('title', 'Statutory Holidays');
    return this.http
      .get<[]>(this.env.api_root.concat('v1/documentsections'), {
        params,
      })
      .pipe(shareReplay(1));
  }

  public getProfileEvents(): Observable<[]> {
    let params = new HttpParams();
    params = params.set('document_type', 'country-profile');
    params = params.set('title', 'Public Holidays');
    return this.http
      .get<[]>(this.env.api_root.concat('v1/documentsections'), {
        params,
      })
      .pipe(shareReplay(1));
  }

  clearCache(): void {
  }

  constructor(
    private countryService: CountryService,
    private http: HttpClient
  ) {
    timer(this.env.cache_timeout, this.env.cache_timeout).subscribe(() =>
      this.clearCache()
    );
  }
}
