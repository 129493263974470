import { Component, OnInit, Input, Inject, Directive } from '@angular/core';
import { User } from 'src/app/models/user';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take, map, catchError } from 'rxjs/operators';
import { Organization } from 'src/app/models/organization';
import { AbstractControl, ValidationErrors, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Country } from 'src/app/models/country';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-organization-edit-member',
  templateUrl: './organization-edit-member.component.html',
  styleUrls: ['./organization-edit-member.component.scss']
})
export class OrganizationEditMemberComponent implements OnInit {

  @Input()
  public user: User;

  @Input()
  public organization: Organization;

  @Input()
  public currentUser: User;

  public editMember() {
    let dialogRef = this.dialog.open(OrganizationEditMemberDialog, {
      width: '600px',
      data: {
        dialogHeader: "Edit user",
        user: {...this.user},
        organization: {...this.organization},
        organizationCountries: this.organization.countries.concat(this.organization.notification_countries),
        allCountries: (this.user.countries && (this.user.countries.length > 0)) ? 'selected' : 'all',
        currentUser: this.currentUser
      }
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.organizationService.saveOrganizationUser(result).subscribe(
          () => this.snackBar.open('User updated', 'Close', {duration: 5000}),
          (error) => this.snackBar.open('Could not update user: '.concat(error), 'Close')
        )
        this.mixpanelService.track("Edit user");
      }
      return false;
    });
  }

  constructor(
    public dialog: MatDialog,
    public organizationService: OrganizationService,
    protected mixpanelService: MixpanelService,
    protected snackBar: MatSnackBar) { }

  ngOnInit() {
  }

}

@Directive({
  selector: '[duplicateEmailAsyncValidator][ngModel]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: DuplicateEmailValidatorDirective, multi: true}]
})
export class DuplicateEmailValidatorDirective implements AsyncValidator {
  @Input('appUserId') userId: number;

  constructor(private organizationService: OrganizationService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.organizationService.isValidEmail(control.value, this.userId).pipe(
      map(isValid => (isValid ? false : {'isDuplicate': true})),
      catchError(() => of(null))
    );
  }
}

export interface MemberDialogData {
  dialogHeader : string;
  user: User;
  organization: Organization;
  organizationCountries: string;
  allCountries: string;
  currentUser: User;
}

@Component({
  selector: 'organization-edit-member-dialog',
  templateUrl: 'organization-edit-member-dialog.html',
  styleUrls: ['./organization-edit-member-dialog.scss']
})
export class OrganizationEditMemberDialog {

  public error : string;
  public message : string;

  public compareWithFn(item1: Country, item2: Country) : Boolean {
    return item1 && item2 ? item1.iso2 === item2.iso2 : item1 === item2;
  }

  public roleChange(e) {
    if (this.data.user.organization_role == 'owner') {
      this.data.allCountries = 'all';
      this.data.user.countries = [];
    }
  }

  public countriesChange(e) {
    if (this.data.allCountries == 'all') {
      this.data.user.countries = [];
    }
  }

  constructor(
    public dialogRef: MatDialogRef<OrganizationEditMemberDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MemberDialogData,
  ) {}

}
