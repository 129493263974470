<div *ngIf="useTextEntry; else linkTemplate">
  <mat-card>
    <mat-card-title>Ask GPS AI</mat-card-title>

    <div *ngIf="!isChatStarted" class="initial-input-container">
      <form (ngSubmit)="sendMessage()">
      <div>
        <mat-form-field fxFlexFill appearance="outline"
                      class="formfield__no-label dark-background">
          <input matInput [(ngModel)]="userInput" placeholder="What do you want to know?" name="initialPrompt" (keyup.enter)="sendMessage()" autocomplete="off"/>
        </mat-form-field>
        </div>
        <div class="chat-button-wrapper">
        <button type="submit" class="chat-button">Ask Now</button>
        </div>
      </form>
    </div>
  </mat-card>
</div>
<ng-template #linkTemplate>
  <a mat-menu-item href="#" (click)="openChat($event)" class="ai__open-link show-new-icon"><mat-icon svgIcon="chat" matBadge="Beta" matBadgeColor="accent" class="new"></mat-icon>Ask GPS AI</a>
</ng-template>

<div *ngIf="isChatStarted" class="chat-overlay" (click)="closeOverlay($event)">
  <div class="chat-container" (click)="$event.stopPropagation()">
    <div class="chat-header">
      <div class="chat-header-title">Ask GPS AI</div>
    </div>
    <div class="chat-history" #chatHistory>
      <div class="chat-header-disclaimer">
The AI responses generated by this AI chatbot are based on content from Global People Strategist.
While we strive to provide accurate and relevant information, the 
AI responses may not always be accurate or complete. Always verify the information using the references
listed in the response.
      </div>
      <div *ngFor="let message of messages">
        <div [ngClass]="{'user-message': message.isUser, 'bot-message': !message.isUser}">
          <div *ngIf="!message.isUser; else userMessage">
            <div [innerHTML]="message.text"></div>
          </div>
          <ng-template #userMessage>{{ message.text }}</ng-template>
        </div>
        <div *ngIf="!message.isUser && message.complete" class="copy">
          <a (click)="copyToClipboard(message.markdown)">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 115.77 122.88" style="enable-background:new 0 0 115.77 122.88" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st0" d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"/></g></svg>          
          Copy response to clipboard</a>
        </div>
      </div>
      <div *ngIf="waiting" class="bot-message">
        <div class="typing-indicator">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <form class="chat-input" (ngSubmit)="sendMessage()">
      <input #chatInput [(ngModel)]="userInput" name="prompt" placeholder="What do you want to know?" (keyup.enter)="sendMessage()" />
      <button type="submit" class="chat-button">Send</button>
    </form>
  </div>
</div>

