import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { CalendarFilter } from 'src/app/models/calendar-filter';
import {
  CalendarService,
  GroupedCalendarDays,
} from 'src/app/services/calendar/calendar.service';

@Component({
  selector: 'app-calendar-grid',
  templateUrl: './calendar-grid.component.html',
  styleUrls: ['./calendar-grid.component.scss'],
})
export class CalendarGridComponent implements OnInit, OnDestroy {
  sub: Subscription = new Subscription();
  @Input()
  month: DateTime;

  @Input()
  filter: CalendarFilter;

  events: GroupedCalendarDays;
  days: DateTime[];

  async getEventsForTheMonth() {
    if (this.month) {
      // Get the days for the month calendar
      const monthStart = this.month.startOf('month');
      const monthEnd = this.month.endOf('month');

      // Get start/end dates for the calendar grid. Note that luxon treats the first day of the week as Monday.
      const gridStart =
        monthStart.weekday === 7
          ? monthStart
          : monthStart.startOf('week').minus({ day: 1 }).startOf('day');
      const gridEnd =
        monthEnd.weekday === 7
          ? monthEnd
              .plus({ day: 1 })
              .endOf('week')
              .minus({ day: 1 })
              .startOf('day')
          : monthEnd.endOf('week').minus({ day: 1 }).startOf('day');

      this.days = [];
      for (
        let day = gridStart;
        day.diff(gridEnd.startOf('day'), 'days').days <= 0;
        day = day.plus({ day: 1 })
      ) {
        this.days.push(day);
      }

      // Get the events for the month
      this.events = await this.calendarService
        .getGroupedCalendarEvents(gridStart, gridEnd, this.filter)
        .toPromise();
    }
  }

  constructor(private calendarService: CalendarService) {}

  ngOnInit(): void {
    const sub = this.calendarService.changes$?.subscribe((res) => {
      this.getEventsForTheMonth();
    });
    this.sub.add(sub);
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.getEventsForTheMonth();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
